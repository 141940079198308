import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';
import LaunchListPage from './pages/LaunchListPage';
import GalleryListPage from './pages/GalleryListPage';
import DesignPage from './pages/DesignPage';
import SeoSettings from './pages/SeoSettingsPage';
import AdminLogin from './pages/AdminLogin';
import ProtectedRoute from './components/ProtectedRoute';
import PreviewPage from './pages/PreviewPage';
import PublicPreviewPage from './pages/LaunchPage';
import LaunchLayout from './pages/LaunchLayout';
import HomepagePreviewPage from './pages/HomepagePreviewPage';
import { ToastContainer } from 'react-toastify';  // Toastify import ediliyor
import 'react-toastify/dist/ReactToastify.css';   // Toastify CSS dosyası ekleniyor
import AdminHomePage from './pages/AdminHomePage';
import Fragman from './pages/Fragman';
import NotFoundPage from './pages/NotFoundPage'; // 404 sayfasını import ediyoruz
import AdminPanelPage from './pages/AdminPanelPage';
import LaunchSearchPage from './pages/Launchsearchpage';
import LaunchReportPage from './pages/LaunchReportPage';

const App: React.FC = () => {
  return (
    <Router>
      {/* ToastContainer'ı ekleyerek uygulamanın her yerinde bildirimleri gösteriyoruz */}
      <ToastContainer position="bottom-right" autoClose={5000} />

      <Routes>
        {/* Genel kullanıcı rotaları */}
        <Route path="/" element={<AdminLogin />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-panel/preview/:id" element={<ProtectedRoute element={<PreviewPage />} />} />
        <Route path="/public-preview/:id" element={<PublicPreviewPage />} />
        <Route path="/lansman/:launchUrl" element={<LaunchLayout />} />
        <Route path="/admin-panel/fragman/:id" element={<Fragman />} />
        <Route path="/lansmanlar" element={<LaunchSearchPage />} />

        {/* Admin Panel Rotaları */}
        <Route
          path="/admin-panel"
          element={
            <ProtectedRoute
              element={
                <AdminPanelPage />
              }
            />
          }
        >
          <Route path="adminhomepage" element={<ProtectedRoute element={<AdminHomePage />} />} />
          <Route path="launch-list" element={<ProtectedRoute element={<LaunchListPage />} />} />
          <Route path="design/:id" element={<ProtectedRoute element={<DesignPage />} />} />
          <Route path="seo/:id" element={<ProtectedRoute element={<SeoSettings />} />} />
          <Route path="gallery-list" element={<ProtectedRoute element={<GalleryListPage />} />} />
          <Route path="launchreport" element={<ProtectedRoute element={<LaunchReportPage />} />} />
          <Route path="" element={<div>Hoş geldiniz! Bir sayfa seçin.</div>} />

        </Route>

        {/* 404 Sayfası */}
        <Route path="*" element={<NotFoundPage />} /> {/* 404 sayfası en son sırada */}
      </Routes>
    </Router>
  );
};

export default App;