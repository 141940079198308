import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector';
import LargePopupCardSection from '../../UI/sections/LargePopupCardSection';
import { S3File } from '../../services/types';

interface LargePopupCardFormProps {
    onSave: (data: any) => void;
}

const LargePopupCardForm: React.FC<LargePopupCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>();
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<S3File | null>(null);
    const [selectedPoster, setSelectedPoster] = useState<S3File | null>(null); // Kapak fotoğrafı için state
    const [youtubeUrl, setYoutubeUrl] = useState(''); // YouTube URL için state
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const defaultMedia = 'https://via.placeholder.com/1920x1080?text=No+Media+Available';

    const handleSave = async () => {
        if (!selectedMedia && !youtubeUrl) return;

        const componentData = {
            designId: id,
            componentName: 'Large Popup Card',
            componentData: {
                media: youtubeUrl || selectedMedia?.url || defaultMedia, // YouTube linki veya seçilen medya
                poster: selectedPoster?.url || '' // Kapak fotoğrafı burada gönderiliyor
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data);

        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleMediaSelect = (file: S3File) => {
        setSelectedMedia(file);
        setYoutubeUrl(''); // YouTube URL'yi temizler
        setIsMediaSelectorOpen(false);
    };

    const handlePosterSelect = (file: S3File) => {
        setSelectedPoster(file);
        setIsMediaSelectorOpen(false);
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    const handleFullscreenToggle = () => {
        setIsFullscreen(!isFullscreen);
    };

    const renderMediaPreview = (media: S3File) => {
        const fileExtension = media.url.split('.').pop()?.toLowerCase();

        if (['jpg', 'png', 'jpeg', 'webp'].includes(fileExtension || '')) {
            return (
                <>
                    <img src={media.url} alt={media.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />
                    <p className="text-sm text-gray-500 mt-2">(Önerilen boyutlar: 1280x720 piksel veya 1920x1080 piksel)</p>
                </>
            );
        } else if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <>
                    <video controls className="max-w-full max-h-64 mx-auto rounded-lg" poster={selectedPoster?.url || ''}>
                        <source src={media.url} type={`video/${fileExtension}`} />
                        Tarayıcınız bu videoyu desteklemiyor.
                    </video>
                    <p className="text-sm text-gray-500 mt-2">(Önerilen boyutlar: 1280x720 piksel veya 1920x1080 piksel)</p>
                </>
            );
        } else {
            return <p>Bu dosya tipi görüntülenemez: {media.key}</p>;
        }
    };

    const isVideo = selectedMedia?.url.match(/\.(mp4|webm|ogg)$/);

    return (
        <div>
            {/* Medya Seçimi */}
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal mb-2">Medya (Önerilen boyutlar: 1280x720 piksel veya 1920x1080 piksel)</label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsMediaSelectorOpen(true)}
                >
                    <span className="text-[#091E42] text-sm">{selectedMedia ? selectedMedia.key : 'Medya Seç'}</span>
                    <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>
                {selectedMedia && (
                    <div className="mt-4">
                        {renderMediaPreview(selectedMedia)}
                    </div>
                )}
            </div>

            {/* YouTube Link Girişi */}
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal mb-2">YouTube Linki</label>
                <input
                    type="text"
                    value={youtubeUrl}
                    onChange={(e) => {
                        setYoutubeUrl(e.target.value);
                        setSelectedMedia(null); // Medya seçimini temizler
                    }}
                    className="w-full p-2 border border-[#dfe2e6] rounded-lg"
                    placeholder="YouTube video URL'sini buraya yapıştırın"
                />
            </div>

            {/* Kapak Fotoğrafı Seçimi - Sadece video seçildiğinde gösterilir */}
            {isVideo && (
                <div className="mb-4">
                    <label className="block text-[#243757] text-sm font-normal mb-2">Kapak Fotoğrafı Seç (Poster)</label>
                    <div
                        className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                        onClick={() => setIsMediaSelectorOpen(true)}
                    >
                        <span className="text-[#091E42] text-sm">{selectedPoster ? selectedPoster.key : 'Kapak Fotoğrafı Seç'}</span>
                        <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                    </div>
                    {selectedPoster && (
                        <div className="mt-4">
                            <img src={selectedPoster.url} alt={selectedPoster.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />
                        </div>
                    )}
                </div>
            )}

            <MediaSelector
                isOpen={isMediaSelectorOpen}
                onSelect={isVideo ? handlePosterSelect : handleMediaSelect} // Eğer video seçiliyorsa poster seçimi yapılır
                onClose={() => setIsMediaSelectorOpen(false)}
            />

            {/* Kaydet ve Önizle Butonları */}
            <div className="flex justify-end space-x-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {/* Pop-up Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className={`fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50 ${isFullscreen ? 'w-full h-full' : ''}`}
                    onClick={() => setIsPreviewOpen(false)}
                >
                    <div
                        className="relative w-full max-w-[100vw] h-[100vh] bg-white rounded-lg p-6 overflow-auto"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-red-700 hover:text-red-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* Fullscreen toggle button */}
                        <button
                            onClick={handleFullscreenToggle}
                            className="absolute top-2 left-2 text-red-700 hover:text-red-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v6h6M4 20v-6h6m10-10v6h-6m6 10v-6h-6" />
                            </svg>
                        </button>

                        {/* LargePopupCardSection bileşeni ile önizleme */}
                        <div className="w-full h-full mx-auto flex justify-center items-center">
                            <LargePopupCardSection
                                componentData={{
                                    media: youtubeUrl || selectedMedia?.url || defaultMedia,
                                    poster: selectedPoster?.url || ''
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LargePopupCardForm;
