import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
// @ts-ignore
import Flag from 'react-world-flags'; // Bayrak ikonları için
import { FaChevronDown, FaChevronUp, FaSearch, FaBars, FaTimes } from 'react-icons/fa'; // Chevron, Search, Hamburger ve Close ikonları
import damiseLogo from '../assets/images/Damise.png'; // DAMISE logosunu burada import ediyoruz

interface SeoSettings {
    userId: string;
    title: string;
    url: string;
    keywords: string;
    description: string;
    socialImage: string;
    indexStatus: boolean;
    followStatus: boolean;
}

interface Launch {
    _id: string;
    groupNumber: string;
    language: 'TR' | 'EN'; // Dil sadece 'TR' veya 'EN' olacak
    launchName: string;
    // Diğer alanlar...
}

const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const { launchUrl } = useParams<{ launchUrl: string }>(); // URL parametresini alıyoruz
    const [language, setLanguage] = useState<'TR' | 'EN'>('TR'); // Başlangıç dili TR
    const [isOpen, setIsOpen] = useState(false); // Dropdown menüsünün açık/kapalı durumu
    const [isLaunchMenuOpen, setIsLaunchMenuOpen] = useState(false); // Lansman menüsü açık/kapalı durumu
    const [groupNumber, setGroupNumber] = useState<string>(''); // Mevcut lansmanın grup numarası
    const [userId, setUserId] = useState<string>(''); // Mevcut SEO'dan gelen userId
    const [menuOpen, setMenuOpen] = useState(false); // Mobil menü açık/kapalı durumu

    useEffect(() => {
        // URL'ye göre SEO verisini çekiyoruz
        const fetchSeoData = async () => {
            try {
                const response = await axios.get<SeoSettings>(`${process.env.REACT_APP_API_URL}/seosettings/url/${launchUrl}`);
                if (response.status === 200) {
                    const seoData = response.data;
                    // SEO'dan gelen userId'yi al
                    setUserId(seoData.userId);

                    // Ardından, lansman veritabanından bilgileri al
                    const launchResponse = await axios.get<Launch>(`${process.env.REACT_APP_API_URL}/launch/${seoData.userId}`);
                    if (launchResponse.status === 200) {
                        const launchData = launchResponse.data;
                        setGroupNumber(launchData.groupNumber); // Grup numarasını al
                        setLanguage(launchData.language); // Dil bilgisini al
                    }
                }
            } catch (error) {
                console.error('SEO ve lansman verisi alınamadı:', error);
            }
        };
        fetchSeoData();
    }, [launchUrl]);

    // Dil değişikliği olduğunda lang attribute'ünü güncelle
    useEffect(() => {
        document.documentElement.lang = language === 'TR' ? 'tr' : 'en';
    }, [language]);

    // Dil değişikliği yapıldığında SEO verisine göre yönlendirme yap ve dropdown'ı kapat
    const handleLanguageChange = async (newLanguage: 'TR' | 'EN') => {
        try {
            // Aynı groupNumber'a ve farklı dildeki lansmanı getiriyoruz
            const response = await axios.get<Launch[]>(`${process.env.REACT_APP_API_URL}/launch/launches`, {
                params: {
                    groupNumber: groupNumber, // Aynı grup numarasına sahip lansmanı arıyoruz
                    language: newLanguage // Yeni dil ile lansmanı arıyoruz
                }
            });

            const launches = response.data; // Aynı grup numarasındaki lansmanlar

            if (launches.length > 0) {
                const newLaunchId = launches[0]._id; // Yeni dildeki lansmanın ID'sini alıyoruz
                // Yeni dildeki lansmanın SEO URL'sini bul
                const seoResponse = await axios.get<SeoSettings>(`${process.env.REACT_APP_API_URL}/seosettings/${newLaunchId}`);
                if (seoResponse.status === 200) {
                    const newSeoData = seoResponse.data;
                    setLanguage(newLanguage); // Dili güncelle
                    setIsOpen(false); // Menü kapanıyor
                    navigate(`/lansman/${newSeoData.url}`); // Yeni sayfaya yönlendiriyoruz
                }
            } else {
                console.error('İlgili dilde lansman bulunamadı.');
            }
        } catch (error) {
            console.error('Dil değiştirilemedi:', error);
        }
    };

    // Dropdown menüsünü aç/kapa
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // Lansman menüsünü aç/kapa
    const toggleLaunchMenu = () => {
        setIsLaunchMenuOpen(!isLaunchMenuOpen);
    };

    // Mobil menüyü aç/kapa
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="fixed top-0 left-0 w-full px-4 sm:px-8 py-2 bg-white z-50 shadow-md">
            <div className="flex justify-between items-center">
                <div className="flex-shrink-0">
                    <img src={damiseLogo} alt="Logo" className="w-32 sm:w-48 cursor-pointer" onClick={() => window.location.href = 'https://damise.com'} />
                </div>

                {/* Mobil menü butonu */}
                <div className="sm:hidden">
                    <button onClick={toggleMenu} className="text-gray-700">
                        {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </button>
                </div>

                {/* Navigasyon ve dil seçenekleri (sadece büyük ekranlarda) */}
                <div className="hidden sm:flex items-center space-x-6">
                    <button
                        onClick={() => navigate('/')}
                        className="text-gray-700 hover:text-red-800 text-lg font-medium relative group"
                    >
                        Ana Sayfa
                        {/* Hover sırasında çizgi ekler */}
                        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-800 scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
                    </button>

                    <div className="relative">
                        <button
                            onClick={toggleLaunchMenu}
                            className="text-gray-700 hover:text-red-800 text-lg font-medium flex items-center relative group"
                        >
                            <FaSearch className="mr-2" />
                            Lansmanlar {isLaunchMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
                            {/* Hover sırasında çizgi ekler */}
                            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-800 scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
                        </button>

                        {isLaunchMenuOpen && (
                            <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
                                <button
                                    onClick={() => navigate('/yayindaki-lansmanlar')}
                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    Yayındaki Lansmanlar
                                </button>
                                <button
                                    onClick={() => navigate('/gelecek-lansmanlar')}
                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    Gelecek Lansmanlar
                                </button>
                                <button
                                    onClick={() => navigate('/gecmis-lansmanlar')}
                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    Geçmiş Lansmanlar
                                </button>
                            </div>
                        )}
                    </div>

                    <button
                        onClick={() => navigate('/login')}
                        className="text-gray-700 border border-gray-400 rounded-full px-4 py-1 text-lg font-medium hover:text-red-800 hover:border-red-800 transition duration-300"
                    >
                        Giriş Yap
                        {/* Hover sırasında çizgi ekler */}
                        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-800 scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
                    </button>

                    {/* Dil Menüsü (Büyük ekranlar için) */}
                    <div className="relative hidden sm:block">
                        <button
                            onClick={toggleDropdown}
                            className="flex items-center space-x-2 text-gray-800 hover:text-red-800 text-lg font-medium border rounded-full px-4 py-1"
                        >
                            <Flag code={language === 'TR' ? 'TUR' : 'GBR'} className="w-5 h-4" />
                            <span className="notranslate">{language}</span> {/* notranslate ekledim */}
                        </button>

                        {isOpen && (
                            <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-300 rounded-lg shadow-lg">
                                <button
                                    onClick={() => handleLanguageChange('TR')}
                                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    <Flag code="TUR" className="mr-2 w-6" />
                                    <span>Türkçe</span>
                                </button>
                                <button
                                    onClick={() => handleLanguageChange('EN')}
                                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    <Flag code="GBR" className="mr-2 w-6" />
                                    <span>English</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Mobil menü (küçük ekranlar için) */}
            {menuOpen && (
                <div className="sm:hidden mt-4 space-y-2">
                    <button
                        onClick={() => navigate('/')}
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        Ana Sayfa
                    </button>
                    <button
                        onClick={() => navigate('/yayindaki-lansmanlar')}
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        Yayındaki Lansmanlar
                    </button>
                    <button
                        onClick={() => navigate('/gelecek-lansmanlar')}
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        Gelecek Lansmanlar
                    </button>
                    <button
                        onClick={() => navigate('/gecmis-lansmanlar')}
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        Geçmiş Lansmanlar
                    </button>
                    <button
                        onClick={() => navigate('/login')}
                        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                        Giriş Yap
                    </button>

                    {/* Mobil Dil Menüsü */}
                    <div className="relative">
                        <button
                            onClick={toggleDropdown}
                            className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                        >
                            <Flag code={language === 'TR' ? 'TUR' : 'GBR'} className="mr-2 w-6" />
                            <span className="notranslate">{language}</span> {/* notranslate ekledim */}
                        </button>
                        {isOpen && (
                            <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-300 rounded-lg shadow-lg">
                                <button
                                    onClick={() => handleLanguageChange('TR')}
                                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    <Flag code="TUR" className="mr-2 w-6" />
                                    <span>Türkçe</span>
                                </button>
                                <button
                                    onClick={() => handleLanguageChange('EN')}
                                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-gray-700"
                                >
                                    <Flag code="GBR" className="mr-2 w-6" />
                                    <span>English</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
