import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // MediaSelector bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin
import AccordionRightCardSection from '../../UI/sections/AccordionRightCardSection'; // Gerçek yolu buraya yazın

interface AccordionRightCardFormProps {
    onSave: (data: any) => void;
}

const AccordionRightCardForm: React.FC<AccordionRightCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false); // Modal açık mı kapalı mı?
    const [selectedMedia, setSelectedMedia] = useState<S3File | null>(null); // Seçilen medya dosyası
    const [accordionSections, setAccordionSections] = useState([{ title: '', subtitle: '', error: '' }]); // Error state eklendi
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modal state

    const handleAddSection = () => {
        setAccordionSections([...accordionSections, { title: '', subtitle: '', error: '' }]);
    };

    const handleSectionChange = (index: number, field: string, value: string) => {
        const newSections = accordionSections.map((section, i) =>
            i === index
                ? {
                    ...section,
                    [field]: value,
                    error: field === 'subtitle' && value.length > 200 ? 'Alt başlık 200 karakterden uzun olamaz!' : '',
                }
                : section
        );
        setAccordionSections(newSections);
    };

    const handleRemoveSection = (index: number) => {
        setAccordionSections(accordionSections.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        if (!selectedMedia) return; // Eğer medya seçilmediyse kaydetme

        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Accordion Right Card',
            componentData: { media: selectedMedia?.url || '', accordionSections }, // Seçilen medya dosyasının URL'si
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
         
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleMediaSelect = (file: S3File) => {
        setSelectedMedia(file);
        setIsMediaSelectorOpen(false);
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    const renderSelectedMediaPreview = () => {
        if (!selectedMedia) return null;

        const mimeType = selectedMedia.url.split('.').pop()?.toLowerCase();
        if (
            mimeType?.includes('jpg') ||
            mimeType?.includes('jpeg') ||
            mimeType?.includes('png') ||
            mimeType?.includes('gif') ||
            mimeType?.includes('webp')
        ) {
            return <img src={selectedMedia.url} alt={selectedMedia.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg overflow-hidden" />;
        } else if (mimeType?.includes('mp4') || mimeType?.includes('webm') || mimeType?.includes('ogg')) {
            return <video src={selectedMedia.url} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" controls />;
        } else if (mimeType?.includes('pdf')) {
            return <iframe src={selectedMedia.url} title={selectedMedia.key} className="w-full h-64 object-contain mx-auto rounded-lg"></iframe>;
        } else {
            return <p className="text-sm text-[#091E42]">Seçilen Medya: {selectedMedia.key}</p>;
        }
    };

    return (
        <div className="w-full max-w-4xl mx-auto p-4 overflow-x-hidden">
            {/* Modal kapalıysa form görünür */}
            <div className="mb-4">
                <label className="block text-gray-700">Medya Alanı</label>
                <button
                    onClick={() => setIsMediaSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm"
                >
                    <span className="text-[#091E42]">{selectedMedia ? selectedMedia.key : 'Medya Seç'}</span>
                    <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </button>
                {selectedMedia && <div className="mt-4">{renderSelectedMediaPreview()}</div>}
                <p className="text-sm text-gray-500 mt-2">Önerilen görsel ölçüleri: 816x756 px (en iyi çözünürlük için).</p>
            </div>

            {accordionSections.map((section, index) => (
                <div key={index} className="mb-4 p-4 border border-[#dfe2e6] rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                        <h3 className="text-[#243757] text-sm font-normal">Accordion Bölüm {index + 1}</h3>
                        <button onClick={() => handleRemoveSection(index)} className="px-3 py-1 bg-red-700 text-white rounded-md text-sm">
                            Sil
                        </button>
                    </div>
                    <div className="mb-2">
                        <label className="block text-[#243757] text-sm font-normal">Başlık</label>
                        <input
                            type="text"
                            value={section.title}
                            onChange={(e) => handleSectionChange(index, 'title', e.target.value)}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md break-words" // break-words eklendi
                            placeholder="Başlık"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-[#243757] text-sm font-normal">Alt Başlık</label>
                        <textarea
                            value={section.subtitle}
                            onChange={(e) => handleSectionChange(index, 'subtitle', e.target.value)}
                            maxLength={200}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md break-words" // break-words ve wrap ayarlandı
                            placeholder="Alt Başlık (200 karakter sınırı)"
                        />
                        <p className="text-sm text-gray-500">{section.subtitle.length}/200 karakter</p>
                        {section.error && <p className="text-red-700 text-sm">{section.error}</p>}
                    </div>
                </div>
            ))}

            <div className="mb-4">
                <button onClick={handleAddSection} className="px-4 py-2 bg-gray-200 text-gray-700 rounded">
                    Accordion Bölüm Ekle
                </button>
            </div>

            <div className="flex justify-end space-x-4">
                <button
                    onClick={handlePreview}
                    className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow border border-[#d6d6d6] text-base font-normal leading-tight"
                >
                    Önizle
                </button>
                <button
                    onClick={handleSave}
                    className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal leading-tight"
                >
                    Ekle
                </button>
            </div>

            {/* Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                >
                    <div
                        className="bg-white bg-opacity-90 rounded-lg p-6 w-full h-full overflow-auto relative"
                        onClick={(e) => e.stopPropagation()} // Modalın içeriğine tıklamayı engelle
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-8 text-gray-500 text-3xl opacity-50 hover:opacity-100 transition-opacity"
                        >
                            &times;
                        </button>
                        {/* AccordionRightCardSection bileşeni ile önizleme yapıyoruz */}
                        <AccordionRightCardSection componentData={{ media: selectedMedia?.url || '', accordionSections }} />
                    </div>
                </div>
            )}

            <MediaSelector isOpen={isMediaSelectorOpen} onSelect={handleMediaSelect} onClose={() => setIsMediaSelectorOpen(false)} />
        </div>
    );
};

export default AccordionRightCardForm;