import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NavigationTabsProps {
    activePage: 'tasarla' | 'seo';
    id: string;
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ activePage, id }) => {
    const navigate = useNavigate();

    const handleNavigateToSEO = () => {
        navigate(`/admin-panel/seo/${id}`);
    };

    const handleNavigateToDesign = () => {
        navigate(`/admin-panel/design/${id}`);
    };

    return (
        <div className="flex justify-between items-center mb-4">
            <div className="flex space-x-4">
                <button
                    onClick={handleNavigateToDesign}
                    className={`flex items-center px-4 py-2 rounded ${activePage === 'tasarla'
                        ? 'bg-gray-100 shadow-md text-gray-900'
                        : 'bg-transparent text-gray-500'
                        }`}
                >
                    <div className={`w-4 h-4 ${activePage === 'tasarla' ? 'bg-gray-500' : 'bg-gray-400'} mr-2`}></div>
                    <span>Tasarla</span>
                </button>
                <button
                    onClick={handleNavigateToSEO}
                    className={`flex items-center px-4 py-2 rounded ${activePage === 'seo'
                        ? 'bg-gray-100 shadow-md text-gray-900'
                        : 'bg-transparent text-gray-500'
                        }`}
                >
                    <div className={`w-4 h-4 ${activePage === 'seo' ? 'bg-gray-500' : 'bg-gray-400'} mr-2`}></div>
                    <span>SEO</span>
                </button>
            </div>
        </div>
    );
};

export default NavigationTabs;
