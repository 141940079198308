import React from 'react';

interface TwinCardSectionProps {
    componentData: {
        leftMedia: string;
        leftPoster?: string; // Sol medya kapak fotoğrafı
        rightMedia: string;
        rightPoster?: string; // Sağ medya kapak fotoğrafı
    };
}

const TwinCardSection: React.FC<TwinCardSectionProps> = ({ componentData }) => {
    const renderMedia = (mediaUrl: string, mediaPoster?: string) => {
        const mediaType = mediaUrl.split('.').pop()?.toLowerCase();

        if (!mediaType) {
            return <p>Desteklenmeyen medya türü</p>;
        }

        if (['mp4', 'webm', 'ogg'].includes(mediaType)) {
            return (
                <video className="w-full h-full object-cover" controls poster={mediaPoster}>
                    <source src={mediaUrl} type={`video/${mediaType}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        }

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(mediaType)) {
            return <img src={mediaUrl} alt="Media" className="w-full h-full object-cover" />;
        }

        if (mediaType === 'pdf') {
            return (
                <iframe
                    className="w-full h-full"
                    src={mediaUrl}
                    title="PDF Preview"
                    style={{ border: 'none' }}
                />
            );
        }

        if (['mp3', 'wav', 'ogg'].includes(mediaType)) {
            return (
                <audio controls className="w-full">
                    <source src={mediaUrl} type={`audio/${mediaType}`} />
                    Tarayıcınız bu sesi desteklemiyor.
                </audio>
            );
        }

        if (['doc', 'docx', 'ppt', 'pptx'].includes(mediaType)) {
            return (
                <a href={mediaUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                    {`Dosyayı görüntüle: ${mediaUrl}`}
                </a>
            );
        }

        return <p>Desteklenmeyen medya türü</p>;
    };

    return (
        <div
            className="flex flex-col md:flex-row justify-center items-center gap-8 py-8 bg-white-100 mx-auto"
            style={{ width: '85vw', height: '70vh' }}
        >
            <div className="w-full md:w-[65%] h-full bg-gray-200 rounded-3xl shadow-lg flex justify-center items-center transform transition-transform duration-300 hover:scale-105 overflow-hidden">
                {renderMedia(componentData.leftMedia, componentData.leftPoster)}
            </div>

            <div className="w-full md:w-[65%] h-full bg-gray-200 rounded-3xl shadow-lg flex justify-center items-center transform transition-transform duration-300 hover:scale-105 overflow-hidden">
                {renderMedia(componentData.rightMedia, componentData.rightPoster)}
            </div>
        </div>
    );
};

export default TwinCardSection;
