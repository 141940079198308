import React from 'react';
import { FaTrash } from 'react-icons/fa';
import Switch from '@mui/material/Switch';

interface DesignElement {
    id: string;
    type: string;
    content: string;
    showInFragman: boolean;
    order: number;
}

interface DesignElementComponentProps {
    element: DesignElement;
    index: number;
    onMoveUp: (index: number) => void;
    onMoveDown: (index: number) => void;
    onToggleFragman: (id: string) => void;
    onDelete: (id: string) => void;
}


const DesignElementComponent: React.FC<DesignElementComponentProps> = ({
    element,
    index,
    onMoveUp,
    onMoveDown,
    onToggleFragman,
    onDelete,
}) => {
    return (
        <div className="flex w-[700px] items-center justify-between border rounded-lg p-2 bg-white shadow">
        <div className="flex items-center w-40 space-x-2">
            <span className="text-gray-800 font-medium">{element.type}</span>
        </div>
        <div className="flex items-center">
            <Switch
                checked={element.showInFragman}
                onChange={() => onToggleFragman(element.id)}
            />
            <span className="text-sm text-gray-700">Fragmanda Göster</span>
        </div>
        <div className="flex items-center space-x-2">
            <button
                onClick={() => onDelete(element.id)}
                className="text-red-600 hover:text-red-800 text-sm"
            >
                <FaTrash /> {/* Kırmızı trash ikonu */}
            </button>
        </div>
    </div>
    );
};

export default DesignElementComponent;
