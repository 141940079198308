import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

interface TwinFlipCardProps {
    componentData: {
        leftCard: {
            frontMedia: string;
            backMedia: string;
        };
        rightCard: {
            frontMedia: string;
            backMedia: string;
        };
    };
}

const TwinFlipCard: React.FC<TwinFlipCardProps> = ({ componentData }) => {
    const { leftCard, rightCard } = componentData;
    const [isLeftFlipped, setIsLeftFlipped] = useState(false);
    const [isRightFlipped, setIsRightFlipped] = useState(false);

    // Medya render fonksiyonu..
    const renderMedia = (media: string) => {
        const fileExtension = media.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return (
                <img
                    src={media}
                    alt="Media"
                    className=" w-full h-full object-cover" // Kart boyutlarına tam oturacak şekilde genişlik ve yükseklik
                />
            );
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video
                    className=" w-full h-full object-cover" // Video kart boyutlarına tam oturacak
                    autoPlay
                    muted
                    loop
                >
                    <source src={media} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        }

        return <p>Geçersiz medya formatı.</p>;
    };

    return (
        <div
            className="flex flex-col md:flex-row justify-center gap-8 mt-12 mx-auto" // Flexbox düzeni, mobilde dikey, masaüstünde yatay olacak
            style={{ width: '85vw', height: '70vh' }} // Kartlar için genişlik ve yükseklik ayarları
        >
            {/* Sol Kart */}
            <ReactCardFlip isFlipped={isLeftFlipped} flipDirection="horizontal">
                <div
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg" // %85 genişliğe göre tam oturacak
                    onClick={() => setIsLeftFlipped(true)}
                >
                    {/* Ön Yüz */}
                    <div className="w-full h-full flex justify-center items-center">
                        {renderMedia(leftCard.frontMedia)}
                    </div>
                    <button
                        className="absolute bottom-4 right-4 bg-transparent p-2 rounded-full shadow-md hover:bg-white transition-colors"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" className="text-white hover:text-black" />
                    </button>
                </div>

                <div
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsLeftFlipped(false)}
                >
                    {/* Arka Yüz */}
                    <div className="w-full h-full flex justify-center items-center">
                        {renderMedia(leftCard.backMedia)}
                    </div>
                    <button
                        className="absolute bottom-4 right-4 bg-transparent p-2 rounded-full shadow-md hover:bg-white transition-colors"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    >
                        <FontAwesomeIcon icon={faTimes} size="lg" className="text-white hover:text-black" />
                    </button>
                </div>
            </ReactCardFlip>

            {/* Sağ Kart */}
            <ReactCardFlip isFlipped={isRightFlipped} flipDirection="horizontal">
                <div
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsRightFlipped(true)}
                >
                    {/* Ön Yüz */}
                    <div className="w-full h-full flex justify-center items-center">
                        {renderMedia(rightCard.frontMedia)}
                    </div>
                    <button
                        className="absolute bottom-4 right-4 bg-transparent p-2 rounded-full shadow-md hover:bg-white transition-colors"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" className="text-white hover:text-black" />
                    </button>
                </div>

                <div
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsRightFlipped(false)}
                >
                    {/* Arka Yüz */}
                    <div className="w-full h-full flex justify-center items-center">
                        {renderMedia(rightCard.backMedia)}
                    </div>
                    <button
                        className="absolute bottom-4 right-4 bg-transparent p-2 rounded-full shadow-md hover:bg-white transition-colors"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    >
                        <FontAwesomeIcon icon={faTimes} size="lg" className="text-white hover:text-black" />
                    </button>
                </div>
            </ReactCardFlip>
        </div>
    );
};

export default TwinFlipCard;
