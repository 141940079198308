import React, { useState } from 'react';
import axios from 'axios';
import MediaSelector from '../MediaSelector'; // Medya seçici bileşeni
import { S3File } from '../../services/types'; // S3File tipini import ediyoruz
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import BannerSection from '../../UI/sections/BannerSection'; // BannerSection'ı import ediyoruz

interface BannerFormProps {
    onSave: (data: any) => void;
}

const BannerForm: React.FC<BannerFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // Design ID'yi alıyoruz
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<S3File | null>(null);
    const [url, setUrl] = useState('');
    const [buttonText, setButtonText] = useState(''); // Buton metni için state
    const [error, setError] = useState('');
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme popup'ını kontrol eden state

    const handleSave = async () => {
        if (!selectedMedia || !url || !buttonText) {
            setError('Lütfen bir görsel, URL ve buton metni giriniz.');
            return;
        }

        const componentData = {
            designId: id, // Design ID'yi burada gönderiyoruz
            componentName: 'Banner',
            componentData: { media: selectedMedia.url, url, buttonText }, // Buton metni de ekleniyor
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data);

        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleMediaSelect = (file: S3File) => {
        setSelectedMedia(file);
        setIsMediaSelectorOpen(false);
        setError(''); // Medya seçildiğinde hata mesajı temizlenir
    };

    // Pop-up dışına tıklandığında pop-up'ı kapatacak fonksiyon
    const handleBackgroundClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            setIsPreviewOpen(false);
        }
    };

    return (
        <div>
            {/* Medya Seçimi */}
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal mb-2">Banner Görseli</label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsMediaSelectorOpen(true)}  // Medya seçici popup'ı açılır
                >
                    <span className="text-[#091E42] text-sm">{selectedMedia ? selectedMedia.key : 'S3 Medyası Seç'}</span>
                </div>
                {selectedMedia && (
                    <div className="mt-4">
                        <img src={selectedMedia.url} alt="Banner Görseli" className="w-full h-40 object-cover rounded-lg" />
                    </div>
                )}
                <p className="text-sm text-gray-500 mt-2">Önerilen görsel ölçüleri: 1632x200 px (en iyi çözünürlük için).</p> {/* Görsel ölçü bilgisi eklendi */}
            </div>
            {/* URL Girişi */}
            <div className="mb-4">
                <label className="block text-gray-700">Keşfet URL</label>
                <input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Keşfet butonu için URL"
                />
            </div>

            {/* Buton Metni Girişi */}
            <div className="mb-4">
                <label className="block text-gray-700">Buton Metni</label>
                <input
                    type="text"
                    value={buttonText}
                    onChange={(e) => setButtonText(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Keşfet butonu için metin"
                />
            </div>

            {/* Hata Mesajı */}
            {error && <p className="text-red-500 text-sm">{error}</p>}

            {/* Medya Seçici Bileşeni */}
            <MediaSelector
                isOpen={isMediaSelectorOpen}
                onSelect={handleMediaSelect}
                onClose={() => setIsMediaSelectorOpen(false)}
            />

            {/* Kaydet ve Önizleme Butonları */}
            <div className="flex justify-end space-x-4">
                <button
                    onClick={() => setIsPreviewOpen(true)} // Önizleme popup'ını açar
                    className="px-4 py-2 bg-red-700 text-white rounded-lg"
                >
                    Önizle
                </button>
                <button
                    onClick={handleSave}
                    className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal"
                >
                    Kaydet
                </button>
            </div>

            {/* Önizleme Popup'ı */}
            {isPreviewOpen && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    onClick={handleBackgroundClick} // Pop-up dışına tıklanıldığında kapansın
                >
                    <div className="relative w-full max-w-[90vw] max-h-[90vh] bg-white rounded-lg overflow-hidden p-8"> {/* Üst ve alt boşluklar için padding eklendi */}
                        <button
                            className="absolute top-2 right-2 bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition"
                            onClick={() => setIsPreviewOpen(false)} // Popup'ı kapatır
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* BannerSection bileşeni ile önizleme */}
                        <BannerSection componentData={{ media: selectedMedia?.url || '', url, buttonText }} />
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default BannerForm;
