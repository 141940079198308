import React from 'react'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'
import { Outlet } from 'react-router-dom'

export default function AdminPanelPage() {
  return (
    <>
    <Sidebar />
    <div className="flex flex-col flex-1">
      <Header />
      <div className="flex-1 bg-white p-8">
        <Outlet />
      </div>
    </div>
  </>
  )
}
