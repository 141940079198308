import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // MediaSelector bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin
import TwinTopTitleHeroCardSection from '../../UI/sections/TwinTopTitleHeroCardSection'; // Import section

interface TwinTopTitleHeroCardFormProps {
    onSave: (data: any) => void;
}

const TwinTopTitleHeroCardForm: React.FC<TwinTopTitleHeroCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [isRightMediaSelectorOpen, setIsRightMediaSelectorOpen] = useState(false);
    const [isLeftMediaSelectorOpen, setIsLeftMediaSelectorOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modal durumu

    const [rightMedia, setRightMedia] = useState<S3File | null>(null);
    const [leftMedia, setLeftMedia] = useState<S3File | null>(null);
    const [rightTitle, setRightTitle] = useState('');
    const [rightSubtitle, setRightSubtitle] = useState('');
    const [rightButtonText, setRightButtonText] = useState('');
    const [rightButtonUrl, setRightButtonUrl] = useState('');
    const [leftTitle, setLeftTitle] = useState('');
    const [leftSubtitle, setLeftSubtitle] = useState('');
    const [leftButtonText, setLeftButtonText] = useState('');
    const [leftButtonUrl, setLeftButtonUrl] = useState('');

    // Yeni karakter sınırları
    const maxTitleLength = 35;
    const maxSubtitleLength = 80; // Alt başlık sınırı 80 karakter
    const maxButtonTextLength = 14; // Buton metni için karakter sınırı 14

    const handleSave = async () => {
        if (!rightMedia || !leftMedia) return; // Eğer medya seçilmediyse kaydetme

        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Twin Top Title Hero Card',
            componentData: {
                rightCard: { media: rightMedia?.url, title: rightTitle, subtitle: rightSubtitle, buttonText: rightButtonText, buttonUrl: rightButtonUrl },
                leftCard: { media: leftMedia?.url, title: leftTitle, subtitle: leftSubtitle, buttonText: leftButtonText, buttonUrl: leftButtonUrl },
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet

        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleRightMediaSelect = (file: S3File) => {
        setRightMedia(file);
        setIsRightMediaSelectorOpen(false);
    };

    const handleLeftMediaSelect = (file: S3File) => {
        setLeftMedia(file);
        setIsLeftMediaSelectorOpen(false);
    };

    const handlePreviewOpen = () => {
        setIsPreviewOpen(true); // Modalı açmak için isPreviewOpen state'ini true yapar
    };

    const renderMediaPreview = (media: S3File | null) => {
        if (!media) return null;

        const fileExtension = media.key.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return <img src={media.url} alt={media.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />;
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video controls className="max-w-full max-h-64 object-contain mx-auto rounded-lg">
                    <source src={media.url} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    // Karakter sınırı kontrolü
    const handleTitleChange = (setFunction: React.Dispatch<React.SetStateAction<string>>, value: string, maxLength: number) => {
        if (value.length <= maxLength) {
            setFunction(value);
        }
    };

    return (
        <div className="grid grid-cols-2 gap-4">
            {/* Sağ Kart */}
            <div className="mb-4 p-4 border border-[#dfe2e6] rounded-lg">
                <h3 className="text-[#243757] text-sm font-normal">Sağ Kart</h3>
                <label className="block text-[#243757] text-sm font-normal mt-2">Medya (Önerilen ölçüler: 1060x756 piksel)</label>
                <button
                    onClick={() => setIsRightMediaSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                >
                    <span className="text-[#091E42]">{rightMedia ? rightMedia.key : 'Medya Seç'}</span>
                </button>
                {renderMediaPreview(rightMedia)}
                <label className="block text-[#243757] text-sm font-normal mt-4">Başlık (En fazla 35 karakter)</label>
                <input
                    type="text"
                    value={rightTitle}
                    onChange={(e) => handleTitleChange(setRightTitle, e.target.value, maxTitleLength)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Başlık"
                />
                <p className="text-sm text-gray-500">{rightTitle.length}/{maxTitleLength}</p>

                <label className="block text-[#243757] text-sm font-normal mt-4">Alt Başlık (En fazla 80 karakter)</label>
                <input
                    type="text"
                    value={rightSubtitle}
                    onChange={(e) => handleTitleChange(setRightSubtitle, e.target.value, maxSubtitleLength)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Alt Başlık"
                />
                <p className="text-sm text-gray-500">{rightSubtitle.length}/{maxSubtitleLength}</p>

                <label className="block text-[#243757] text-sm font-normal mt-4">Buton Adı (En fazla 14 karakter)</label>
                <input
                    type="text"
                    value={rightButtonText}
                    onChange={(e) => handleTitleChange(setRightButtonText, e.target.value, maxButtonTextLength)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Buton Adı"
                />
                <p className="text-sm text-gray-500">{rightButtonText.length}/{maxButtonTextLength}</p>

                <label className="block text-[#243757] text-sm font-normal mt-4">Buton Url</label>
                <input
                    type="text"
                    value={rightButtonUrl}
                    onChange={(e) => setRightButtonUrl(e.target.value)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Buton Url"
                />
            </div>

            {/* Sol Kart */}
            <div className="mb-4 p-4 border border-[#dfe2e6] rounded-lg">
                <h3 className="text-[#243757] text-sm font-normal">Sol Kart</h3>
                <label className="block text-[#243757] text-sm font-normal mt-2">Medya (Önerilen ölçüler: 1060x756 piksel)</label>
                <button
                    onClick={() => setIsLeftMediaSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                >
                    <span className="text-[#091E42]">{leftMedia ? leftMedia.key : 'Medya Seç'}</span>
                </button>
                {renderMediaPreview(leftMedia)}
                <label className="block text-[#243757] text-sm font-normal mt-4">Başlık (En fazla 35 karakter)</label>
                <input
                    type="text"
                    value={leftTitle}
                    onChange={(e) => handleTitleChange(setLeftTitle, e.target.value, maxTitleLength)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Başlık"
                />
                <p className="text-sm text-gray-500">{leftTitle.length}/{maxTitleLength}</p>

                <label className="block text-[#243757] text-sm font-normal mt-4">Alt Başlık (En fazla 80 karakter)</label>
                <input
                    type="text"
                    value={leftSubtitle}
                    onChange={(e) => handleTitleChange(setLeftSubtitle, e.target.value, maxSubtitleLength)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Alt Başlık"
                />
                <p className="text-sm text-gray-500">{leftSubtitle.length}/{maxSubtitleLength}</p>

                <label className="block text-[#243757] text-sm font-normal mt-4">Buton Adı (En fazla 14 karakter)</label>
                <input
                    type="text"
                    value={leftButtonText}
                    onChange={(e) => handleTitleChange(setLeftButtonText, e.target.value, maxButtonTextLength)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Buton Adı"
                />
                <p className="text-sm text-gray-500">{leftButtonText.length}/{maxButtonTextLength}</p>

                <label className="block text-[#243757] text-sm font-normal mt-4">Buton Url</label>
                <input
                    type="text"
                    value={leftButtonUrl}
                    onChange={(e) => setLeftButtonUrl(e.target.value)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Buton Url"
                />
            </div>

            <MediaSelector
                isOpen={isRightMediaSelectorOpen}
                onSelect={handleRightMediaSelect}
                onClose={() => setIsRightMediaSelectorOpen(false)}
            />
            <MediaSelector
                isOpen={isLeftMediaSelectorOpen}
                onSelect={handleLeftMediaSelect}
                onClose={() => setIsLeftMediaSelectorOpen(false)}
            />

            {/* Button Section */}
            <div className="w-full flex justify-end space-x-4"> {/* Butonlar sağa hizalı ve boşluklar ayarlanmış */}
                <button onClick={handlePreviewOpen} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizleme
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Ekle
                </button>
            </div>

            {/* Önizleme Modal */}
            {isPreviewOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-6xl w-full">
                        <button
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
                            onClick={() => setIsPreviewOpen(false)}
                        >
                            X
                        </button>
                        <TwinTopTitleHeroCardSection
                            componentData={{
                                rightCard: { media: rightMedia?.url || '', title: rightTitle, subtitle: rightSubtitle, buttonText: rightButtonText, buttonUrl: rightButtonUrl },
                                leftCard: { media: leftMedia?.url || '', title: leftTitle, subtitle: leftSubtitle, buttonText: leftButtonText, buttonUrl: leftButtonUrl },
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TwinTopTitleHeroCardForm;
