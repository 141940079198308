import React, { useState, useEffect } from 'react';
import api from '../services/apiService'; // Axios instance'ı import edin
import { S3File } from '../services/types';

interface ImageSelectorProps {
    onSelect: (file: S3File) => void;
    onClose: () => void;
    isOpen: boolean;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({ onSelect, onClose, isOpen }) => {
    const [files, setFiles] = useState<S3File[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await api.get<S3File[]>('/s3files');
                setFiles(response.data);
                setLoading(false);
            } catch (error) {
                setError('Veriler getirilirken bir hata oluştu');
                setLoading(false);
            }
        };

        if (isOpen) {
            fetchFiles();
        }
    }, [isOpen]);

    const filteredFiles = files.filter(file =>
        file.key.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!isOpen) return null;

    const renderFilePreview = (file: S3File) => {
        const mimeType = file.url.split('.').pop()?.toLowerCase();

        if (
            mimeType?.includes('jpg') ||
            mimeType?.includes('jpeg') ||
            mimeType?.includes('png') ||
            mimeType?.includes('gif') ||
            mimeType?.includes('webp') ||
            mimeType?.includes('avif')
        ) {
            return <img src={file.url} alt={file.key} className="w-full h-full object-cover mb-2" />;
        } else if (mimeType?.includes('mp4') || mimeType?.includes('webm') || mimeType?.includes('ogg')) {
            return <video src={file.url} className="w-full h-full object-cover mb-2" controls />;
        } else if (mimeType?.includes('pdf')) {
            return <iframe src={file.url} title={file.key} className="w-full h-full object-cover mb-2"></iframe>;
        } else {
            return <p className="text-xs text-center">{file.key}</p>;
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full max-h-[90vh] flex flex-col">
                {/* Başlık ve arama çubuğu sabit kısım */}
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-semibold text-gray-700">Medya Seç</h3>
                    <button onClick={onClose} className="text-gray-500 text-xl">&times;</button>
                </div>
                <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full mb-4 px-4 py-2 border border-gray-300 rounded-md"
                />

                {/* Kaydırılabilir dosya listesi */}
                <div className="overflow-y-auto">
                    {loading && <p>Yükleniyor...</p>}
                    {error && <p className="text-red-600">{error}</p>}
                    {!loading && !error && (
                        <div className="grid grid-cols-3 gap-4">
                            {filteredFiles.map((file, index) => (
                                <div
                                    key={index}
                                    className="border p-2 cursor-pointer flex flex-col items-center"
                                    onClick={() => onSelect(file)}
                                    style={{ width: '150px', height: '150px' }} // Her bir kutuyu sabit boyutta yapıyoruz
                                >
                                    {renderFilePreview(file)}
                                    <p className="text-xs text-center">{file.key}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImageSelector;
