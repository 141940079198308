import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // Medya seçici bileşenini doğru şekilde import edin
import LargeTopTitleHeroCardSection from '../../UI/sections/LargeTopTitleHeroCardSection'; // Section dosyanızı import edin
import { motion } from 'framer-motion'; // Animasyon için framer-motion import edin
import { S3File } from '../../services/types'; // S3File tipini import edin

interface LargeTopTitleHeroCardFormProps {
    onSave: (data: any) => void;
}

const LargeTopTitleHeroCardForm: React.FC<LargeTopTitleHeroCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [buttonUrl, setButtonUrl] = useState('');
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false); // Medya seçici için modal state'i
    const [selectedMedia, setSelectedMedia] = useState<S3File | null>(null); // Seçilen medya dosyası
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Pop-up state

    // Başlık ve alt başlık karakter sınırlamaları
    const MAX_TITLE_LENGTH = 50; // Başlık için yeni maksimum uzunluk
    const MAX_SUBTITLE_LENGTH = 65; // Alt başlık için yeni maksimum uzunluk

    const handleSave = async () => {
        if (!selectedMedia) return; // Eğer medya seçilmediyse kaydetme

        const componentData = {
            designId: id,
            componentName: 'Large Top Title Hero Card',
            componentData: { title, subtitle, buttonText, buttonUrl, media: selectedMedia?.url || '' }, // Seçilen medya dosyasını ekledik
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
          
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleMediaSelect = (file: S3File) => {
        setSelectedMedia(file);
        setIsMediaSelectorOpen(false);
    };

    // Pop-up açma ve kapatma fonksiyonları
    const handlePreviewOpen = () => setIsPreviewOpen(true);
    const handlePreviewClose = () => setIsPreviewOpen(false);

    // Dosya türüne göre doğru medya gösterimini belirleyen fonksiyon
    const renderMediaPreview = (media: S3File | null) => {
        if (!media) {
            return (
                <img
                    src="https://via.placeholder.com/1920x1080?text=Anonim+Görsel"
                    alt="Anonim Görsel"
                    className="max-w-full max-h-64 object-contain mx-auto rounded-lg"
                />
            );
        }
    
        const fileExtension = media.url.split('.').pop()?.toLowerCase();
    
        if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'webp') {
            return <img src={media.url} alt={media.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />;
        } else if (fileExtension === 'mp4' || fileExtension === 'webm') {
            return (
                <video controls className="max-w-full max-h-64 mx-auto rounded-lg">
                    <source src={media.url} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else if (fileExtension === 'pdf') {
            return (
                <a href={media.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                    PDF Dosyasını Görüntüle
                </a>
            );
        } else {
            return <p>Bu dosya tipi görüntülenemez: {media.key}</p>;
        }
    };

    return (
        <div>
            {/* Başlık */}
            <div className="mb-4">
                <label className="block text-gray-700">Başlık (En fazla 50 karakter)</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => {
                        if (e.target.value.length <= MAX_TITLE_LENGTH) setTitle(e.target.value);
                    }}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Başlık"
                />
                <p className="text-sm text-gray-500 mt-1">
                    {title.length}/{MAX_TITLE_LENGTH} karakter
                </p>
            </div>

            {/* Alt Başlık */}
            <div className="mb-4">
                <label className="block text-gray-700">Alt Başlık (En fazla 65 karakter)</label>
                <input
                    type="text"
                    value={subtitle}
                    onChange={(e) => {
                        if (e.target.value.length <= MAX_SUBTITLE_LENGTH) setSubtitle(e.target.value);
                    }}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Alt Başlık"
                />
                <p className="text-sm text-gray-500 mt-1">
                    {subtitle.length}/{MAX_SUBTITLE_LENGTH} karakter
                </p>
            </div>

            {/* Buton Adı */}
            <div className="mb-4">
                <label className="block text-gray-700">Buton Adı</label>
                <input
                    type="text"
                    value={buttonText}
                    onChange={(e) => setButtonText(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Buton Adı"
                />
            </div>

            {/* Buton Url */}
            <div className="mb-4">
                <label className="block text-gray-700">Buton Url</label>
                <input
                    type="text"
                    value={buttonUrl}
                    onChange={(e) => setButtonUrl(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Buton Url"
                />
            </div>

            {/* Medya Seçimi */}
            <div className="mb-4">
                <label className="block text-gray-700">Medya (Önerilen boyutlar: 1920x1080 piksel)</label>
                <div
                    className="relative w-full p-2 border border-gray-300 rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsMediaSelectorOpen(true)}  // Medya seçici popup'ını aç
                >
                    <span className="text-gray-700 text-sm">{selectedMedia ? selectedMedia.key : 'Medya Seç'}</span>
                    <svg className="w-4 h-4 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>
                {selectedMedia && (
                    <div className="mt-4">
                        {renderMediaPreview(selectedMedia)} {/* Seçilen medya dosyasını göster */}
                    </div>
                )}
            </div>

            <MediaSelector
                isOpen={isMediaSelectorOpen}
                onSelect={handleMediaSelect}
                onClose={() => setIsMediaSelectorOpen(false)}
            />

            {/* Butonlar */}
            <div className="flex justify-end space-x-4">
                <button onClick={handlePreviewOpen} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {/* Pop-up Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={handlePreviewClose} // Pop-up dışına tıklandığında kapatılacak
                >
                    <motion.div
                        className="relative bg-white rounded-lg overflow-hidden"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        style={{
                            width: '95vw', // Sayfadaki genişlik değerini koruyarak açılacak
                            height: '80vh', // Sayfadaki yükseklik değerini koruyarak açılacak
                        }}
                    >
                        {/* Section ile gerçek kartın önizlemesi */}
                        <LargeTopTitleHeroCardSection
                            componentData={{
                                title,
                                subtitle,
                                buttonText,
                                buttonUrl,
                                media: selectedMedia?.url || '', // Medya URL'si (boş ise 'media' olmayabilir)
                            }}
                        />

                        {/* Kapatma Butonu */}
                        <button
                            className="absolute top-2 right-2 text-black-500 hover:text-black-700"
                            onClick={handlePreviewClose}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default LargeTopTitleHeroCardForm;
