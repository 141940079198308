import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LogoSelector from '../MediaSelector'; // LogoSelector bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin
import BottomTextCardSection from '../../UI/sections/BottomTextCardSection'; // BottomTextCardSection import edin

interface BottomTextCardFormProps {
    onSave: (data: any) => void;
}

const BottomTextCardForm: React.FC<BottomTextCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi burada kullanıyoruz
    const [text, setText] = useState('');
    const [selectedMedia, setSelectedMedia] = useState<S3File | null>(null); // Seçilen medya dosyası
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false); // Medya seçici modalı açık mı kapalı mı?
    const [textError, setTextError] = useState(''); // Karakter limiti uyarısı için
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modal state

    const handleSave = async () => {
        if (!selectedMedia) return; // Eğer medya seçilmediyse kaydetme

        if (text.length > 250) {
            setTextError('Yazı 250 karakterden uzun olamaz!');
            return;
        }

        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Bottom Text Card',
            componentData: { text, media: selectedMedia.url }, // Seçilen medya dosyasının URL'si
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet

        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleMediaSelect = (file: S3File) => {
        setSelectedMedia(file);
        setIsMediaSelectorOpen(false);
    };

    const renderMediaPreview = () => {
        if (!selectedMedia) return null;

        const fileExtension = selectedMedia.key.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return <img src={selectedMedia.url} alt={selectedMedia.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />;
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video controls className="max-w-full max-h-64 mx-auto rounded-lg">
                    <source src={selectedMedia.url} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setText(value);

        if (value.length > 250) {
            setTextError('Yazı 250 karakteri aşamaz.');
        } else {
            setTextError('');
        }
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal">Medya</label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsMediaSelectorOpen(true)}
                >
                    <span className="text-[#091E42] text-sm">{selectedMedia ? selectedMedia.key : 'Seç'}</span>
                    <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>
                <p className="text-sm text-gray-500 mt-2">Önerilen görsel ölçüleri: 1632x658px (en iyi sonuç için).</p> {/* Ölçü bilgisi eklendi */}
                <div className="mt-4">{renderMediaPreview()}</div>
            </div>

            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal">Yazı</label>
                <textarea
                    value={text}
                    onChange={handleTextChange}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-lg"
                    placeholder="Yazı Alanı"
                    maxLength={250}
                />
                <p className="text-sm text-gray-500">{text.length}/250 karakter</p>
                {textError && <p className="text-red-500 text-sm">{textError}</p>}
            </div>

            <LogoSelector
                isOpen={isMediaSelectorOpen}
                onSelect={handleMediaSelect}
                onClose={() => setIsMediaSelectorOpen(false)}
            />

            <div className="flex justify-end space-x-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {/* Önizleme Modal */}
            {isPreviewOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50" onClick={() => setIsPreviewOpen(false)}>
                    <div className="bg-white rounded-lg p-6 w-full h-full overflow-auto relative" onClick={() => setIsPreviewOpen(false)}>
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-black-500 hover:text-black-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* BottomTextCardSection bileşeni ile önizleme yapıyoruz */}
                        <BottomTextCardSection
                            componentData={{ media: selectedMedia?.url || '', text }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default BottomTextCardForm;
