import React from 'react';
import { useInView } from 'react-intersection-observer';

interface BottomTextCardSectionProps {
    componentData: {
        text: string;
        media: string;
    };
}

const BottomTextCardSection: React.FC<BottomTextCardSectionProps> = ({ componentData }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const defaultImage = 'https://via.placeholder.com/1200x800?text=Anonim+Görsel';

    const renderMediaPreview = () => {
        const mediaUrl = componentData.media || defaultImage;
        const fileExtension = mediaUrl.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return (
                <img
                    src={mediaUrl}
                    alt="Selected Media"
                    className="w-full h-full object-cover"
                />
            );
        }

        if (['mp4', 'webm', 'ogg'].includes(fileExtension || '')) {
            return (
                <video
                    className="w-full h-full object-cover"
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src={mediaUrl} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        if (fileExtension === 'pdf') {
            return (
                <iframe
                    src={mediaUrl}
                    className="w-full h-full object-cover"
                    title="PDF Viewer"
                ></iframe>
            );
        }

        if (['mp3', 'wav', 'ogg'].includes(fileExtension || '')) {
            return (
                <audio controls className="w-full h-full">
                    <source src={mediaUrl} type={`audio/${fileExtension}`} />
                    Your browser does not support the audio element.
                </audio>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    return (
        <div
            className="p-0 shadow-none mx-auto overflow-hidden rounded-t-3xl w-full md:w-[85vw] h-auto md:h-[70vh]" // Mobilde tam genişlik ve otomatik yükseklik
        >
            <div className="flex flex-col justify-between items-center w-full h-full">
                {/* Medya Alanı */}
                <div
                    className="w-full h-auto md:h-[80%] flex justify-center items-center" // Mobilde yükseklik otomatik, geniş ekranlarda %80
                    ref={ref}
                    style={{
                        opacity: inView ? 1 : 0,
                        transform: inView ? 'translateY(0)' : 'translateY(80px)',
                        transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
                    }}
                >
                    {renderMediaPreview()}
                </div>
    
                {/* Text Alanı */}
                <div className="text-center py-1 w-[95%] border-t"> 
                    <p
                        className="text-lg md:text-3xl font-bold text-gray-900 leading-tight break-words" // Mobilde daha küçük yazı boyutu
                        style={{ fontFamily: 'Radhanji, sans-serif' }} 
                    >
                        {componentData.text}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BottomTextCardSection;
