import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // Medya seçici bileşenini import edin
import LargeScalableCardSection from '../../UI/sections/LargeScalableCardSection'; // Section dosyanızı import edin
import { motion } from 'framer-motion'; // framer-motion'ı import edin
import { S3File } from '../../services/types'; // S3File tipini import edin

interface LargeScalableCardFormProps {
    onSave: (data: any) => void;
}

const LargeScalableCardForm: React.FC<LargeScalableCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false); // Modal açık mı kapalı mı?
    const [selectedMedia, setSelectedMedia] = useState<S3File | null>(null); // Seçilen medya dosyası
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Pop-up açık mı?

    const defaultMediaUrl = 'https://via.placeholder.com/1042x337?text=No+Media+Available'; // Anonim görsel URL'si

    // Pop-up'ı açmak için kullanılan fonksiyon
    const handlePreviewOpen = () => {
        setIsPreviewOpen(true);
    };

    // Pop-up'ı kapatmak için kullanılan fonksiyon
    const handlePreviewClose = () => {
        setIsPreviewOpen(false);
    };

    const handleSave = async () => {
        const mediaUrl = selectedMedia ? selectedMedia.url : defaultMediaUrl; // Medya yoksa anonim görseli kullan

        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Large Scalable Card',
            componentData: { media: mediaUrl }, // Seçilen medya dosyasının URL'si veya anonim görsel
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
     
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleMediaSelect = (file: S3File) => {
        setSelectedMedia(file);
        setIsMediaSelectorOpen(false);
    };

    // Medya türüne göre doğru medya gösterimini belirleyen fonksiyon
    const renderMediaPreview = (mediaUrl: string) => {
        const fileExtension = mediaUrl.split('.').pop()?.toLowerCase();

        if (['jpg', 'png', 'jpeg', 'webp'].includes(fileExtension || '')) {
            return (
                <>
                    <img src={mediaUrl} alt="Selected Media" className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />
                    <p className="text-sm text-gray-500 mt-2">Önerilen boyut: 1042x337 piksel (Görsel)</p>
                </>
            );
        } else if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <>
                    <video controls className="max-w-full max-h-64 mx-auto rounded-lg">
                        <source src={mediaUrl} type={`video/${fileExtension}`} />
                        Tarayıcınız bu videoyu desteklemiyor.
                    </video>
                    <p className="text-sm text-gray-500 mt-2">Önerilen boyut: 1042x337 piksel (Video)</p>
                </>
            );
        } else if (fileExtension === 'pdf') {
            return (
                <a href={mediaUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                    PDF Dosyasını Görüntüle
                </a>
            );
        } else {
            return null; // Desteklenmeyen medya için mesaj göstermeyeceğiz
        }
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal mb-2">
                    Medya (Önerilen boyutlar: 1042x337 piksel)
                </label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsMediaSelectorOpen(true)}  // Medya seçici popup'ını aç
                >
                    <span className="text-[#091E42] text-sm">{selectedMedia ? selectedMedia.key : 'Medya Seç'}</span>
                    <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>
                {renderMediaPreview(selectedMedia ? selectedMedia.url : defaultMediaUrl)} {/* Seçilen medya yoksa anonim görseli göster */} 
            </div>

            <MediaSelector
                isOpen={isMediaSelectorOpen}
                onSelect={handleMediaSelect}
                onClose={() => setIsMediaSelectorOpen(false)}
            />

            <div className="flex justify-end space-x-4">
                <button onClick={handlePreviewOpen} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Ekle
                </button>
            </div>

            {/* Pop-up Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={handlePreviewClose} // Tıkladığında kapatılacak
                >
                    <motion.div
                        className="relative bg-white rounded-lg overflow-hidden"
                        initial={{ scale: 0.8, opacity: 0 }} // Küçük başlayıp büyüme
                        animate={{ scale: 1, opacity: 1 }}   // Tam ekran büyüme animasyonu
                        transition={{ duration: 0.7, ease: 'easeOut' }} // Yumuşak geçiş
                        style={{
                            width: '100%', // Ekran genişliği kadar genişlet
                            height: 'auto', // Yükseklik kartın dinamik yapısına göre ayarlanacak
                        }}
                    >
                        {/* Section dosyasındaki LargeScalableCardSection bileşeni */}
                        <LargeScalableCardSection
                            componentData={{
                                media: selectedMedia?.url || defaultMediaUrl, // Medya yoksa anonim görseli kullan
                                text: '' // Text özelliği de eklenebilir
                            }}
                        />

                        {/* Kapatma butonu */}
                        <button
                            className="absolute top-2 right-2 text-red-black hover:text-black-700 transition-all"
                            onClick={handlePreviewClose} // Butonla da kapatılabilir
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default LargeScalableCardForm;
