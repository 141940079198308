import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import FullTextCardSection from '../../UI/sections/FullTextCardSection'; // FullTextCardSection bileşenini import edin

interface FullTextCardFormProps {
    onSave: (data: any) => void;
}

const FullTextCardForm: React.FC<FullTextCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [text, setText] = useState('');
    const [textError, setTextError] = useState(''); // Hata mesajını tutmak için state
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modal state

    const handleSave = async () => {
        if (text.length > 600) {
            setTextError('Yazı 600 karakterden fazla olamaz!'); // Karakter sınırını aşıyorsa uyarı
            return;
        }

        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Full Text',
            componentData: { text },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
            
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (value.length <= 600) {
            setText(value);
            setTextError(''); // Eğer sınırı aşmazsa hata mesajını temizle
        } else {
            setTextError('Yazı 600 karakterden fazla olamaz!'); // Karakter sınırı uyarısı
        }
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-gray-700">Yazı</label>
                <textarea
                    value={text}
                    onChange={handleTextChange}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Yazı Alanı (600 karakter sınırı)"
                    maxLength={600} // Tarayıcı bazında da sınır koyar
                />
                <div className="text-right text-sm text-gray-500 mt-1">
                    {text.length}/600 karakter {/* Karakter sayacı */}
                </div>
                {textError && <p className="text-red-500 text-sm">{textError}</p>} {/* Hata mesajı */}
            </div>
            <div className="flex justify-end space-x-4">
                <button
                    onClick={handlePreview}
                    className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow"
                >
                    Önizle
                </button>
                <button
                    onClick={handleSave}
                    className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight"
                >
                    Ekle
                </button>
            </div>

            {/* Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={() => setIsPreviewOpen(false)} // Modal dışına tıklanınca kapanacak
                >
                    <div
                        className="relative w-full max-w-[90%] h-full bg-white rounded-lg p-6 overflow-auto"
                        onClick={(e) => e.stopPropagation()} // Modal içeriğine tıklanınca kapanmayacak
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-black-500 hover:text-black-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* FullTextCardSection bileşeni ile önizleme yapıyoruz */}
                        <div className="w-full max-w-[1000px] h-[85vh] mx-auto">
                            <FullTextCardSection componentData={{ text }} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FullTextCardForm;
