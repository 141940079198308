import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; 
import { S3File } from '../../services/types'; 
import TwinCardSection from '../../UI/sections/TwinCardSection'; 

interface TwinCardFormProps {
    onSave: (data: any) => void;
}

const TwinCardForm: React.FC<TwinCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); 
    const [rightMedia, setRightMedia] = useState<S3File | null>(null);
    const [leftMedia, setLeftMedia] = useState<S3File | null>(null);
    const [rightPoster, setRightPoster] = useState<S3File | null>(null); // Sağ medya için kapak fotoğrafı
    const [leftPoster, setLeftPoster] = useState<S3File | null>(null); // Sol medya için kapak fotoğrafı
    const [isRightMediaSelectorOpen, setIsRightMediaSelectorOpen] = useState(false);
    const [isLeftMediaSelectorOpen, setIsLeftMediaSelectorOpen] = useState(false);
    const [isRightPosterSelectorOpen, setIsRightPosterSelectorOpen] = useState(false); // Sağ medya kapak fotoğrafı seçici
    const [isLeftPosterSelectorOpen, setIsLeftPosterSelectorOpen] = useState(false); // Sol medya kapak fotoğrafı seçici
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const handleSave = async () => {
        if (!rightMedia || !leftMedia) return;

        const componentData = {
            designId: id, 
            componentName: 'Twin Card',
            componentData: {
                rightMedia: rightMedia.url,
                rightPoster: rightPoster?.url || '', // Sağ medya kapak fotoğrafı
                leftMedia: leftMedia.url,
                leftPoster: leftPoster?.url || '' // Sol medya kapak fotoğrafı
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data);
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleRightMediaSelect = (file: S3File) => {
        setRightMedia(file);
        setIsRightMediaSelectorOpen(false);
    };

    const handleLeftMediaSelect = (file: S3File) => {
        setLeftMedia(file);
        setIsLeftMediaSelectorOpen(false);
    };

    const handleRightPosterSelect = (file: S3File) => {
        setRightPoster(file);
        setIsRightPosterSelectorOpen(false);
    };

    const handleLeftPosterSelect = (file: S3File) => {
        setLeftPoster(file);
        setIsLeftPosterSelectorOpen(false);
    };

    const renderPreview = (url: string) => {
        const fileType = url.split('.').pop()?.toLowerCase();

        if (!fileType) return <p className="text-sm text-[#091E42]">Desteklenmeyen dosya türü</p>;

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileType)) {
            return <img src={url} alt="Media" className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />;
        } else if (['mp4', 'webm', 'ogg'].includes(fileType)) {
            return <video controls src={url} className="max-w-full max-h-64 mx-auto rounded-lg" />;
        } else {
            return <p className="text-sm text-[#091E42]">Desteklenmeyen dosya türü</p>;
        }
    };

    const handleModalClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-gray-700">Sağ Medya (Önerilen ölçüler: 1060x756 piksel)</label>
                <div
                    className="relative w-full p-2 border border-gray-300 rounded flex items-center justify-between cursor-pointer"
                    onClick={() => setIsRightMediaSelectorOpen(true)}
                >
                    <span className="text-gray-500">{rightMedia ? 'Medya Seçildi' : 'Seç'}</span>
                </div>
                {rightMedia && (
                    <div className="mt-2">
                        {renderPreview(rightMedia.url)}
                    </div>
                )}
            </div>

            <div className="mb-4">
                <label className="block text-gray-700">Sağ Medya Kapak Fotoğrafı (Opsiyonel)</label>
                <div
                    className="relative w-full p-2 border border-gray-300 rounded flex items-center justify-between cursor-pointer"
                    onClick={() => setIsRightPosterSelectorOpen(true)}
                >
                    <span className="text-gray-500">{rightPoster ? 'Kapak Fotoğrafı Seçildi' : 'Seç'}</span>
                </div>
                {rightPoster && (
                    <div className="mt-2">
                        <img src={rightPoster.url} alt="Kapak Fotoğrafı" className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />
                    </div>
                )}
            </div>

            <div className="mb-4">
                <label className="block text-gray-700">Sol Medya (Önerilen ölçüler: 1060x756 piksel)</label>
                <div
                    className="relative w-full p-2 border border-gray-300 rounded flex items-center justify-between cursor-pointer"
                    onClick={() => setIsLeftMediaSelectorOpen(true)}
                >
                    <span className="text-gray-500">{leftMedia ? 'Medya Seçildi' : 'Seç'}</span>
                </div>
                {leftMedia && (
                    <div className="mt-2">
                        {renderPreview(leftMedia.url)}
                    </div>
                )}
            </div>

            <div className="mb-4">
                <label className="block text-gray-700">Sol Medya Kapak Fotoğrafı (Opsiyonel)</label>
                <div
                    className="relative w-full p-2 border border-gray-300 rounded flex items-center justify-between cursor-pointer"
                    onClick={() => setIsLeftPosterSelectorOpen(true)}
                >
                    <span className="text-gray-500">{leftPoster ? 'Kapak Fotoğrafı Seçildi' : 'Seç'}</span>
                </div>
                {leftPoster && (
                    <div className="mt-2">
                        <img src={leftPoster.url} alt="Kapak Fotoğrafı" className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />
                    </div>
                )}
            </div>

            <MediaSelector
                isOpen={isRightMediaSelectorOpen}
                onSelect={handleRightMediaSelect}
                onClose={() => setIsRightMediaSelectorOpen(false)}
            />

            <MediaSelector
                isOpen={isRightPosterSelectorOpen}
                onSelect={handleRightPosterSelect}
                onClose={() => setIsRightPosterSelectorOpen(false)}
            />

            <MediaSelector
                isOpen={isLeftMediaSelectorOpen}
                onSelect={handleLeftMediaSelect}
                onClose={() => setIsLeftMediaSelectorOpen(false)}
            />

            <MediaSelector
                isOpen={isLeftPosterSelectorOpen}
                onSelect={handleLeftPosterSelect}
                onClose={() => setIsLeftPosterSelectorOpen(false)}
            />

            <div className="flex justify-end gap-4">
                <button onClick={() => setIsPreviewOpen(true)} className="px-4 py-2.5 bg-red-700 text-white rounded-lg">
                    Önizleme
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Ekle
                </button>
            </div>

            {isPreviewOpen && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                    onClick={() => setIsPreviewOpen(false)}
                >
                    <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-6xl w-full" onClick={handleModalClick}>
                        <button
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
                            onClick={() => setIsPreviewOpen(false)}
                        >
                            X
                        </button>
                        <TwinCardSection
                            componentData={{
                                leftMedia: leftMedia?.url || '',
                                leftPoster: leftPoster?.url || '',
                                rightMedia: rightMedia?.url || '',
                                rightPoster: rightPoster?.url || ''
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TwinCardForm;
