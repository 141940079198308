import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { MdFileDownload, MdDelete } from "react-icons/md";
import axios from 'axios';
import Cookies from 'js-cookie';

Modal.setAppElement('#root'); // Popup'ın erişilebilirliği için gerekli

interface NewMediaPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewMediaPopup: React.FC<NewMediaPopupProps> = ({ isOpen, onClose }) => {
  const [mediaName, setMediaName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [filePreviews, setFilePreviews] = useState<string[]>([]); // Dosya önizleme URL'leri
  const [loading, setLoading] = useState(false); // Loading durumu

  useEffect(() => {
    if (isOpen) {
      setMediaName('');
      setSelectedFiles([]);
      setFilePreviews([]);
      setLoading(false); // Popup açıldığında loading'i sıfırla
    }
  }, [isOpen]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);

      // Dosya boyutu kontrolü (250 MB sınırı)
      const oversizedFiles = filesArray.filter(file => file.size > 250 * 1024 * 1024);
      if (oversizedFiles.length > 0) {
        alert('Seçilen dosyalardan bazıları 250 MB limitini aşıyor.');
        return;
      }

      // Tüm yeni dosyaları önceki dosyalarla birleştiriyoruz
      setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);

      // Yeni dosyaların önizlemelerini oluşturuyoruz
      const newPreviews = filesArray.map((file) => URL.createObjectURL(file));
      setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
    }
  };

  const handleDelete = (index: number) => {
    // Belirtilen dosya ve önizlemeyi kaldırıyoruz
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFilePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    if (selectedFiles.length === 0) return; // Hiç dosya seçilmediyse işlem yapılmaz

    setLoading(true); // Yükleme başladığında loading aktif

    try {
      const token = Cookies.get('adminToken');

      // Her dosya için ayrı bir POST isteği yapılıyor
      for (let file of selectedFiles) {
        const formData = new FormData();
        formData.append('mediaName', mediaName);
        formData.append('file', file);

        await axios.post(`${process.env.REACT_APP_API_URL}/media`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
      }

      setLoading(false); // Yükleme tamamlandığında loading devre dışı
      onClose(); // Yükleme tamamlandıktan sonra modal kapatılır
    } catch (error) {
      console.error('Dosya yüklenirken bir hata oluştu:', error);
      setLoading(false); // Hata oluşursa loading devre dışı
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Yeni Medya Ekle"
      className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-[1000] flex justify-center items-center"
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-[#192055]">Yeni Medya Ekle</h3>
        <button onClick={onClose} className="text-gray-500 text-xl">&times;</button>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Medya/Grup Adı</label>
        <input
          type="text"
          placeholder="Medya/Grup Adı"
          className="border border-gray-300 rounded p-2 w-full"
          value={mediaName}
          onChange={(e) => setMediaName(e.target.value)}
          disabled={loading} // Loading sırasında inputları kilitle
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Tekli/Çoklu Medya Yükle</label>
        <div className="flex items-center mt-2">
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="hidden"
            id="file-input"
            disabled={loading} // Loading sırasında dosya seçimini kilitle
          />
          <label htmlFor="file-input" className="flex items-center justify-center bg-gray-500 text-white py-2 px-4 rounded cursor-pointer">
            <MdFileDownload className="mr-2" />
            Dosya Seç
          </label>
        </div>
      </div>

      {/* Önizleme Alanı */}
      <div className="grid grid-cols-3 gap-4 mb-4">
        {filePreviews.map((preview, index) => (
          <div key={index} className="w-24 h-24 border border-gray-300 rounded overflow-hidden relative">
            {selectedFiles[index].type.startsWith('image/') ? (
              <img src={preview} alt="Preview" className="w-full h-full object-cover" />
            ) : selectedFiles[index].type.startsWith('video/') ? (
              <video src={preview} className="w-full h-full object-cover" controls />
            ) : (
              <span>Desteklenmeyen format</span>
            )}
            {/* Silme butonu */}
            <button
              onClick={() => handleDelete(index)}
              className="absolute top-1 right-1 text-red-500 bg-white rounded-full p-1"
              disabled={loading} // Loading sırasında silme işlemini kilitle
            >
              <MdDelete />
            </button>
          </div>
        ))}
      </div>

      <button
        onClick={handleSave}
        className="bg-red-700 text-white py-2 px-4 rounded"
        disabled={loading} // Loading sırasında butonu kilitle
      >
        {loading ? 'Yükleniyor...' : 'Kaydet'}
      </button>
    </Modal>
  );
};

export default NewMediaPopup;
