import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface HeaderSectionProps {
    componentData: {
        title: string;
        logoMedia: string;
    };
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ componentData }) => {
    // Intersection Observer hook'u kullanarak görünürlük kontrolü yapıyoruz
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    return (
        <div
            ref={ref}
            className="flex justify-between items-center w-full max-w-[95vw] h-auto mx-auto bg-white relative px-4 md:px-6 py-4" // Genişlik ve padding mobilde optimize edildi
            style={{ overflow: 'hidden' }} // Taşmaları önlemek için overflow: hidden eklendi
        >
            {/* Sol tarafta başlık */}
            <motion.h1
                className="text-gray-800 font-bold text-[40px] md:text-[70px] pl-8"  // Mobilde daha küçük boyut
                style={{
                    fontFamily: 'Radhanji, sans-serif', // Font ailesi Radhanji olarak ayarlandı
                    paddingLeft: '20px', // Sol padding mobilde azaltıldı
                    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.3)', // Gölgelendirme eklendi
                }}
                initial={{ x: -100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 1.2, ease: 'easeOut' }}
            >
                {componentData.title}
            </motion.h1>

            {/* Sağ tarafta logo */}
            <motion.div
                className="flex items-center h-auto pr-8" 
                initial={{ x: 100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 1.2, ease: 'easeOut' }}
            >
                <img
                     src={componentData.logoMedia}
                     alt="Logo"
                     className="object-contain w-[70px] h-[70px] md:w-[120px] md:h-[120px]" // Mobil ve büyük ekranlar için logo boyutu optimize edildi
                />
            </motion.div>
        </div>
    );
};

export default HeaderSection;
