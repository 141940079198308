import create from 'zustand';

interface DesignElement {
    id: string;
    type: string;
    content: string;
    showInFragman: boolean;
    order: number;
}

interface DesignStore {
    activePage: 'tasarla' | 'seo';
    designElements: DesignElement[];
    preview: boolean;
    isModalOpen: boolean;
    setActivePage: (page: 'tasarla' | 'seo') => void;
    setDesignElements: (elements: DesignElement[]) => void;
    setPreview: (preview: boolean) => void;
    setIsModalOpen: (isOpen: boolean) => void;
    addElement: (element: Omit<DesignElement, 'order'>) => void; // order değeri olmadan ekleme
    updateElement: (id: string, updatedElement: Partial<DesignElement>) => void;
    deleteElement: (id: string) => void;
    moveElementUp: (index: number) => void;
    moveElementDown: (index: number) => void;
}

const useDesignStore = create<DesignStore>((set) => ({
    activePage: 'tasarla',
    designElements: [],
    preview: false,
    isModalOpen: false,
    setActivePage: (page) => set({ activePage: page }),
    setDesignElements: (elements) => set({ designElements: elements }),
    setPreview: (preview) => set({ preview }),
    setIsModalOpen: (isOpen) => set({ isModalOpen: isOpen }),
    addElement: (element) =>
        set((state) => {
            const newOrder = state.designElements.length + 1; // Yeni bileşen için order değeri belirleme
            return {
                designElements: [...state.designElements, { ...element, order: newOrder }],
            };
        }),
    updateElement: (id, updatedElement) =>
        set((state) => ({
            designElements: state.designElements.map((el) =>
                el.id === id ? { ...el, ...updatedElement } : el
            ),
        })),
    deleteElement: (id) =>
        set((state) => {
            const newElements = state.designElements.filter((el) => el.id !== id);
            // Silmeden sonra sıralamayı yeniden düzenleme
            newElements.forEach((el, i) => {
                el.order = i + 1;
            });
            return { designElements: newElements };
        }),
    moveElementUp: (index) =>
        set((state) => {
            const newElements = [...state.designElements];
            if (index > 0) {
                const temp = newElements[index];
                newElements[index] = newElements[index - 1];
                newElements[index - 1] = temp;
            }
            // Sıralama değerlerini tutarlı hale getirme
            newElements.forEach((el, i) => {
                el.order = i + 1;
            });
            return { designElements: newElements };
        }),
    moveElementDown: (index) =>
        set((state) => {
            const newElements = [...state.designElements];
            if (index < newElements.length - 1) {
                const temp = newElements[index];
                newElements[index] = newElements[index + 1];
                newElements[index + 1] = temp;
            }
            // Sıralama değerlerini tutarlı hale getirme
            newElements.forEach((el, i) => {
                el.order = i + 1;
            });
            return { designElements: newElements };
        }),
}));

export default useDesignStore;
