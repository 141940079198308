import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface ReelsCardSliderSectionProps {
    componentData: {
        reelsCards: Array<{
            media: string;
            title: string;
            subtitle: string;
        }>;
    };
}

const ReelsCardSliderSection: React.FC<ReelsCardSliderSectionProps> = ({ componentData }) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const [selectedMedia, setSelectedMedia] = useState<string | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    // Kart sayısını iki katına çıkarıyoruz (sonsuz döngü etkisi için)
    const duplicatedReelsCards = [...componentData.reelsCards, ...componentData.reelsCards];

    // Otomatik kaydırma işlemi
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isTransitioning && !isPaused) {
                setActiveIndex((prevIndex) => prevIndex + 1);
            }
        }, 3000); // 3 saniyede bir kaydırma

        return () => clearInterval(interval); // Component unmount olduğunda interval temizlenecek
    }, [isTransitioning, isPaused]);

    // Medyanın video olup olmadığını kontrol eden fonksiyon
    const isVideo = (media: string) => {
        const videoExtensions = ['mp4', 'webm', 'ogg'];
        const extension = media.split('.').pop();
        return extension && videoExtensions.includes(extension.toLowerCase());
    };

    // Pop-up kapatma fonksiyonu
    const closeModal = () => {
        setSelectedMedia(null);
    };

    // Modal dışında bir yere tıklandığında kapatma
    const handleBackgroundClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    // Otomatik kaydırmayı sağlamak için referansla scroll ayarlanması
    useEffect(() => {
        if (sliderRef.current) {
            const cardWidth = 320; // Kart genişliği
            const totalScrollLeft = cardWidth * activeIndex; // Scroll yapılacak pozisyon

            setIsTransitioning(true); // Geçişi başlatıyoruz
            sliderRef.current.scrollTo({
                left: totalScrollLeft,
                behavior: 'smooth',
            });

            // Geçiş tamamlandıktan sonra, döngünün sonsuz gibi görünmesini sağlamak için
            const timer = setTimeout(() => {
                if (activeIndex >= componentData.reelsCards.length) {
                    // Başlangıç konumuna anında geri götür
                    sliderRef.current?.scrollTo({
                        left: cardWidth * (activeIndex - componentData.reelsCards.length),
                        behavior: 'auto',
                    });
                    setActiveIndex((prevIndex) => prevIndex - componentData.reelsCards.length);
                }
                setIsTransitioning(false); // Geçiş tamamlandı
            }, 500); // Smooth scroll'un tamamlanması için kısa bir süre tanıyoruz

            return () => clearTimeout(timer);
        }
    }, [activeIndex, componentData.reelsCards.length]);

    return (
        <div className="relative w-full flex justify-center items-center">
            {/* Slider */}
            <div
                ref={sliderRef}
                className="max-w-[95%] overflow-x-scroll flex space-x-5 py-4 px-2 scrollbar-hide"
                style={{
                    scrollSnapType: 'x mandatory',
                    WebkitOverflowScrolling: 'touch',
                }}
            >
                {duplicatedReelsCards.map((card, index) => (
                    <div
                        key={index}
                        className="relative flex-shrink-0 w-[320px] h-[600px] rounded-3xl shadow-lg overflow-hidden transition-transform transform-gpu hover:scale-105 duration-500"
                        style={{ scrollSnapAlign: 'start' }}
                        onMouseEnter={() => setIsPaused(true)} // Kartın üzerine geldiğinde kaydırmayı durdur
                        onMouseLeave={() => setIsPaused(false)} // Karttan ayrıldığında kaydırmayı devam ettir
                        onClick={() => setSelectedMedia(card.media)} // Kart tıklandığında medya pop-up ile açılır
                    >
                        {/* Medya (video veya görsel) */}
                        {isVideo(card.media) ? (
                            <video
                                src={card.media}
                                className="w-full h-full object-cover"
                                autoPlay
                                muted
                                loop
                            />
                        ) : (
                            <img
                                src={card.media}
                                alt={`Reels Card ${index + 1}`}
                                className="w-full h-full object-cover"
                            />
                        )}

                        {/* Metin Alanı */}
                        <div className="absolute bottom-0 left-0 w-full p-4 bg-gradient-to-t from-black to-transparent text-white">
                            <div className="p-4">
                                <h3
                                    className="text-2xl text-center font-semibold mb-1"
                                    style={{ fontFamily: 'Radhanji, sans-serif' }} // Font ailesi Radhanji olarak ayarlandı
                                >
                                    {card.title}
                                </h3>
                                <p
                                    className="text-gray-300 text-center"
                                    style={{ fontFamily: 'Radhanji, sans-serif' }} // Font ailesi Radhanji olarak ayarlandı
                                >
                                    {card.subtitle}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pop-up Modal */}
            {selectedMedia && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={handleBackgroundClick} // Modal dışında tıklayınca kapansın
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                    <div className="relative w-[85vw] h-[70vh] rounded-lg overflow-hidden">
                        <button
                            className="absolute top-2 right-2 bg-gray-200 bg-opacity-30 p-2 rounded-full hover:bg-opacity-50 transition z-50"
                            onClick={closeModal} // X butonuna tıklayınca kapansın
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {isVideo(selectedMedia) ? (
                            <video
                                src={selectedMedia}
                                className="w-full h-full object-cover"
                                controls
                                autoPlay
                            />
                        ) : (
                            <img
                                src={selectedMedia}
                                alt="Selected Media"
                                className="w-full h-full object-cover"
                            />
                        )}
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default ReelsCardSliderSection;
