import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CTACardSection from '../../UI/sections/CTACardSection'; // CTACardSection bileşeni import edin

interface CTACardFormProps {
    onSave: (data: any) => void;
}

const CTACardForm: React.FC<CTACardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi burada kullanıyoruz
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState(''); // Yeni subtitle alanı
    const [buttonText, setButtonText] = useState('');
    const [buttonUrl, setButtonUrl] = useState('');
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modal state
    const [titleError, setTitleError] = useState<string | null>(null); // Başlık hatası state
    const [subtitleError, setSubtitleError] = useState<string | null>(null); // Alt başlık hatası state

    const MAX_TITLE_LENGTH = 40;
    const MAX_SUBTITLE_LENGTH = 30;

    const handleSave = async () => {
        if (title.length > MAX_TITLE_LENGTH) {
            setTitleError(`Başlık ${MAX_TITLE_LENGTH} karakterden fazla olamaz!`);
            return;
        }

        if (subtitle.length > MAX_SUBTITLE_LENGTH) {
            setSubtitleError(`Alt başlık ${MAX_SUBTITLE_LENGTH} karakterden fazla olamaz!`);
            return;
        }

        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'CTA Card',
            componentData: { title, subtitle, buttonText, buttonUrl }, // Subtitle verisini de ekledik
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length <= MAX_TITLE_LENGTH) {
            setTitleError(null); // Karakter sınırı aşılmadıysa hata mesajını temizle
        } else {
            setTitleError(`Başlık ${MAX_TITLE_LENGTH} karakterden fazla olamaz!`); // Karakter sınırı aşıldığında hata mesajı göster
        }
        setTitle(value.slice(0, MAX_TITLE_LENGTH)); // Karakter sınırı aşıldığında daha fazla yazmayı engelle
    };

    const handleSubtitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length <= MAX_SUBTITLE_LENGTH) {
            setSubtitleError(null); // Karakter sınırı aşılmadıysa hata mesajını temizle
        } else {
            setSubtitleError(`Alt başlık ${MAX_SUBTITLE_LENGTH} karakterden fazla olamaz!`); // Karakter sınırı aşıldığında hata mesajı göster
        }
        setSubtitle(value.slice(0, MAX_SUBTITLE_LENGTH)); // Karakter sınırı aşıldığında daha fazla yazmayı engelle
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-gray-700">Başlık (40 karakter sınırı)</label>
                <input
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    maxLength={MAX_TITLE_LENGTH} // Karakter sınırını belirliyoruz
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Başlık Alanı"
                />
                <div className="text-right text-sm text-gray-500">{title.length}/{MAX_TITLE_LENGTH} karakter</div>
                {titleError && <p className="text-red-700 text-sm">{titleError}</p>}
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Alt Başlık (30 karakter sınırı)</label>
                <input
                    type="text"
                    value={subtitle}
                    onChange={handleSubtitleChange}
                    maxLength={MAX_SUBTITLE_LENGTH} // Karakter sınırını belirliyoruz
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Alt Başlık Alanı"
                />
                <div className="text-right text-sm text-gray-500">{subtitle.length}/{MAX_SUBTITLE_LENGTH} karakter</div>
                {subtitleError && <p className="text-red-700 text-sm">{subtitleError}</p>}
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Buton Adı</label>
                <input
                    type="text"
                    value={buttonText}
                    onChange={(e) => setButtonText(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Buton Adı"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Buton Url</label>
                <input
                    type="text"
                    value={buttonUrl}
                    onChange={(e) => setButtonUrl(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Buton Url Alanı"
                />
            </div>
            <div className="flex justify-end space-x-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {/* Önizleme Modal */}
            {isPreviewOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50" onClick={() => setIsPreviewOpen(false)}>
                    <div
                        className="relative w-full max-w-[95%] h-auto bg-white rounded-lg p-6 overflow-auto"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-black-500 hover:text-black-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* CTACardSection bileşeni ile önizleme yapıyoruz */}
                        <div className="w-full">
                            <CTACardSection
                                title={title}
                                subtitle={subtitle} // Subtitle değerini CTACardSection'a ilet
                                buttonText={buttonText}
                                buttonUrl={buttonUrl}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CTACardForm;