import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // MediaSelector bileşenini import edin
import HeaderSection from '../../UI/sections/HeaderSection'; // HeaderSection bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin

interface HeaderFormProps {
    onSave: (data: any) => void;
}

const HeaderForm: React.FC<HeaderFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [title, setTitle] = useState('');
    const [logoMedia, setLogoMedia] = useState<S3File | null>(null);
    const [isLogoSelectorOpen, setIsLogoSelectorOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modal state

    const handleSave = async () => {
        if (!logoMedia) return; // Eğer logo seçilmediyse kaydetme işlemini durdur

        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Header',
            componentData: { title, logoMedia: logoMedia.url }, // Logo Media URL'si kaydediliyor
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
           
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleLogoSelect = (file: S3File) => {
        setLogoMedia(file);
        setIsLogoSelectorOpen(false);
    };

    const renderPreview = () => {
        if (!logoMedia) return null;

        const { key, url } = logoMedia;
        const fileType = key.split('.').pop(); // Dosya uzantısını alıyoruz

        if (!fileType) return <p className="text-sm text-[#091E42]">Desteklenmeyen dosya türü</p>;

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileType)) {
            // Görsel dosyası
            return (
                <div className="mt-2">
                    <p className="text-sm text-[#091E42]">Seçilen Logo: {key}</p>
                    <img src={url} alt={key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />
                    <p className="text-sm text-gray-500">İdeal Boyut: 140x140px</p> {/* Logo boyut bilgisi */}
                </div>
            );
        } else if (['mp4', 'webm', 'ogg'].includes(fileType)) {
            // Video dosyası
            return (
                <div className="mt-2">
                    <p className="text-sm text-[#091E42]">Seçilen Video: {key}</p>
                    <video controls src={url} className="max-w-full max-h-64 mx-auto rounded-lg" />
                </div>
            );
        } else {
            return <p className="text-sm text-[#091E42]">Desteklenmeyen dosya türü</p>;
        }
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-gray-700">Başlık</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                    placeholder="Başlık Girin"
                />
            </div>
            <div className="mb-4">
    <label className="block text-gray-700">Logo Medya</label>
    <button
        onClick={() => setIsLogoSelectorOpen(true)}
        className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
    >
        <span className="text-[#091E42]">{logoMedia ? logoMedia.key : 'Logo Seç'}</span>
        <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
    </button>

    {/* Önerilen logo ölçüsü bilgisi */}
    <p className="text-sm text-gray-500 mt-2">Önerilen Logo Ölçüsü: 140x140px</p>

    {renderPreview()}
</div>

            <MediaSelector
                isOpen={isLogoSelectorOpen}
                onSelect={handleLogoSelect}
                onClose={() => setIsLogoSelectorOpen(false)}
            />

            <div className="flex justify-end space-x-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {/* Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={() => setIsPreviewOpen(false)} // Modal dışına tıklayınca kapanacak
                >
                    <div
                        className="relative w-[1200px] h-[160px] bg-white rounded-lg p-6 overflow-auto"
                        onClick={(e) => setIsPreviewOpen(false)} // Modal içeriğine tıklayınca da kapanacak
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-black-500 hover:text-black-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* HeaderSection bileşeni ile önizleme yapıyoruz */}
                        <div className="w-full h-[160px] mx-auto">
                            <HeaderSection componentData={{ title, logoMedia: logoMedia?.url || '' }} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HeaderForm;
