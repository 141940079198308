import create from 'zustand';
import axios from 'axios';

interface MailStoreState {
    isLoading: boolean;
    errorMessage: string;
    sendVerificationEmail: (email: string) => Promise<void>;
    verifyCode: (email: string, code: string) => Promise<any>;
    clearError: () => void;
}

const useMailStore = create<MailStoreState>((set) => ({
    isLoading: false,
    errorMessage: '',

    sendVerificationEmail: async (email: string) => {
        set({ isLoading: true, errorMessage: '' });
        try {
            // BASE_URL backend sunucusunun doğru portunda çalıştığından emin olun
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';  // .env dosyasından alınıyor

            await axios.post(`${API_URL}/email/send-code`, { email });
        } catch (error) {
            set({ errorMessage: 'E-posta gönderilirken bir hata oluştu.' });
        } finally {
            set({ isLoading: false });
        }
    },

    verifyCode: async (email: string, code: string) => {
        set({ isLoading: true, errorMessage: '' });
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';  // .env dosyasından alınıyor
            const response = await axios.post(`${API_URL}/email/verify-code`, { email, code });
            return response;
        } catch (error) {
            set({ errorMessage: 'Doğrulama kodu hatalı.' });
        } finally {
            set({ isLoading: false });
        }
    },

    clearError: () => {
        set({ errorMessage: '' });
    },
}));

export default useMailStore;