import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import FullScreenCardSliderSection from '../../UI/sections/FullScreenCardSliderSection'; // FullScreenCardSliderSection bileşenini import edin
import MediaSelector from '../MediaSelector'; // MediaSelector bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin

interface FullScreenCardSliderFormProps {
    onSave: (data: any) => void;
}

const FullScreenCardSliderForm: React.FC<FullScreenCardSliderFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi burada kullanıyoruz
    const [cards, setCards] = useState([{ media: null as S3File | null, text: '', buttonText: '', buttonUrl: '', logoMedia: null as S3File | null }]);
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState<number | null>(null);
    const [isLogoSelectorOpen, setIsLogoSelectorOpen] = useState<number | null>(null);
    const [textError, setTextError] = useState<{ [key: number]: string }>({});
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modal state

    const handleAddCard = () => {
        setCards([...cards, { media: null, text: '', buttonText: '', buttonUrl: '', logoMedia: null }]);
    };

    const handleCardChange = (index: number, field: string, value: string) => {
        if (field === 'text' && value.length > 50) {
            setTextError({ ...textError, [index]: 'Yazı 50 karakterden fazla olamaz!' });
        } else {
            setTextError({ ...textError, [index]: '' });
        }

        const newCards = cards.map((card, i) =>
            i === index ? { ...card, [field]: value } : card
        );
        setCards(newCards);
    };

    const handleMediaSelect = (index: number, file: S3File) => {
        const newCards = cards.map((card, i) =>
            i === index ? { ...card, media: file } : card
        );
        setCards(newCards);
        setIsMediaSelectorOpen(null);
    };

    const handleLogoSelect = (index: number, file: S3File) => {
        const newCards = cards.map((card, i) =>
            i === index ? { ...card, logoMedia: file } : card
        );
        setCards(newCards);
        setIsLogoSelectorOpen(null);
    };

    const handleRemoveCard = (index: number) => {
        setCards(cards.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Full Screen Card Slider',
            componentData: {
                cards: cards.map(card => ({
                    ...card,
                    media: card.media?.url || '',
                    logoMedia: card.logoMedia?.url || ''
                })),
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
         
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    const renderMediaPreview = (media: S3File | null) => {
        if (!media) return null;

        const fileExtension = media.key.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return (
                <div className="text-center">
                    <img src={media.url} alt={media.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />
                    <p className="text-sm text-gray-500">İdeal Boyut: 1920x1080px (Görsel için)</p>
                </div>
            );
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <div className="text-center">
                    <video controls className="max-w-full max-h-64 object-contain mx-auto rounded-lg">
                        <source src={media.url} type={`video/${fileExtension}`} />
                        Your browser does not support the video tag.
                    </video>
                    <p className="text-sm text-gray-500">İdeal Boyut: 1920x1080px (Video için)</p>
                </div>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    return (
        <div>
            {cards.map((card, index) => (
                <div key={index} className="mb-4 p-4 border rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                        <h3 className="text-gray-700">Full Screen Card {index + 1}</h3>
                        <button
                            onClick={() => handleRemoveCard(index)}
                            className="px-3 py-1 bg-red-700 text-white rounded"
                        >
                            Sil
                        </button>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Medya</label>
                        <button
                            onClick={() => setIsMediaSelectorOpen(index)}
                            className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                        >
                            <span className="text-[#091E42]">{card.media ? card.media.key : 'Medya Seç'}</span>
                            <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>
                        {renderMediaPreview(card.media)}
                        <p className="text-sm text-gray-500 mt-1">İdeal Boyut: 1920x1080px</p> {/* Medya türüne göre ideal boyut bilgisi */} 
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Yazı Alanı</label>
                        <textarea
                            value={card.text}
                            onChange={(e) => handleCardChange(index, 'text', e.target.value)}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                            placeholder="Yazı Alanı (50 karakter sınırı)"
                            maxLength={50}
                        />
                        <div className="text-right text-sm text-gray-500">
                            {card.text.length}/50 karakter
                        </div>
                        {textError[index] && <p className="text-red-700 text-sm">{textError[index]}</p>}
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Buton Adı</label>
                        <input
                            type="text"
                            value={card.buttonText}
                            onChange={(e) => handleCardChange(index, 'buttonText', e.target.value)}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                            placeholder="Buton Adı"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Buton Url</label>
                        <input
                            type="text"
                            value={card.buttonUrl}
                            onChange={(e) => handleCardChange(index, 'buttonUrl', e.target.value)}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                            placeholder="Buton Url"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Logo Medya Alanı</label>
                        <button
                            onClick={() => setIsLogoSelectorOpen(index)}
                            className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                        >
                            <span className="text-[#091E42]">{card.logoMedia ? card.logoMedia.key : 'Logo Seç'}</span>
                            <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>
                        {card.logoMedia && renderMediaPreview(card.logoMedia)}
                        <p className="text-sm text-gray-500 mt-1">İdeal Boyut: 300x100px (Logo için)</p> {/* Logo medya için ideal boyut bilgisi */} 
                    </div>
                    <MediaSelector
                        isOpen={isMediaSelectorOpen === index}
                        onSelect={(file: S3File) => handleMediaSelect(index, file)}
                        onClose={() => setIsMediaSelectorOpen(null)}
                    />
                    <MediaSelector
                        isOpen={isLogoSelectorOpen === index}
                        onSelect={(file: S3File) => handleLogoSelect(index, file)}
                        onClose={() => setIsLogoSelectorOpen(null)}
                    />
                </div>
            ))}
            <button onClick={handleAddCard} className="mb-4 px-4 py-2 bg-gray-200 text-gray-700 rounded">
                Full Screen Card Ekle
            </button>

            {/* Önizle butonu */}
            <div className="flex justify-end space-x-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {/* Önizleme Modal */}
            {isPreviewOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50" onClick={() => setIsPreviewOpen(false)}>
                    <div
                        className="relative w-full max-w-[90%] h-full bg-white rounded-lg p-6 overflow-auto"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-black-500 hover:text-black-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* FullScreenCardSliderSection bileşeni ile önizleme yapıyoruz */}
                        <div className="w-full max-w-[1200px] h-[85vh] mx-auto">
                            <FullScreenCardSliderSection
                                componentData={{ cards: cards.map(card => ({
                                    ...card,
                                    media: card.media?.url || '',
                                    logoMedia: card.logoMedia?.url || ''
                                })) }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FullScreenCardSliderForm;
