import React from 'react';
import SearchComponentSection from '../UI/sections/SearchComponentSection'; // SearchComponentSection dosya yolunu kontrol edin.
import Navbar from '../components/Navbar';

const LaunchSearchPage: React.FC = () => {
    return (
        <div>
            <Navbar />
            {/* Navbar ile içerik arasına boşluk bırakmak için margin ekliyoruz */}
            <div className="mt-24">
                <SearchComponentSection componentData={null} />
            </div>
        </div>
    );
};

export default LaunchSearchPage;