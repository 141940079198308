import React from 'react';
import { motion } from 'framer-motion';

interface LargeTopTitleHeroCardSectionProps {
    componentData: {
        title: string;
        subtitle: string;
        buttonText: string;
        buttonUrl: string;
        media?: string; // Arka plan resmi için opsiyonel medya alanı
    };
}

const LargeTopTitleHeroCardSection: React.FC<LargeTopTitleHeroCardSectionProps> = ({ componentData }) => {
    const { title, subtitle, buttonText, buttonUrl, media } = componentData;

    return (
        <section className="relative w-[85vw] h-[70vh] flex flex-col justify-start text-center mx-auto rounded-3xl overflow-hidden pt-12">
            {/* Arka plandaki resim */}
            {media && (
                <div className="absolute inset-0">
                    <img src={media} alt="Background" className="w-full h-full object-cover" />
                    <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-transparent"></div>
                </div>
            )}

            {/* İçerik */}
            <div className="relative z-10">
                <h1
                    className="text-4xl font-bold text-white mb-2"
                    style={{ fontFamily: 'Radhanji, sans-serif' }} // Font ailesi Radhanji olarak ayarlandı
                >
                    {title}
                </h1>
                <h2
                    className="text-xl text-white mb-4"
                    style={{ fontFamily: 'Radhanji, sans-serif' }} // Font ailesi Radhanji olarak ayarlandı
                >
                    {subtitle}
                </h2>
                {buttonText && buttonUrl && (
                    <motion.a
                        href={buttonUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-transparent border-2 border-white text-white px-6 py-3 rounded-full text-lg font-bold transition-colors duration-300 mt-4" // Buton için üst marj eklendi
                        whileHover={{ scale: 1.05, backgroundColor: 'rgba(255, 255, 255, 0.2)' }} // Hover sırasında arka planın opaklığını artır
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.3 }}
                        style={{ fontFamily: 'Radhanji, sans-serif' }} // Font ailesi Radhanji olarak ayarlandı
                    >
                        {buttonText}
                    </motion.a>
                )}
            </div>
        </section>
    );
};

export default LargeTopTitleHeroCardSection;
