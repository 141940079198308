import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { DamiseUserResponse } from '../services/types';
import { useNavigate } from 'react-router-dom';

import login from '../assets/images/login.png';

const AdminLogin: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);  // loading durumu eklendi
    const navigate = useNavigate();

    const handleLogin = async () => {
        setLoading(true); // Giriş işlemi başladığında loading true yapılır
        try {
            const response = await axios.post<DamiseUserResponse>(`${process.env.REACT_APP_API_URL}/login`, { email, password });
            const token = response.data.token;

            Cookies.set('adminToken', token, { expires: 1, secure: true, sameSite: 'strict' });

            navigate('/admin-panel/launch-list');
        } catch (err) {
            setError('Giriş bilgileri hatalı veya yetkiniz yok');
        } finally {
            setLoading(false); // İşlem tamamlandığında loading false yapılır
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="min-h-screen flex">
            <div
                className="w-full md:w-1/2 h-screen bg-cover"
                style={{
                    backgroundImage: `url(${login})`,
                    backgroundPosition: 'center left',  // Sola kaydırmak için
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            />
            <div className="w-full md:w-1/2 flex flex-col justify-center items-center p-8 bg-white">
                <div className="max-w-md w-full">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-4">Hoşgeldiniz</h2>
                    <p className="text-gray-600 mb-6">Hesabınıza giriş yapın</p>
                    {error && <p className="text-red-600 mb-4">{error}</p>}
                    <div className="space-y-4">
                        <input
                            type="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <div className="flex justify-between items-center">
                            <a href="#" className="text-sm text-red-500">Şifremi Unuttum</a>
                            <div>
                                <input type="checkbox" id="remember" className="mr-2" />
                                <label htmlFor="remember" className="text-sm text-gray-600">Beni Hatırla</label>
                            </div>
                        </div>
                        <button
                            onClick={handleLogin}
                            className="w-full bg-red-600 text-white py-2 rounded-lg hover:bg-red-700 transition duration-300"
                            disabled={loading} // loading sırasında buton devre dışı bırakılır
                        >
                            {loading ? 'Giriş yapılıyor...' : 'Giriş Yap'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
