import React from 'react';
import { motion, useInView } from 'framer-motion';

interface FullTextCardSectionProps {
    componentData: {
        text: string;
    };
}

const FullTextCardSection: React.FC<FullTextCardSectionProps> = ({ componentData }) => {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: false }); // Her kaydırmada tetiklenecek

    return (
        <motion.div
            ref={ref}
            className="flex justify-center items-center mx-auto bg-white rounded-lg shadow-none p-4"
            style={{
                height: '45vh',  // Yükseklik 45vh
                width: '100%',   // Genişlik full yapıldı
                transformOrigin: 'center', // Kart merkezde kalacak
            }}
            initial={{ opacity: 0.3 }} // Başlangıçta çok az opak
            animate={isInView ? { opacity: 1 } : { opacity: 0.3 }} // Görünüyorsa tamamen opak hale gelir, değilse biraz saydam kalır
            transition={{ duration: 2, ease: 'easeInOut' }} // Yavaş ve yumuşak geçiş
        >
            <div className="max-w-[1000px] text-center p-4">
                <p
                    className="text-lg md:text-xl lg:text-2xl font-medium" // Mobilde küçük, orta ekranlarda daha büyük
                    style={{ fontFamily: 'Radhanji, sans-serif' }} // Font ailesi Radhanji olarak ayarlandı
                >
                    {componentData.text}
                </p>
            </div>
        </motion.div>
    );
};

export default FullTextCardSection;
