import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface AccordionSection {
    title: string;
    subtitle: string | string[];
}

interface AccordionRightCardSectionProps {
    componentData: {
        media: string;
        accordionSections: AccordionSection[];
    };
}

const AccordionRightCardSection: React.FC<AccordionRightCardSectionProps> = ({ componentData }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const toggleAccordion = (index: number) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const renderMedia = (media: string) => {
        if (!media) {
            return (
                <div className="flex justify-center items-center w-full h-full bg-gray-200">
                    <p className="text-gray-500 text-lg">Anonim Görsel</p>
                </div>
            );
        }

        const fileExtension = media.split('.').pop()?.toLowerCase();

        if (fileExtension === 'mp4') {
            return (
                <video className="object-cover w-full h-full" autoPlay muted loop playsInline>
                    <source src={media} type="video/mp4" />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else if (fileExtension === 'pdf') {
            return (
                <iframe src={media} className="object-cover w-full h-full" title="PDF Viewer"></iframe>
            );
        } else if (['png', 'jpg', 'jpeg', 'gif', 'webp'].includes(fileExtension || '')) {
            return (
                <img src={media} alt="Media" className="object-cover w-full h-full" />
            );
        } else if (['mp3', 'wav', 'ogg'].includes(fileExtension || '')) {
            return (
                <audio controls className="object-cover w-full h-full">
                    <source src={media} type={`audio/${fileExtension}`} />
                    Tarayıcınız bu ses dosyasını desteklemiyor.
                </audio>
            );
        } else if (['webm', 'ogg'].includes(fileExtension || '')) {
            return (
                <video className="object-cover w-full h-full" controls>
                    <source src={media} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else {
            return <p>Desteklenmeyen medya türü</p>;
        }
    };

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const containerVariants = {
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 80, damping: 20, duration: 2 } },
    };

    const titleVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 80, damping: 20, duration: 2 } },
    };

    return (
        <motion.div
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={containerVariants}
            ref={ref}
            className="flex flex-col lg:flex-row justify-center items-center bg-white-100 p-8" // Mobilde dikey, geniş ekranda yatay
            style={{ marginTop: 0, marginBottom: 0 }} // Margin top ve bottom sıfırlandı
        >
            {/* Accordion ve medya içeren kart */}
            <motion.div className="flex flex-col lg:flex-row h-[auto] lg:h-[70vh] w-full lg:w-[85vw] bg-white shadow-lg overflow-hidden rounded-3xl" variants={containerVariants}>
                {/* Sol taraf - Accordion */}
                <div className="w-full lg:w-1/2 bg-white-50 p-6 space-y-8" style={{ marginTop: 90, marginBottom: 20 }}>
                    {componentData.accordionSections.map((section: AccordionSection, index: number) => (
                        <motion.div key={index} className="pb-4" variants={titleVariants}>
                            <motion.div
                                className="flex justify-between items-center pl-16 max-w-[65%] mx-auto"
                                initial="hidden"
                                animate={inView ? 'visible' : 'hidden'}
                                variants={titleVariants}
                            >
                                <button
                                    className="text-2xl text-gray-700 font-bold text-left"
                                    style={{ fontFamily: 'Radhanji, sans-serif' }}
                                    onClick={() => toggleAccordion(index)}
                                >
                                    {section.title}
                                </button>
                                <button
                                    className="ml-auto text-xl p-1 transition-transform"
                                    onClick={() => toggleAccordion(index)}
                                >
                                    {activeIndex === index ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    )}
                                </button>
                            </motion.div>
                            <div className="w-[55%] border-b border-gray-300 mt-2 mb-4 mx-auto"></div>
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: activeIndex === index ? 'auto' : 0, opacity: activeIndex === index ? 1 : 0 }}
                                transition={{ type: 'spring', stiffness: 80, damping: 20, duration: 2 }}
                                className="overflow-hidden"
                            >
                                <div
                                    className="text-base text-gray-700 mt-2 pl-12 pt-2 max-w-[50%] mx-auto text-left break-words"
                                    style={{ fontFamily: 'Radhanji, sans-serif' }}
                                >
                                    {Array.isArray(section.subtitle) ? (
                                        section.subtitle.map((item: string, subIndex: number) => (
                                            <p key={subIndex} className="mb-2">{item}</p>
                                        ))
                                    ) : (
                                        <p>{section.subtitle || 'İçerik yok'}</p>
                                    )}
                                </div>
                            </motion.div>
                        </motion.div>
                    ))}
                </div>

                {/* Sağ taraf - Medya */}
                <div className="w-full lg:w-1/2 flex justify-center items-center bg-white">
                    {componentData.media ? (
                        renderMedia(componentData.media)
                    ) : (
                        <div className="flex justify-center items-center w-full h-full bg-gray-200">
                            <p className="text-gray-500 text-lg">Anonim Görsel</p>
                        </div>
                    )}
                </div>
            </motion.div>
        </motion.div>
    );
};

export default AccordionRightCardSection;
