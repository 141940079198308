import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import NavigationTabs from '../components/NavigationTabs';
import { useParams } from 'react-router-dom';
import api from '../services/apiService';
import useSeoStore, { SeoSettings } from '../store/seoStore';
import ImageSelector from '../components/MediaSelector';
import { S3File } from '../services/types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../components/Sidebar';

const SeoSettingsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const safeId = id || 'default-id';
    const { seoData, setSeoData, resetSeoData } = useSeoStore();
    const [isImageSelectorOpen, setIsImageSelectorOpen] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

    useEffect(() => {
        const fetchSeoSettings = async () => {
            resetSeoData();

            try {
                const response = await api.get<SeoSettings>(`${process.env.REACT_APP_API_URL}/seosettings/${safeId}`);
                if (response.data) {
                    setSeoData(response.data);
                }
            } catch (error) {
                console.error('SEO ayarları getirilirken bir hata oluştu:', error);
            }
        };

        fetchSeoSettings();
    }, [safeId, resetSeoData, setSeoData]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setSeoData({ [name]: value });
    };

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setSeoData({ [name]: checked });
    };

    const handleImageSelect = (file: S3File) => {
        setSeoData({ socialImage: file.url });
        setIsImageSelectorOpen(false);
    };

    const handleSave = async () => {
        try {
            await api.post(`${process.env.REACT_APP_API_URL}/seosettings`, {
                userId: safeId,
                ...seoData,
            });
            setMessage('SEO ayarları başarıyla kaydedildi.');
            setMessageType('success');
        } catch (error) {
            console.error('SEO ayarları kaydedilirken bir hata oluştu:', error);
            setMessage('SEO ayarları kaydedilemedi. Lütfen zorunlu alanları doldurunuz.');
            setMessageType('error');
        }
    };

    return (
        <div className="flex">
            {/* Sidebar */}
            <div className="fixed top-0 left-0 w-64 h-screen bg-gray-800 text-white z-50">
                <Sidebar />
            </div>

            {/* Main Content Area */}
            <div className="ml-64 w-full min-h-screen bg-white p-6">
                <ToastContainer position="bottom-right" autoClose={5000} />

                {/* Adjust margin below the header */}
                <div className="p-6 bg-white rounded-md w-full max-w-2xl mt-10">
                    <NavigationTabs activePage="seo" id={safeId} />
                    <div className="border-t border-gray-300 mt-6 mb-8"></div>

                    <div className="w-full">
                        <h2 className="text-[#091e42] text-4xl font-medium leading-loose">SEO Ayarları</h2>
                    </div>

                    <div className="w-full flex flex-col gap-6 mt-4">
                        <div className="flex flex-col gap-1">
                            <label className="text-[#243757] text-sm font-normal">Başlık<span className="text-red-500">*</span></label>
                            <input
                                type="text"
                                name="title"
                                value={seoData.title}
                                onChange={handleChange}
                                className="w-full h-[50px] px-[17px] py-[18px] bg-white rounded-[10px] border border-[#dfe2e6]"
                                placeholder="Başlık"
                            />
                        </div>

                        <div className="flex flex-col gap-1">
                            <label className="text-[#243757] text-sm font-normal">Lansman Url<span className="text-red-500">*</span></label>
                            <input
                                type="text"
                                name="url"
                                value={seoData.url}
                                onChange={handleChange}
                                className="w-full h-[50px] px-[17px] py-[18px] bg-white rounded-[10px] border border-[#dfe2e6]"
                                placeholder="Lansman Url"
                            />
                        </div>

                        <div className="flex flex-col gap-1">
                        <label className="text-[#243757] text-sm font-normal">Anahtar Kelime</label>

                            <input
                                type="text"
                                name="keywords"
                                value={seoData.keywords}
                                onChange={handleChange}
                                className="w-full h-[50px] px-[17px] py-[18px] bg-white rounded-[10px] border border-[#dfe2e6]"
                                placeholder="Anahtar Kelime"
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="text-[#243757] text-sm font-normal">Açıklama<span className="text-red-500">*</span></label>
                            <textarea
                                name="description"
                                value={seoData.description}
                                onChange={handleChange}
                                className="w-full h-[100px] px-[17px] py-[18px] bg-white rounded-[10px] border border-[#dfe2e6]"
                                placeholder="Açıklama"
                                rows={4}
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="text-[#243757] text-sm font-normal">Sayfa Sosyal Paylaşım Görseli<span className="text-red-500">*</span></label>
                            <div className="flex items-center gap-2">
                                <input
                                    type="text"
                                    name="socialImage"
                                    value={seoData.socialImage}
                                    onChange={handleChange}
                                    className="w-full h-[50px] px-[17px] py-[18px] bg-white rounded-[10px] border border-[#dfe2e6]"
                                    placeholder="Görsel Seç"
                                    readOnly
                                />
                                <button
                                    onClick={() => setIsImageSelectorOpen(true)}
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                >
                                    Seç
                                </button>
                            </div>
                            <small className="text-[#98a1b0] text-xs font-normal">*1200x630 boyutlarında bir görsel ekleyiniz.</small>
                        </div>

                        <div className="flex gap-8 items-center">
                            <div className="flex items-center gap-2">
                                <label className="text-[#2b3674] text-sm font-normal">Index Durumu</label>
                                <Switch
                                    checked={seoData.indexStatus}
                                    onChange={handleSwitchChange}
                                    name="indexStatus"
                                    color="primary"
                                />
                            </div>
                            <div className="flex items-center gap-2">
                                <label className="text-[#2b3674] text-sm font-normal">Takip Etme</label>
                                <Switch
                                    checked={seoData.followStatus}
                                    onChange={handleSwitchChange}
                                    name="followStatus"
                                    color="primary"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex justify-start">
                        <button
                            onClick={handleSave}
                            className="w-[166px] h-10 px-4 py-2.5 bg-[#970928] rounded-lg shadow border border-[#970928] text-white text-sm font-normal"
                        >
                            Kaydet
                        </button>
                    </div>

                    {message && (
                        <div className={`mt-4 p-4 rounded ${messageType === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                            {message}
                        </div>
                    )}

                    <ImageSelector
                        isOpen={isImageSelectorOpen}
                        onSelect={handleImageSelect}
                        onClose={() => setIsImageSelectorOpen(false)}
                    />
                </div>
            </div>
        </div>
    );
};

export default SeoSettingsPage;
