import React, { useState } from 'react';
import Clooned3DViewer from '../../UI/sections/Clooned3DViewer';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface Clooned3DViewerFormProps {
    onSave: (data: any) => void;
}

const Clooned3DViewerForm: React.FC<Clooned3DViewerFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>();
    const [features, setFeatures] = useState('lsc;fs;dt'); // Varsayılan özellikler
    const [oid, setOid] = useState('ce26bfc9b7674758879206d7013064bf'); // Varsayılan Object ID
    const [width, setWidth] = useState('750px'); // Varsayılan genişlik
    const [height, setHeight] = useState('655px'); // Varsayılan yükseklik
    const [text, setText] = useState(''); // Yeni metin alanı
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme durumu

    const handleSave = async () => {
        // Formu gönderirken özellikleri kaydet
        const componentData = {
            designId: id,
            componentName: 'Clooned 3D Viewer', // Component adı sabit
            componentData: { features, oid,text }, // Text alanı da eklendi
        };

        try {
            // Veriyi backend'e gönderin
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handlePreview = () => {
        setIsPreviewOpen(true); // Pop-up'ı aç
    };

    return (
        <div>
            {/* Features input */}
            <div className="mb-4">
                <label className="block text-gray-700">Features</label>
                <input
                    type="text"
                    value={features}
                    onChange={(e) => setFeatures(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Özellikleri girin (örn: lsc;fs;dt)"
                />
            </div>

            {/* Object ID input */}
            <div className="mb-4">
                <label className="block text-gray-700">Object ID (OID)</label>
                <input
                    type="text"
                    value={oid}
                    onChange={(e) => setOid(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="3D modelin Object ID'sini girin"
                />
            </div>

           

            {/* Text input */}
            <div className="mb-4">
                <label className="block text-gray-700">Text</label>
                <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Açıklama metnini girin"
                    rows={4} // Gerekirse satır sayısını ayarlayabilirsiniz
                />
            </div>

            {/* Önizleme ve Ekle butonları */}
            <div className="flex justify-end space-x-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {/* Pop-up Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={() => setIsPreviewOpen(false)} // Modal dışına tıklayınca kapanacak
                >
                    <div
                        className="relative w-full max-w-[100vw] h-[100vh] bg-white rounded-lg p-6 overflow-auto"
                        onClick={(e) => e.stopPropagation()} // Modal içeriğine tıklanınca kapanmayacak
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-black-500 hover:text-black-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* Clooned3DViewer bileşeni ile önizleme */}
                        <div className="w-full h-full mx-auto flex justify-center items-center">
                            <Clooned3DViewer componentData={{ features, oid, text }} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Clooned3DViewerForm;
