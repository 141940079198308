import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LogoSelector from '../MediaSelector'; // LogoSelector bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin
import TopTextCardSection from '../../UI/sections/TopTextCardSection'; // Önizleme için kullanacağımız bileşeni import edin

const defaultImage = 'https://via.placeholder.com/1920x1080?text=Anonim+Görsel'; // Anonim görsel URL'i

interface TopTextCardFormProps {
    onSave: (data: any) => void;
}

const TopTextCardForm: React.FC<TopTextCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [text, setText] = useState('');
    const [selectedMedia, setSelectedMedia] = useState<S3File | null>(null); // Seçilen medya dosyası
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false); // Medya seçici modalı açık mı kapalı mı?
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modalı açık mı?
    
    const MAX_TEXT_LENGTH = 250;

    const handleSave = async () => {
        if (!selectedMedia) return; // Eğer medya seçilmediyse kaydetme

        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Top Text Card',
            componentData: { text, media: selectedMedia.url }, // Seçilen medya dosyasının URL'si
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
           
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleMediaSelect = (file: S3File) => {
        setSelectedMedia(file);
        setIsMediaSelectorOpen(false);
    };

    const renderMediaPreview = () => {
        const mediaUrl = selectedMedia?.url || defaultImage; // Eğer medya seçilmediyse anonim görsel gösterilecek
        const fileExtension = mediaUrl.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return <img src={mediaUrl} alt={selectedMedia?.key || 'Anonim Görsel'} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />;
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video controls className="max-w-full max-h-64 mx-auto rounded-lg">
                    <source src={mediaUrl} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        // Anonim görsel haricinde dosya uzantısına göre medya görüntülenmezse bu alan boş kalır
        return null;
    };

    const closeModal = () => {
        setIsPreviewOpen(false); // Modalın üzerine basıldığında kapanacak
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal">Yazı (En fazla {MAX_TEXT_LENGTH} karakter)</label>
                <textarea
                    value={text}
                    onChange={(e) => {
                        if (e.target.value.length <= MAX_TEXT_LENGTH) setText(e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-lg"
                    placeholder="Yazı Alanı"
                />
                <p className="text-sm text-gray-500 mt-1">{text.length}/{MAX_TEXT_LENGTH} karakter</p>
            </div>

            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal">Medya Önerilen görsel ölçüleri: 1632x658px (en iyi sonuç için).</label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsMediaSelectorOpen(true)}
                >
                    <span className="text-[#091E42] text-sm">{selectedMedia ? selectedMedia.key : 'Seç'}</span>
                    <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>
                <div className="mt-4">
                    {renderMediaPreview()}
                </div>
            </div>

            <LogoSelector
                isOpen={isMediaSelectorOpen}
                onSelect={handleMediaSelect}
                onClose={() => setIsMediaSelectorOpen(false)}
            />

            {/* Ön İzleme ve Ekle Butonları */} 
            <div className="flex justify-end space-x-4 mb-4">
                <button
                    onClick={() => setIsPreviewOpen(true)} 
                    className="px-4 py-2.5 bg-red-700 rounded-lg shadow border  text-white text-base font-normal"
                >
                    Ön İzle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Ekle
                </button>
            </div>

            {/* Önizleme Popup Modal */} 
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                    onClick={closeModal} // Modalın herhangi bir yerine tıklayınca kapanacak
                >
                    <div className="bg-white w-full h-full max-w-full max-h-full relative" onClick={(e) => e.stopPropagation()}>
                        <button
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                            onClick={closeModal} // X butonu ile kapatma
                        >
                            X
                        </button>
                        {/* Burada tam ekran olacak şekilde içerik gösteriliyor */} 
                        <TopTextCardSection componentData={{ text, media: selectedMedia?.url || defaultImage }} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TopTextCardForm;
