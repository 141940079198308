import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface SpacingFormProps {
    onSave: (data: any) => void;
}

const SpacingForm: React.FC<SpacingFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [spacingValue, setSpacingValue] = useState(0);

    const handleSave = async () => {
        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Spacing',
            componentData: { spacingValue },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
          
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-gray-700">Boşluk Değeri (px)</label>
                <input
                    type="number"
                    value={spacingValue}
                    onChange={(e) => setSpacingValue(parseInt(e.target.value))}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Boşluk Miktarı Girin"
                />
            </div>
            <div className="flex justify-end">
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Boşluk Ekle
                </button>
            </div>
        </div>
    );
};

export default SpacingForm;