import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface TwinTopTitleHeroCardSectionProps {
  componentData: {
    rightCard: {
      media: string;
      title: string;
      subtitle: string;
      buttonText: string;
      buttonUrl: string;
    };
    leftCard: {
      media: string;
      title: string;
      subtitle: string;
      buttonText: string;
      buttonUrl: string;
    };
  };
}

const TwinTopTitleHeroCardSection: React.FC<TwinTopTitleHeroCardSectionProps> = ({ componentData }) => {
  const { rightCard, leftCard } = componentData;

  // Intersection Observer for animating the cards when they come into view
  const leftRef = useRef<HTMLDivElement | null>(null);
  const rightRef = useRef<HTMLDivElement | null>(null);
  const [leftInView, setLeftInView] = useState(false);
  const [rightInView, setRightInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === leftRef.current) {
            setLeftInView(entry.isIntersecting);
          }
          if (entry.target === rightRef.current) {
            setRightInView(entry.isIntersecting);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (leftRef.current) observer.observe(leftRef.current);
    if (rightRef.current) observer.observe(rightRef.current);

    return () => {
      if (leftRef.current) observer.unobserve(leftRef.current);
      if (rightRef.current) observer.unobserve(rightRef.current);
    };
  }, []);

  const renderMedia = (mediaUrl: string) => {
    if (mediaUrl.endsWith('.mp4') || mediaUrl.endsWith('.webm') || mediaUrl.endsWith('.ogg')) {
      return (
        <video
          className="w-full h-full object-cover rounded-3xl" // 3xl yuvarlatılmış köşe
          autoPlay
          muted
          loop
        >
          <source src={mediaUrl} type="video/mp4" />
        </video>
      );
    } else {
      return <img src={mediaUrl} alt="Card Media" className="w-full h-full object-cover rounded-3xl" />; // 3xl yuvarlatılmış köşe
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-between p-5 bg-white mb-5 w-[85vw] h-[70vh] mx-auto mt-16 space-y-5 md:space-y-0 md:space-x-7 max-w-none"> 
      {/* Genişlik %85vw ve yükseklik %70vh, mobilde kartları alt alta koyduk */}
      
      {/* Left Card */}
      <div
        ref={leftRef}
        className={`w-full md:w-[calc(50%-14px)] h-full bg-[#DFE2E6] overflow-hidden relative transition-all duration-1000 ease-in-out transform rounded-3xl ${leftInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'
          } hover:shadow-lg group`} 
      >
        {/* Content */}
        <div className="absolute bottom-0 left-0 w-full p-5 h-[25%] bg-gradient-to-t from-black to-transparent opacity-80 z-10 rounded-b-none"> 
          <div className="absolute bottom-10 left-5 text-white font-sans z-20 space-y-2">
            <h3 className="text-[30px] font-bold mb-2 tracking-wider">
              {leftCard.title}
            </h3>
            <p className="text-[16px] font-semibold mb-5 line-clamp-2" style={{ marginBottom: '20px', maxWidth: '300px' }}>
              {leftCard.subtitle}
            </p>
          </div>
        </div>
        <motion.div
          className="absolute bottom-12 right-10 hidden group-hover:block z-30"
          style={{ width: '170px', height: '55px' }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.3 }}
        >
          <a
            href={leftCard.buttonUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="px-6 py-3 border-2 border-white rounded-full text-white text-center font-bold text-m transition-colors duration-300 block"
            style={{ background: "rgba(255, 255, 255, 0)" }}
          >
            <span className="relative z-10">{leftCard.buttonText}</span>
            <div className="absolute inset-0 bg-white opacity-0 hover:opacity-20 transition-opacity duration-300 rounded-full z-0"></div>
          </a>
        </motion.div>
        {renderMedia(leftCard.media)}
      </div>

      {/* Right Card */}
      <div
        ref={rightRef}
        className={`w-full md:w-[calc(50%-14px)] h-full bg-[#DFE2E6] overflow-hidden relative transition-all duration-1000 ease-in-out transform rounded-3xl ${rightInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'
          } hover:shadow-lg group`}
      >
        {/* Content */}
        <div className="absolute bottom-0 left-0 w-full p-5 h-[25%] bg-gradient-to-t from-black to-transparent opacity-80 z-10 rounded-b-none">
          <div className="absolute bottom-10 left-5 text-white font-sans z-20 space-y-2">
            <h3 className="text-[30px] font-bold mb-2 tracking-wider">
              {rightCard.title}
            </h3>
            <p className="text-[16px] font-semibold mb-5 line-clamp-2" style={{ marginBottom: '20px', maxWidth: '300px' }}>
              {rightCard.subtitle}
            </p>
          </div>
        </div>
        <motion.div
          className="absolute bottom-12 right-10 hidden group-hover:block z-30"
          style={{ width: '170px', height: '55px' }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.3 }}
        >
          <a
            href={rightCard.buttonUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="px-6 py-3 border-2 border-white rounded-full text-white text-center font-bold text-m transition-colors duration-300 block"
            style={{ background: "rgba(255, 255, 255, 0)" }}
          >
            <span className="relative z-10">{rightCard.buttonText}</span>
            <div className="absolute inset-0 bg-white opacity-0 hover:opacity-20 transition-opacity duration-300 rounded-full z-0"></div>
          </a>
        </motion.div>
        {renderMedia(rightCard.media)}
      </div>
    </div>
  );
};

export default TwinTopTitleHeroCardSection;
