import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector';
import { S3File } from '../../services/types';
import CompanyNameCardSliderSection from '../../UI/sections/CompanyNameSection';
import { motion } from 'framer-motion';

interface CompanyNameCard {
    media: S3File | null;
    title: string;
    subtitle: string;
    exploreUrl: string;
    buttonText: string; // Yeni alan ekleniyor
}

interface CompanyNameCardFormProps {
    onSave: (data: any) => void;
}

const CompanyNameCardForm: React.FC<CompanyNameCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>();
    const [companyNameCards, setCompanyNameCards] = useState<CompanyNameCard[]>([{ media: null, title: '', subtitle: '', exploreUrl: '', buttonText: '' }]);
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false);
    const [currentCardIndex, setCurrentCardIndex] = useState<number | null>(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Pop-up kontrolü için state

    const anonymousImage = 'https://via.placeholder.com/400x700?text=No+Media+Selected';
    const MAX_TITLE_LENGTH = 20;
    const MAX_SUBTITLE_LENGTH = 25;

    const handleAddCompanyNameCard = () => {
        setCompanyNameCards([...companyNameCards, { media: null, title: '', subtitle: '', exploreUrl: '', buttonText: '' }]);
    };

    const handleCompanyNameChange = (index: number, field: keyof CompanyNameCard, value: string | S3File | null) => {
        const newCompanyNameCards = companyNameCards.map((card, i) =>
            i === index ? { ...card, [field]: value } : card
        );
        setCompanyNameCards(newCompanyNameCards);
    };

    const handleRemoveCompanyNameCard = (index: number) => {
        setCompanyNameCards(companyNameCards.filter((_, i) => i !== index));
    };

    const handleMediaSelect = (file: S3File) => {
        if (currentCardIndex !== null) {
            handleCompanyNameChange(currentCardIndex, 'media', file);
            setIsMediaSelectorOpen(false);
        }
    };

    const handleSave = async () => {
        if (companyNameCards.length >= 4) {
            const componentData = {
                designId: id,
                componentName: 'CompanyNameComponent',
                componentData: {
                    companyNameCards: companyNameCards.map(card => ({
                        ...card,
                        media: card.media ? card.media.url : '',
                        exploreUrl: card.exploreUrl ? card.exploreUrl : '',
                        buttonText: card.buttonText || 'Keşfet', // Varsayılan buton metni
                    })),
                },
            };

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
                onSave(response.data);
                window.location.reload();
            } catch (error) {
                console.error('Veri kaydedilirken bir hata oluştu:', error);
            }
        } else {
            alert('En az dört tane CompanyName Card eklemelisiniz.');
        }
    };

    return (
        <div>
            {companyNameCards.map((card, index) => (
                <div key={index} className="mb-4 p-4 border rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                        <h3 className="text-gray-700">CompanyName Card {index + 1}</h3>
                        <button
                            onClick={() => handleRemoveCompanyNameCard(index)}
                            className="px-3 py-1 bg-red-500 text-white rounded"
                        >
                            Sil
                        </button>
                    </div>
                    <div className="mb-2">
                        <label className="block text-[#243757] text-sm font-normal">Medya Alanı (Önerilen ölçüler: 1920x1080 piksel)</label>
                        <div
                            className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                            onClick={() => {
                                setCurrentCardIndex(index);
                                setIsMediaSelectorOpen(true);
                            }}
                        >
                            <span className="text-[#091E42] text-sm">{card.media ? card.media.key : 'Seç'}</span>
                            <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="mb-2">
                        <label className="block text-[#243757] text-sm font-normal">Başlık (En fazla {MAX_TITLE_LENGTH} karakter)</label>
                        <input
                            type="text"
                            value={card.title}
                            onChange={(e) => {
                                if (e.target.value.length <= MAX_TITLE_LENGTH) {
                                    handleCompanyNameChange(index, 'title', e.target.value);
                                }
                            }}
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Başlık Alanı"
                        />
                        <p className="text-sm text-gray-500 mt-1">{card.title.length}/{MAX_TITLE_LENGTH} karakter</p>
                    </div>
                    <div className="mb-2">
                        <label className="block text-[#243757] text-sm font-normal">Alt Başlık (En fazla {MAX_SUBTITLE_LENGTH} karakter)</label>
                        <input
                            type="text"
                            value={card.subtitle}
                            onChange={(e) => {
                                if (e.target.value.length <= MAX_SUBTITLE_LENGTH) {
                                    handleCompanyNameChange(index, 'subtitle', e.target.value);
                                }
                            }}
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Alt Başlık Alanı"
                        />
                        <p className="text-sm text-gray-500 mt-1">{card.subtitle.length}/{MAX_SUBTITLE_LENGTH} karakter</p>
                    </div>
                    <div className="mb-2">
                        <label className="block text-[#243757] text-sm font-normal">Keşfet URL</label>
                        <input
                            type="text"
                            value={card.exploreUrl}
                            onChange={(e) => handleCompanyNameChange(index, 'exploreUrl', e.target.value)}
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Keşfet URL'si"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-[#243757] text-sm font-normal">Buton Metni</label>
                        <input
                            type="text"
                            value={card.buttonText}
                            onChange={(e) => handleCompanyNameChange(index, 'buttonText', e.target.value)}
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Buton Metni"
                        />
                    </div>
                </div>
            ))}

            <button onClick={handleAddCompanyNameCard} className="mb-4 px-4 py-2 bg-gray-200 text-gray-700 rounded">
                CompanyName Card Ekle
            </button>

            <div className="flex justify-end space-x-4">
                <button onClick={() => setIsPreviewOpen(true)} className="px-4 py-2 bg-red-700 text-white rounded">
                    Ön İzleme
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Ekle
                </button>
            </div>

            {/* Pop-up Modal (Preview) */}
            {isPreviewOpen && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                    <div className="relative w-auto max-w-[95vw] max-h-[95vh] bg-white rounded-lg overflow-hidden">
                        <button
                            className="absolute top-2 right-2 bg-gray-200 p-2 rounded-full hover:bg-opacity-50 transition z-50"
                            onClick={() => setIsPreviewOpen(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <CompanyNameCardSliderSection
                            componentData={{
                                companyNameCards: companyNameCards.map(card => ({
                                    media: card.media ? card.media.url : anonymousImage,
                                    title: card.title,
                                    subtitle: card.subtitle,
                                    exploreUrl: card.exploreUrl,
                                    buttonText: card.buttonText || 'Keşfet',
                                })),
                            }}
                        />
                    </div>
                </motion.div>
            )}

            <MediaSelector
                isOpen={isMediaSelectorOpen}
                onSelect={handleMediaSelect}
                onClose={() => setIsMediaSelectorOpen(false)}
            />
        </div>
    );
};

export default CompanyNameCardForm;
