import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/apiService'; // Axios instance'ı import edin
import { LaunchData } from '../store/launchStore';
import { FcOk } from 'react-icons/fc';
import CancelIcon from '@mui/icons-material/Cancel';
import { pink } from '@mui/material/colors';
import { FaRegEdit } from 'react-icons/fa';
import { MdOutlinePalette } from 'react-icons/md';
import EditLaunchPopup from '../components/EditLaunchPopup';
import Sidebar from '../components/Sidebar'; // Sidebar bileşeni
import Header from '../components/Header'; // Header bileşeni

const AdminHomePage: React.FC = () => {
    const [launches, setLaunches] = useState<LaunchData[]>([]);
    const [editPopupOpen, setEditPopupOpen] = useState(false);
    const [currentLaunchId, setCurrentLaunchId] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLaunches = async () => {
            try {
                const response = await api.get<LaunchData[]>(`${process.env.REACT_APP_API_URL}/launch`);
                const filteredLaunches = response.data.filter((launch) => launch.showOnHomepage);
                setLaunches(filteredLaunches);
            } catch (error) {
                console.error('Anasayfa lansmanları getirilirken bir hata oluştu:', error);
            }
        };

        fetchLaunches();
    }, []);

    const handleEdit = (id: string) => {
        setCurrentLaunchId(id);
        setEditPopupOpen(true);
    };

    const handleSave = (updatedLaunch: LaunchData) => {
        setLaunches((prevLaunches) =>
            prevLaunches.map((launch) =>
                launch._id === updatedLaunch._id ? updatedLaunch : launch
            )
        );
        setEditPopupOpen(false);
    };

    const handleDelete = (deletedLaunchId: string) => {
        setLaunches((prevLaunches) =>
            prevLaunches.filter((launch) => launch._id !== deletedLaunchId)
        );
    };

    const handleDesign = (id: string) => {
        navigate(`/admin-panel/design/${id}`);
    };

    return (
        <div className="flex">
            {/* Sidebar */}
            <div className="fixed top-0 left-0 w-64 h-full bg-gray-800 text-white z-50">
                <Sidebar /> {/* Sidebar component goes here */}
            </div>

            {/* Main Content */}
            <div className="ml-64 w-full">
                {/* Top Navbar */}
                <div className="fixed top-0 w-full h-16 bg-white shadow z-40">
                    <Header /> {/* Header component goes here */}
                </div>

                {/* Main Content Below the Navbar */}
                <div className="p-8 mt-16"> {/* İçeriği Navbar'ın altına yerleştirmek için mt-16 eklendi */}
                <h2 className="text-4xl font-semibold mt-10 mb-10">Ana Sayfada Gösterilen Lansman</h2>  {/* Daha fazla boşluk için mb-6 */}


                    {/* Lansman Listesi */}
                    <div className="overflow-x-auto">
                        <table className="min-w-full text-left table-auto border-collapse">
                            <thead>
                                <tr className="bg-white-50 shadow-sm">
                                    <th className="px-4 py-2 text-center">#</th>
                                   
                                    <th className="px-4 py-2 text-left">Lansman Adı</th>
                                    <th className="px-4 py-2 text-left">Firma Adı</th>
                                    <th className="px-4 py-2 text-center">Dil</th>
                                    <th className="px-4 py-2 text-center">Sıra No</th>
                                    <th className="px-4 py-2 text-center">Grup No</th>
                                    <th className="px-4 py-2 text-center">Yayına Giriş Tarihi</th>
                                    <th className="px-4 py-2 text-center">Yayın Bitiş Tarihi</th>
                                    <th className="px-4 py-2 text-center">Durumu</th>
                                    <th className="px-4 py-2 text-center"></th> {/* Düzenle sütunu */}
                                    <th className="px-4 py-2 text-center"></th> {/* Tasarla sütunu */}
                                </tr>
                            </thead>
                            <tbody>
                                {launches.map((launch, index) => (
                                    <tr key={launch._id} className="border-b bg-gray-50">
                                        <td className="px-4 py-2 text-center">{index + 1}</td>
                                        
                                        <td className="px-4 py-2 text-left">{launch.launchName}</td>
                                        <td className="px-4 py-2 text-left">{launch.companyName}</td>
                                        <td className="px-4 py-2 text-center">{launch.language}</td>
                                        <td className="px-4 py-2 text-center">{launch.orderNumber}</td>
                                        <td className="px-4 py-2 text-center">{launch.groupNumber}</td>
                                        <td className="px-4 py-2 text-center">{new Date(launch.startDate).toLocaleDateString()}</td>
                                        <td className="px-4 py-2 text-center">{new Date(launch.endDate).toLocaleDateString()}</td>
                                        <td className="px-8 py-2 text-center">
                                            {launch.isActive ? (
                                                <span className="inline-flex items-center">
                                                    <FcOk style={{ fontSize: '1.5rem', marginRight: '0.25rem' }} /> Aktif
                                                </span>
                                            ) : (
                                                <span className="inline-flex items-center">
                                                    <CancelIcon sx={{ color: pink[500], fontSize: '1.5rem', marginRight: '0.25rem' }} /> Pasif
                                                </span>
                                            )}
                                        </td>
                                        {/* Düzenle Butonu */}
                                        <td className="px-4 py-2 text-center cursor-pointer" onClick={() => handleEdit(launch._id)}>
                                            <div className="flex items-center space-x-2 text-red-500">
                                                <FaRegEdit style={{ fontSize: '1.5rem' }} />
                                                <span>Düzenle</span>
                                            </div>
                                        </td>
                                        {/* Tasarla Butonu */}
                                        <td className="px-4 py-2 text-center cursor-pointer" onClick={() => handleDesign(launch._id)}>
                                            <div className="flex items-center space-x-2 text-red-500">
                                                <MdOutlinePalette style={{ fontSize: '1.5rem' }} />
                                                <span>Tasarla</span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {currentLaunchId && (
                        <EditLaunchPopup
                            launchId={currentLaunchId}
                            isOpen={editPopupOpen}
                            onClose={() => setEditPopupOpen(false)}
                            onSave={handleSave}
                            onDelete={handleDelete}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminHomePage;
