import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useDeployDesignStore from "../store/useLaunchForComponents";
import LaunchPage from "./LaunchPage";
import LaunchAnnouncement from "../components/LaunchAnnouncement"; // Geçiş mesajını ekleyelim

const LaunchLayout: React.FC = () => {
    const { launchUrl } = useParams<{ launchUrl: string }>();
    const [filteredComponents, setFilteredComponents] = useState<any>([]);
    const { fetchLaunch, launch, components } = useDeployDesignStore(
        (state: any) => state
    );
    const navigate = useNavigate();

    const fetchDatas = async () => {
        try {
            // Lansman verilerini çekiyoruz
            await fetchLaunch(launchUrl);
        } catch (error) {
            console.error("Lansman bilgileri alınırken hata oluştu:", error);
            navigate("/access-denied"); // Hata durumunda yetki yok sayfasına yönlendirme
        }
    };

    useEffect(() => {
        if (launchUrl) {
            fetchDatas();
        }
    }, [launchUrl, navigate]);

    useEffect(() => {
        if (launch && components) {
            const today = new Date();
            const startDate = new Date(launch.startDate);
            const endDate = new Date(launch.endDate);

            let sortedComponents = [];

            if (today < startDate || today > endDate) {
                // Tarih aralığında değilse sadece showInFragman true olan component'ları gösteriyoruz
                sortedComponents = components
                    .filter((component: any) => component.showInFragman)
                    .sort((a: any, b: any) => a.row - b.row); // row'a göre sıralama
            } else {
                // Tarih aralığındaysa tüm component'ları sırayla göster
                sortedComponents = components.sort((a: any, b: any) => a.row - b.row);
            }

            setFilteredComponents(sortedComponents);
        }
    }, [launch, components]);

    // Bugün tarihi kontrolü
    const today = new Date();
    const isPastOrFuture = launch && (today < new Date(launch.startDate) || today > new Date(launch.endDate));

    return (
        <>
            {launch ? (
                <>
                    {/* Lansman tarihi bugünün tarihi değilse geçiş mesajını göster */}
                    {isPastOrFuture && (
                        <LaunchAnnouncement startDate={launch.startDate} endDate={launch.endDate} />
                    )}

                    {/* Fragman gösterilmesi gereken component'ları listele */}
                    {filteredComponents.length > 0 ? (
                        <LaunchPage components={filteredComponents} />
                    ) : (
                        <div>Loading...</div>
                    )}
                </>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default LaunchLayout;