import React from 'react';
import Modal from 'react-modal';

interface MediaViewerProps {
    isOpen: boolean;
    onClose: () => void;
    fileUrl: string;
}

const MediaViewer: React.FC<MediaViewerProps> = ({ isOpen, onClose, fileUrl }) => {
    const fileExtension = fileUrl.split('.').pop()?.toLowerCase();

    const renderContent = () => {
        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'avif':   // AVIF formatını ekledik
            case 'webp':   // WebP formatını ekledik
                return (
                    <img
                        src={fileUrl}
                        alt="Medya Görüntüleme"
                        className="max-w-full max-h-[80vh] object-contain mx-auto"
                    />
                );
            case 'mp4':
            case 'webm':
            case 'ogg':
                return (
                    <video controls className="max-w-full max-h-[80vh] object-contain mx-auto">
                        <source src={fileUrl} type={`video/${fileExtension}`} />
                        Tarayıcınız bu videoyu oynatmayı desteklemiyor.
                    </video>
                );
            case 'pdf':
                return (
                    <iframe
                        src={fileUrl}
                        title="PDF Görüntüleme"
                        className="w-full h-[80vh] mx-auto"
                    ></iframe>
                );
            default:
                return (
                    <div className="text-center">
                        <p>Bu dosya tipi desteklenmiyor. Aşağıdaki butona tıklayarak dosyayı indirebilirsiniz.</p>
                        <a href={fileUrl} download className="text-blue-600 hover:underline">Dosyayı İndir</a>
                    </div>
                );
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Medya Görüntüleme"
            className="bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full mx-auto"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-[1000] flex justify-center items-center" 
        >
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-[#192055]">Medya Görüntüleme</h3>
                <button onClick={onClose} className="text-gray-500 text-xl">&times;</button>
            </div>
            <div className="flex justify-center items-center">
                {renderContent()} {/* İçeriği dinamik olarak render ediyoruz */}
            </div>
        </Modal>
    );
};

export default MediaViewer;
