import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { FaCopy } from 'react-icons/fa';
import ComponentModal from '../components/AddComponentPopup';
import NavigationTabs from '../components/NavigationTabs';
import DesignElementComponent from '../components/DesignElementComponent';
import useDesignStore from '../store/designStore';
import api from '../services/apiService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../components/Sidebar';
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd';

interface LaunchData {
    launchName: string;
}

interface ComponentData {
    _id: string;
    componentName: string;
    componentData: any;
    showInFragman: boolean;
    row: number;
}

const DesignPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const safeId = id || 'default-id';
    const navigate = useNavigate();

    const [launchName, setLaunchName] = useState<string>('');
    const [previewAccess, setPreviewAccess] = useState<boolean>(false);
    const {
        activePage,
        designElements,
        isModalOpen,
        setActivePage,
        setDesignElements,
        setPreview,
        setIsModalOpen,
        addElement,
        updateElement,
        deleteElement,
        moveElementUp,
        moveElementDown,
    } = useDesignStore();

    const fetchLaunchName = async () => {
        try {
            const response = await api.get<LaunchData>(`${process.env.REACT_APP_API_URL}/launch/${safeId}`);
            if (response.status === 200) {
                const launchData = response.data;
                setLaunchName(launchData.launchName);
            } else {
                console.error('Failed to fetch launch data');
            }
        } catch (error) {
            console.error('Error fetching launch data:', error);
        }
    };

    const fetchComponents = async () => {
        try {
            const response = await api.get<ComponentData[]>(`${process.env.REACT_APP_API_URL}/components/${safeId}`);
            if (response.status === 200) {
                const components = response.data;
                const sortedComponents = components.sort((a, b) => a.row - b.row);
                const updatedComponents = sortedComponents.map((component) => ({
                    id: component._id,
                    type: component.componentName,
                    content: component.componentData,
                    showInFragman: component.showInFragman,
                    order: component.row,
                }));
                setDesignElements(updatedComponents);
            } else {
                console.error('Failed to fetch components');
            }
        } catch (error) {
            console.error('Error fetching components:', error);
        }
    };

    useEffect(() => {
        fetchLaunchName();
        fetchComponents();
    }, [safeId]);

    useEffect(() => {
        // LocalStorage'dan previewAccess durumunu kontrol ediyoruz
        const savedPreviewAccess = localStorage.getItem('previewAccess');
        if (savedPreviewAccess !== null) {
            setPreviewAccess(JSON.parse(savedPreviewAccess));
        } else {
            setPreviewAccess(false); // Varsayılan olarak kapalı
        }
        setPreview(false);
    }, []);

    const handleAddElement = () => {
        setIsModalOpen(true);
    };

    const handleSaveComponent = (component: string) => {
        const maxOrder = designElements.length > 0
            ? Math.max(...designElements.map(el => el.order))
            : 0;

        const newElement = {
            id: `${Date.now()}`,
            type: component,
            content: '',
            showInFragman: true,
            order: maxOrder + 1,
        };

        addElement(newElement);
        setIsModalOpen(false);
        fetchComponents();
        toast.success(`"${component}" kartı başarıyla eklendi!`);
    };

    const handleToggleFragman = async (id: string) => {
        const element = designElements.find((el) => el.id === id);
        if (!element) return;

        updateElement(id, { showInFragman: !element.showInFragman });

        const statusMessage = element.showInFragman
            ? 'Bileşen fragmandan kaldırıldı!'
            : 'Bileşen fragmanda gösteriliyor!';
        toast.info(statusMessage);

        try {
            const response = await api.put(`${process.env.REACT_APP_API_URL}/component/${id}`, {
                showInFragman: !element.showInFragman,
            });
            if (response.status !== 200) {
                console.error('Failed to update component');
            }
        } catch (error) {
            console.error('Error updating component:', error);
        }
    };

    const handleDeleteElement = async (id: string) => {
        try {
            const response = await api.delete(`${process.env.REACT_APP_API_URL}/component/${id}`);
            if (response.status === 200) {
                deleteElement(id);
                handleSaveOrder(designElements); // Doğru argümanı geçiriyoruz
                toast.error(`Bileşen silindi!`);
            } else {
                console.error('Failed to delete component');
            }
        } catch (error) {
            console.error('Error deleting component:', error);
        }
    };

    const handleSaveOrder = async (updatedItems: any[]) => {
        try {
            const updatedComponents = updatedItems.map((element) => ({
                id: element.id,
                row: element.order, // Sıralama (row) verisini güncelliyoruz
            }));

            const response = await api.put(`${process.env.REACT_APP_API_URL}/components/update-rows`, {
                updatedComponents,
            });

            if (response.status === 200) {
                toast.success("Sıralama başarıyla kaydedildi!");
            } else {
                toast.error("Sıralama kaydedilemedi!");
                console.error('Failed to update component rows');
            }
        } catch (error) {
            toast.error("Sıralama kaydedilirken bir hata oluştu!");
            console.error('Error updating component rows:', error);
        }
    };

    // Sürükle bırak ile sıralama işlemi
    const handleDragEnd = (result: any) => {
        if (!result.destination) return; // Eğer sürüklenen eleman bırakılmazsa

        const items = Array.from(designElements); // Mevcut sıralama elemanlarını kopyalıyoruz
        const [reorderedItem] = items.splice(result.source.index, 1); // Sürüklenen elemanı orijinal yerinden çıkarıyoruz
        items.splice(result.destination.index, 0, reorderedItem); // Yeni yerine ekliyoruz

        // Yeni sıralamayı kaydediyoruz (row değerlerini sıralamaya göre güncelliyoruz)
        const updatedItems = items.map((item, index) => ({
            ...item,
            order: index + 1, // Yeni sıraya göre order (row) değerlerini güncelliyoruz
        }));

        setDesignElements(updatedItems); // Yeni sıralamayı state'e kaydediyoruz
        handleSaveOrder(updatedItems); // Sıralama güncellendiğinde veritabanına kaydediyoruz
    };

    const handleTogglePreviewAccess = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setPreviewAccess(checked);
        localStorage.setItem('previewAccess', JSON.stringify(checked)); // Durumu localStorage'a kaydediyoruz
        if (checked) {
            toast.success("Önizleme erişimi aktif!");
        } else {
            toast.warn("Önizleme erişimi kapalı!");
        }
    };

    const handlePreview = () => {
        if (previewAccess) {
            navigate(`/admin-panel/preview/${safeId}`, { state: { designElements } });
            toast.success("Önizleme başlatılıyor!");
        } else {
            toast.error("Önizleme erişimi aktif değil!");
        }
    };

    const handleNavigateToFragman = () => {
        navigate(`/admin-panel/fragman/${safeId}`);
    };

    return (
        <div className="flex">
            {/* Sidebar */}
            <div className="fixed top-0 left-0 w-64 h-screen bg-gray-800 text-white z-50">
                <Sidebar />
            </div>

            {/* Main Content Area */}
            <div className="ml-64 w-full min-h-screen bg-white p-6">
                <ToastContainer position="bottom-right" autoClose={5000} />

                <div className="pt-16 pl-4">
                    <NavigationTabs activePage={activePage} id={safeId} />
                    <div className="border-t border-gray-300 mt-4 mb-8"></div>

                    <div className="flex justify-between items-center mb-4">
                        <div className="text-4xl font-semibold">Tasarım Ayarları</div>
                        <div className="flex items-center space-x-4">
                            <div className="flex items-center space-x-2">
                                <Switch checked={previewAccess} onChange={handleTogglePreviewAccess} />
                                <span>Preview Erişimi</span>
                            </div>

                            <button
                                onClick={handlePreview}
                                className={`px-4 py-2 border border-gray-300 rounded text-gray-800 ${previewAccess ? '' : 'opacity-50 cursor-not-allowed'}`}
                                disabled={!previewAccess}
                            >
                                Önizleme
                            </button>

                            <button
                                onClick={handleNavigateToFragman}
                                className="px-4 py-2 border border-gray-300 rounded text-gray-800"
                            >
                                Fragman
                            </button>

                            <button
                                onClick={handleAddElement}
                                className="px-4 py-2 border border-gray-300 rounded text-gray-800"
                            >
                                Component Ekle
                            </button>
                        </div>
                    </div>

                    <div className="text-lg font-medium text-gray-800 mb-4">
                        {launchName ? `Lansman: ${launchName}` : 'Lansman yükleniyor...'}
                    </div>

                    {/* Drag and Drop Components List */}
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="designElements">
                            {(provided: DroppableProvided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                                    {designElements.map((element, index) => (
                                        <Draggable key={element.id} draggableId={element.id} index={index}>
                                            {(provided: DraggableProvided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <DesignElementComponent
                                                        key={element.id}
                                                        element={element}
                                                        index={index}
                                                        onMoveUp={() => moveElementUp(index)}
                                                        onMoveDown={() => moveElementDown(index)}
                                                        onToggleFragman={() => handleToggleFragman(element.id)}
                                                        onDelete={() => handleDeleteElement(element.id)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <ComponentModal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            fetchComponents();
                        }}
                        onSave={handleSaveComponent}
                    />
                </div>
            </div>
        </div>
    );
};

export default DesignPage;
