import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // MediaSelector bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin
import TwinFlipCard from '../../UI/sections/TwinFlipCardSection'; // Ön izleme için TwinFlipCard bileşenini import edin

interface TwinFlipCardFormProps {
    onSave: (data: any) => void;
}

const TwinFlipCardForm: React.FC<TwinFlipCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [isRightFrontMediaSelectorOpen, setIsRightFrontMediaSelectorOpen] = useState(false);
    const [isRightBackMediaSelectorOpen, setIsRightBackMediaSelectorOpen] = useState(false);
    const [isLeftFrontMediaSelectorOpen, setIsLeftFrontMediaSelectorOpen] = useState(false);
    const [isLeftBackMediaSelectorOpen, setIsLeftBackMediaSelectorOpen] = useState(false);

    const [rightFrontMedia, setRightFrontMedia] = useState<S3File | null>(null);
    const [rightBackMedia, setRightBackMedia] = useState<S3File | null>(null);
    const [leftFrontMedia, setLeftFrontMedia] = useState<S3File | null>(null);
    const [leftBackMedia, setLeftBackMedia] = useState<S3File | null>(null);
    
    // Pop-up durumu
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const handleSave = async () => {
        if (!rightFrontMedia || !rightBackMedia || !leftFrontMedia || !leftBackMedia) return;

        const componentData = {
            designId: id,
            componentName: 'Twin Flip Card',
            componentData: {
                rightCard: { frontMedia: rightFrontMedia.url, backMedia: rightBackMedia.url },
                leftCard: { frontMedia: leftFrontMedia.url, backMedia: leftBackMedia.url },
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data);
           
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleRightFrontMediaSelect = (file: S3File) => {
        setRightFrontMedia(file);
        setIsRightFrontMediaSelectorOpen(false);
    };

    const handleRightBackMediaSelect = (file: S3File) => {
        setRightBackMedia(file);
        setIsRightBackMediaSelectorOpen(false);
    };

    const handleLeftFrontMediaSelect = (file: S3File) => {
        setLeftFrontMedia(file);
        setIsLeftFrontMediaSelectorOpen(false);
    };

    const handleLeftBackMediaSelect = (file: S3File) => {
        setLeftBackMedia(file);
        setIsLeftBackMediaSelectorOpen(false);
    };

    const renderMediaPreview = (media: S3File | null) => {
        if (!media) return null;

        const fileExtension = media.key.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return <img src={media.url} alt={media.key} className="max-w-xs h-auto rounded-lg" />;
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video controls className="max-w-xs h-auto rounded-lg">
                    <source src={media.url} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    return (
        <div>
            {/* Sağ Kart */}
            <div className="mb-4 p-4 border border-[#dfe2e6] rounded-lg">
                <h3 className="text-[#243757] text-sm font-normal mb-4">Sağ Kart</h3>
                <label className="block text-sm text-gray-700 mb-2">Ön Medya (Önerilen ölçüler: 1060x756 piksel)</label>
                <button
                    onClick={() => setIsRightFrontMediaSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm"
                >
                    <span className="text-[#091E42]">{rightFrontMedia ? rightFrontMedia.key : 'Ön Medya Seç'}</span>
                </button>
                {renderMediaPreview(rightFrontMedia)}

                <label className="block text-sm text-gray-700 mt-4 mb-2">Arka Medya (Önerilen ölçüler: 1060x756 piksel)</label>
                <button
                    onClick={() => setIsRightBackMediaSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm"
                >
                    <span className="text-[#091E42]">{rightBackMedia ? rightBackMedia.key : 'Arka Medya Seç'}</span>
                </button>
                {renderMediaPreview(rightBackMedia)}
            </div>

            {/* Sol Kart */}
            <div className="mb-4 p-4 border border-[#dfe2e6] rounded-lg">
                <h3 className="text-[#243757] text-sm font-normal mb-4">Sol Kart</h3>
                <label className="block text-sm text-gray-700 mb-2">Ön Medya (Önerilen ölçüler: 1060x756 piksel)</label>
                <button
                    onClick={() => setIsLeftFrontMediaSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm"
                >
                    <span className="text-[#091E42]">{leftFrontMedia ? leftFrontMedia.key : 'Ön Medya Seç'}</span>
                </button>
                {renderMediaPreview(leftFrontMedia)}

                <label className="block text-sm text-gray-700 mt-4 mb-2">Arka Medya (Önerilen ölçüler: 1060x756 piksel)</label>
                <button
                    onClick={() => setIsLeftBackMediaSelectorOpen(true)}
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm"
                >
                    <span className="text-[#091E42]">{leftBackMedia ? leftBackMedia.key : 'Arka Medya Seç'}</span>
                </button>
                {renderMediaPreview(leftBackMedia)}
            </div>

            <MediaSelector
                isOpen={isRightFrontMediaSelectorOpen}
                onSelect={handleRightFrontMediaSelect}
                onClose={() => setIsRightFrontMediaSelectorOpen(false)}
            />
            <MediaSelector
                isOpen={isRightBackMediaSelectorOpen}
                onSelect={handleRightBackMediaSelect}
                onClose={() => setIsRightBackMediaSelectorOpen(false)}
            />
            <MediaSelector
                isOpen={isLeftFrontMediaSelectorOpen}
                onSelect={handleLeftFrontMediaSelect}
                onClose={() => setIsLeftFrontMediaSelectorOpen(false)}
            />
            <MediaSelector
                isOpen={isLeftBackMediaSelectorOpen}
                onSelect={handleLeftBackMediaSelect}
                onClose={() => setIsLeftBackMediaSelectorOpen(false)}
            />

            <div className="flex justify-end gap-4">
                <button onClick={() => setIsPreviewOpen(true)} className="px-4 py-2.5 bg-red-700 text-white rounded-lg">
                    Önizleme
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Ekle
                </button>
            </div>

            {/* Pop-up (Ön İzleme) */}
            {isPreviewOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={() => setIsPreviewOpen(false)}>
                    <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-6xl w-full" onClick={(e) => e.stopPropagation()}>
                        <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-800" onClick={() => setIsPreviewOpen(false)}>
                            X
                        </button>
                        {/* Önizleme İçin TwinFlipCard Gösteriliyor */}
                        <TwinFlipCard componentData={{
                            leftCard: {
                                frontMedia: leftFrontMedia?.url || '',
                                backMedia: leftBackMedia?.url || ''
                            },
                            rightCard: {
                                frontMedia: rightFrontMedia?.url || '',
                                backMedia: rightBackMedia?.url || ''
                            }
                        }} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TwinFlipCardForm;
