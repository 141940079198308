import React, { useState } from 'react';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

Modal.setAppElement('#root');

interface LargePopupCardSectionProps {
    componentData: {
        media: string; // Media URL (image, video, or YouTube)
        poster?: string; // Kapak fotoğrafı (Poster)
    };
}

const LargePopupCardSection: React.FC<LargePopupCardSectionProps> = ({ componentData }) => {
    const [isZoomed, setIsZoomed] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

    const handleZoom = () => {
        setIsZoomed(true);
    };

    const handleClose = () => {
        setIsZoomed(false);
        setIsVideoPlaying(false); // Video durdurulacak
    };

    const isYouTubeLink = componentData.media.includes('youtube.com') || componentData.media.includes('youtu.be');
    const isVideo = componentData.media.match(/\.(mp4|webm|ogg)$/);

    const handlePlayVideo = () => {
        setIsZoomed(true);
        setIsVideoPlaying(true); // Video oynatılacak
    };

    const renderYouTubeEmbed = (url: string) => {
        const videoId = url.includes('youtube.com')
            ? new URLSearchParams(new URL(url).search).get('v')
            : url.split('youtu.be/')[1];
        return (
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-3xl"
            ></iframe>
        );
    };

    return (
        <div className="flex justify-center items-center w-full m-0 p-0" style={{ height: '70vh' }}>
            <motion.div
                ref={ref}
                className="relative flex justify-center items-center rounded-3xl m-0 p-0"
                style={{ width: '85vw', height: '70vh' }} 
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.8 }}
                transition={{ duration: 1, ease: 'easeOut' }}
            >
                {isYouTubeLink ? (
                    renderYouTubeEmbed(componentData.media)
                ) : isVideo ? (
                    <div className="relative w-full h-full">
                        <video
                            className="rounded-3xl object-cover w-full h-full"
                            style={{ width: '85vw', height: '70vh' }}
                            muted
                            controls={false}
                            poster={componentData.poster}
                            autoPlay={false}
                            loop={false}
                        >
                            <source src={componentData.media} type="video/mp4" />
                            Tarayıcınız bu videoyu desteklemiyor.
                        </video>
                    </div>
                ) : (
                    <img
                        src={componentData.media}
                        alt="Selected Media"
                        className="rounded-3xl object-cover w-full h-full"
                        style={{ width: '85vw', height: '70vh' }}
                    />
                )}

                <button
                    className="absolute bottom-4 right-4 bg-gray-200 bg-opacity-30 hover:bg-opacity-70 transition-all rounded-full shadow-lg w-16 h-16 flex items-center justify-center"
                    onClick={isVideo ? handlePlayVideo : handleZoom}
                >
                    {isVideo ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-10 h-10 text-white"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.5 3.75a.75.75 0 011.11-.66l14.25 7.5a.75.75 0 010 1.32l-14.25 7.5a.75.75 0 01-1.11-.66v-15z"
                                clipRule="evenodd"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-10 h-10 rotate-45 text-white"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 10l5-5 5 5M7 14l5 5 5-5"
                            />
                        </svg>
                    )}
                </button>
            </motion.div>

            {isZoomed && (
                <Modal
                    isOpen={isZoomed}
                    onRequestClose={handleClose}
                    contentLabel="Media Modal"
                    className="fixed inset-0 flex items-center justify-center z-50"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-70 z-[1000]"
                    shouldCloseOnOverlayClick={true}
                >
                    <motion.div
                        className="relative flex items-center justify-center w-full h-full"
                        initial={{ scale: 0.5, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.5, opacity: 0 }}
                        transition={{ duration: 0.7, ease: 'easeInOut' }}
                    >
                        {isYouTubeLink ? (
                            renderYouTubeEmbed(componentData.media)
                        ) : isVideo ? (
                            <video
                                autoPlay={isVideoPlaying}
                                controls
                                className="object-cover w-full h-full"
                                style={{ width: '100vw', height: '100vh' }}
                                poster={componentData.poster}
                            >
                                <source src={componentData.media} type="video/mp4" />
                                Tarayıcınız bu videoyu desteklemiyor.
                            </video>
                        ) : (
                            <img
                                src={componentData.media}
                                alt="Zoomed Media"
                                className="object-cover w-full h-full"
                                style={{ width: '100vw', height: '100vh' }}
                            />
                        )}
                        <button
                            className="absolute top-4 right-4 bg-gray-200 bg-opacity-30 hover:bg-opacity-70 rounded-full shadow-lg hover:shadow-2xl transition-all w-14 h-14 flex items-center justify-center"
                            onClick={handleClose}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-10 h-10 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </motion.div>
                </Modal>
            )}
        </div>
    );
};

export default LargePopupCardSection;
