import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // Medya seçici bileşenini doğru şekilde import edin
import LargeFlipCardSection from '../../UI/sections/LargeFlipCardSection'; // LargeFlipCardSection bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin

interface LargeFlipCardFormProps {
    onSave: (data: any) => void;
}

const LargeFlipCardForm: React.FC<LargeFlipCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [isFrontMediaSelectorOpen, setIsFrontMediaSelectorOpen] = useState(false); // Ön medya seçici açık mı?
    const [isBackMediaSelectorOpen, setIsBackMediaSelectorOpen] = useState(false); // Arka medya seçici açık mı?
    const [selectedFrontMedia, setSelectedFrontMedia] = useState<S3File | null>(null); // Seçilen ön medya dosyası
    const [selectedBackMedia, setSelectedBackMedia] = useState<S3File | null>(null); // Seçilen arka medya dosyası
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Pop-up önizleme state

    const defaultMedia = 'https://via.placeholder.com/1920x1080?text=No+Media+Available'; // Anonim görsel

    const handleSave = async () => {
        if (!selectedFrontMedia || !selectedBackMedia) {
            alert('Lütfen hem ön hem de arka medya seçin!');
            return;
        }

        const componentData = {
            designId: id,
            componentName: 'Large Flip Card',
            componentData: {
                frontMedia: selectedFrontMedia.url,
                backMedia: selectedBackMedia.url,
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
    
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleFrontMediaSelect = (file: S3File) => {
        setSelectedFrontMedia(file);
        setIsFrontMediaSelectorOpen(false);
    };

    const handleBackMediaSelect = (file: S3File) => {
        setSelectedBackMedia(file);
        setIsBackMediaSelectorOpen(false);
    };

    const handlePreview = () => {
        setIsPreviewOpen(true); // Pop-up'ı aç
    };

    // Medya türüne göre medya gösterimi yapan yardımcı fonksiyon
    const renderMediaPreview = (mediaUrl: string) => {
        const fileExtension = mediaUrl.split('.').pop()?.toLowerCase();

        if (['mp4', 'webm', 'ogg'].includes(fileExtension || '')) {
            // Video dosyası
            return (
                <video controls className="w-full h-auto rounded-lg">
                    <source src={mediaUrl} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else {
            // Görsel dosyası
            return <img src={mediaUrl} alt="Selected media" className="w-full h-auto object-contain rounded-lg" />;
        }
    };

    return (
        <div>
            {/* Ön Medya Alanı */}
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal mb-2">Ön Medya Alanı (Önerilen boyut: 1632x756 piksel)</label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsFrontMediaSelectorOpen(true)}
                >
                    <span className="text-[#091E42] text-sm">{selectedFrontMedia ? selectedFrontMedia.key : 'Seç'}</span>
                    <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>
                {selectedFrontMedia && (
                    <div className="mt-4">
                        {renderMediaPreview(selectedFrontMedia.url)} {/* Seçilen ön medya dosyasını göster */}
                    </div>
                )}
            </div>

            {/* Arka Medya Alanı */}
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal mb-2">Arka Medya Alanı (Önerilen boyut: 1632x756 piksel)</label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsBackMediaSelectorOpen(true)}
                >
                    <span className="text-[#091E42] text-sm">{selectedBackMedia ? selectedBackMedia.key : 'Seç'}</span>
                    <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>
                {selectedBackMedia && (
                    <div className="mt-4">
                        {renderMediaPreview(selectedBackMedia.url)} {/* Seçilen arka medya dosyasını göster */}
                    </div>
                )}
            </div>

            <MediaSelector
                isOpen={isFrontMediaSelectorOpen}
                onSelect={handleFrontMediaSelect}
                onClose={() => setIsFrontMediaSelectorOpen(false)}
            />

            <MediaSelector
                isOpen={isBackMediaSelectorOpen}
                onSelect={handleBackMediaSelect}
                onClose={() => setIsBackMediaSelectorOpen(false)}
            />

            <div className="flex justify-end space-x-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {/* Pop-up Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={() => setIsPreviewOpen(false)} // Modal dışına tıklayınca kapanacak
                >
                    <div
                        className="relative w-full max-w-[100vw] h-[100vh] bg-white rounded-lg p-6 overflow-auto"
                        onClick={(e) => e.stopPropagation()} // Modal içeriğine tıklanınca kapanmayacak
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-red-500 hover:text-red-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* LargeFlipCardSection bileşeni ile önizleme */}
                        <div className="w-full h-full mx-auto flex justify-center items-center">
                            <LargeFlipCardSection
                                componentData={{
                                    frontMedia: selectedFrontMedia?.url || defaultMedia,
                                    backMedia: selectedBackMedia?.url || defaultMedia,
                                }}
                                marginTop={0}
                                marginBottom={0}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LargeFlipCardForm;
