import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 w-[calc(100vw)] bg-gray-100 text-gray-500 text-center p-1">
      <p>UniWorkHub © Copyright 2024. Tüm Hakları Saklıdır.</p>
      <p>Gülbeyza Cüce && Emir Tarık Şahin</p>
    </div>
  );
};

export default Footer;
