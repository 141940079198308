import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

interface LaunchComponent {
    _id: string;
    launchUrl: string;
    componentCount: string[];
    componentName: string;
    createdAt: string;
}

interface GroupedLaunchComponent {
    launchUrl: string;
    components: LaunchComponent[];
}

const LaunchReportPage: React.FC = () => {
    const [groupedComponents, setGroupedComponents] = useState<GroupedLaunchComponent[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [expandedUrl, setExpandedUrl] = useState<string | null>(null);
    const [visitorIds, setVisitorIds] = useState<string[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<LaunchComponent[]>(`${process.env.REACT_APP_API_URL}/launch-components`);

                // Gruplama ve benzersizleştirme işlemi
                const groupedData = response.data.reduce((acc, component) => {
                    // Null ziyaretçi kimliklerini filtreleme
                    component.componentCount = component.componentCount.filter(visitorId => visitorId !== null);

                    const existingGroup = acc.find((group) => group.launchUrl === component.launchUrl);
                    if (existingGroup) {
                        // Map ile componentName bazında benzersiz hale getir
                        const uniqueComponentsMap = new Map<string, LaunchComponent>();
                        existingGroup.components.forEach((comp) => {
                            uniqueComponentsMap.set(comp.componentName, comp);
                        });

                        // Yeni componentName varsa ekle
                        if (!uniqueComponentsMap.has(component.componentName)) {
                            uniqueComponentsMap.set(component.componentName, component);
                        }

                        existingGroup.components = Array.from(uniqueComponentsMap.values());
                    } else {
                        acc.push({ launchUrl: component.launchUrl, components: [component] });
                    }
                    return acc;
                }, [] as GroupedLaunchComponent[]);

                setGroupedComponents(groupedData);
            } catch (error) {
                console.error('Error fetching launch components:', error);
            } finally {
                setLoading(false);
            }
        };


        fetchData();
    }, []);

    const toggleExpand = (url: string) => {
        setExpandedUrl(expandedUrl === url ? null : url);
    };

    const getUniqueVisitorCount = (components: LaunchComponent[]) => {
        const uniqueVisitors = new Set<string>();
        components.forEach((component) => {
            component.componentCount.forEach((visitorId) => {
                uniqueVisitors.add(visitorId);
            });
        });
        return uniqueVisitors.size;
    };

    const openModal = (component: LaunchComponent) => {
        const uniqueVisitors = Array.from(new Set(component.componentCount));
        setVisitorIds(uniqueVisitors);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setVisitorIds([]);
    };

    return (
        <div className="flex h-screen">
            {/* Sidebar */}
            <div className="fixed top-0 left-0 w-64 h-full bg-red-800 text-white z-50">
                <Sidebar />
            </div>

            {/* Main Content */}
            <div className="flex-1 ml-64">
                {/* Top Navbar */}
                <div className="fixed top-0 left-64 right-0 h-16 bg-red-800 shadow z-40 flex items-center px-6">
                    <Header />
                </div>

                {/* Page Content */}
                <div className="pt-20 px-6">
                    <h1 className="text-2xl font-semibold mb-6">Lansman Raporları</h1>
                    {loading ? (
                        <p>Loading data...</p>
                    ) : (
                        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
                            <thead className="bg-red-800 text-white">
                                <tr>
                                    <th className="p-3 text-left">Lansmanlar</th>
                                    <th className="p-3 text-left">Total Lansman Görüntülenme Sayısı</th>
                                    <th className="p-3 text-left">Aksiyonlar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupedComponents.map((group) => (
                                    <React.Fragment key={group.launchUrl}>
                                        <tr className="border-b">
                                            <td className="p-3">{group.launchUrl}</td>
                                            <td className="p-3">{getUniqueVisitorCount(group.components)}</td>
                                            <td className="p-3">
                                                <button
                                                    className="bg-red-800 text-white px-3 py-1 rounded"
                                                    onClick={() => toggleExpand(group.launchUrl)}
                                                >
                                                    {expandedUrl === group.launchUrl ? 'Kapat' : 'Detayları Gör'}
                                                </button>
                                            </td>
                                        </tr>
                                        {expandedUrl === group.launchUrl && (
                                            <tr>
                                                <td colSpan={3} className="p-3 bg-gray-100">
                                                    <div>
                                                        <h2 className="text-lg font-semibold mb-2">
                                                            {group.launchUrl} Detayları
                                                        </h2>
                                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                                            {group.components.map((component) => (
                                                                <div
                                                                    key={component._id}
                                                                    className="bg-white border rounded-lg p-4"
                                                                >
                                                                    <h3 className="font-medium">
                                                                        {component.componentName}
                                                                    </h3>
                                                                    <p>
                                                                        Ziyaretçi Sayısı:{" "}
                                                                        {new Set(component.componentCount).size}
                                                                    </p>
                                                                    <p>
                                                                        Oluşturulma Tarihi:{" "}
                                                                        {new Date(
                                                                            component.createdAt
                                                                        ).toLocaleDateString()}
                                                                    </p>
                                                                    <button
                                                                        className="bg-red-800 text-white px-2 py-1 rounded mt-2"
                                                                        onClick={() => openModal(component)}
                                                                    >
                                                                        Ziyaretçileri Gör
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-96 max-w-full">
                        <h2 className="text-xl font-semibold mb-4">Ziyaretçi Kimlikleri</h2>
                        <ul className="list-disc pl-5 space-y-2 max-h-64 overflow-y-auto">
                            {visitorIds.map((visitorId) => (
                                <li key={visitorId}>{visitorId}</li>
                            ))}
                        </ul>
                        <button
                            className="bg-red-800 text-white px-4 py-2 rounded mt-4"
                            onClick={closeModal}
                        >
                            Kapat
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LaunchReportPage;
