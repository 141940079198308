import axios from 'axios';
import Cookies from 'js-cookie';

// Axios instance oluşturma
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // .env dosyasından dinamik baseURL çekmek için
});

console.log('API URL:', process.env.REACT_APP_API_URL);

// Her istekte otomatik olarak Authorization başlığına token'ı ekleyin, SEO endpoint'leri hariç
api.interceptors.request.use((config) => {
  const token = Cookies.get('adminToken');

  // SEO endpoint'leri için Authorization header eklenmesini atla
  if (!config.url?.includes('/seosettings')) {
    if (token) {
      // headers nesnesi undefined ise onu boş bir nesne olarak ayarla
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

// Lansman listesini alma fonksiyonu
export const getLaunchList = async () => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/launch-list`); // Axios instance kullanarak istek yapıyoruz
    return response.data;
  } catch (error) {
    console.error('API request error: ', error);
    throw error;
  }
};

// SEO verilerini çekme fonksiyonu (Authorization gerekmeden)
export const getSeoData = async (launchId: string) => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/seosettings/${launchId}`);
    return response.data;
  } catch (error) {
    console.error(`SEO verileri alınamadı: ${launchId}`, error);
    throw error;
  }
};

export default api;