import React from 'react';
import { useInView } from 'react-intersection-observer';

interface TopTextCardSectionProps {
    componentData: {
        text: string;
        media: string;
    };
}

const TopTextCardSection: React.FC<TopTextCardSectionProps> = ({ componentData }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const renderMediaPreview = () => {
        const fileExtension = componentData.media.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return (
                <img
                    src={componentData.media}
                    alt="Selected Media"
                    className="w-full h-full object-cover rounded-lg" // Görselin tam çerçeveyi doldurması için object-cover
                />
            );
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video
                    className="w-full h-full object-cover rounded-b-lg" // Videonun tam çerçeveyi doldurması için object-cover
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src={componentData.media} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    const mediaStyle: React.CSSProperties = {
        opacity: 0,
        transform: 'translateY(80px)',
        transition: 'transform 1s ease-in-out, opacity 1s ease-in-out', // Hızlandırma yapıldı (2.5s -> 1s)
    };

    const visibleStyle: React.CSSProperties = {
        opacity: 1,
        transform: 'translateY(0)',
    };

    return (
        <div
            className="bg-white p-0 mx-auto overflow-hidden rounded-b-3xl w-full md:w-[85vw] h-auto md:h-[70vh]" // Mobilde tam genişlik ve otomatik yükseklik
        >
            <div className="flex flex-col justify-between items-center w-full h-full">
                {/* Başlık */}
                <div className="text-center py-2 w-full"> {/* mb-4 kaldırıldı, py-2 ile padding azaltıldı */}
                    <p
                        className="text-lg md:text-3xl font-bold leading-tight" // Mobilde başlık boyutu küçük, geniş ekranda büyük
                        style={{
                            fontFamily: 'Radhanji, sans-serif', // Radhanji fontu olarak ayarlandı
                            color: 'gray-900',
                        }}
                    >
                        {componentData.text}
                    </p>
                </div>

                {/* Medya */}
                <div
                    className="w-full h-auto md:h-full flex justify-center items-center" // Mobilde yükseklik otomatik
                    ref={ref}
                    style={{
                        ...mediaStyle,
                        ...(inView ? visibleStyle : {}),
                    }}
                >
                    {renderMediaPreview()}
                </div>
            </div>
        </div>
    );
};

export default TopTextCardSection;
