import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

interface TitleCardSectionProps {
    componentData: {
        title: string;
    };
}

const TitleCardSection: React.FC<TitleCardSectionProps> = ({ componentData }) => {
    const { title } = componentData;

    // Intersection Observer ile bileşen görünür olduğunda animasyonu tetiklemek için ref ekliyoruz
    const { ref, inView } = useInView({
        triggerOnce: true,   // Sadece bir kez tetiklensin
        threshold: 0.3       // Kartın %30'u görünür olduğunda tetikleme yapılır
    });

    return (
        <div className="w-full flex justify-start items-center pt-6 pb-0 ml-20"> {/* Sol tarafta daha fazla boşluk bıraktık */}
            {/* Başlık ve Çizgi */}
            <motion.div
                ref={ref}  // Intersection observer için ref ekliyoruz
                className="flex items-center w-full"
                initial={{ scale: 0.8, opacity: 0 }}  // Başlangıç durumu (küçük boyut ve saydam)
                animate={inView ? { scale: 1, opacity: 1 } : {}} // Sayfa kaydırıldığında büyütme ve görünürlük sağlanır
                transition={{ duration: 0.6, ease: "easeInOut" }} // Geçiş süresi ve yumuşaklık
            >
                {/* Başlık */}
                <motion.h2
                    className="text-[38px] font-bold text-gray-700 w-auto"
                    style={{ fontFamily: 'Radhanji, sans-serif' }} // Font ailesini Radhanji olarak ayarladık
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}} // Sayfa kaydırıldığında başlık görünür hale gelir
                    transition={{ duration: 0.6, delay: 0.3 }} // Biraz gecikme ekleyerek başlık animasyonu sağlanır
                >
                    {title}
                </motion.h2>

                {/* Yanındaki Gradient Çizgi */}
                <div className="flex-grow ml-4 h-[1px] bg-gradient-to-r from-gray-500 to-transparent"></div>
            </motion.div>
        </div>
    );
};

export default TitleCardSection;