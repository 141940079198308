import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion'; // Framer Motion kullanarak pop-up oluşturuyoruz
import MediaSelector from '../MediaSelector'; // MediaSelector bileşenini doğru şekilde import edin
import { S3File } from '../../services/types'; // S3File tipini import edin
import MiniCardSliderSection from '../../UI/sections/MiniCardSliderSection'; // Önizleme için MiniCardSliderSection bileşenini import edin

interface ProductCardFormProps {
    onSave: (data: any) => void;
}

const ProductCardForm: React.FC<ProductCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [cards, setCards] = useState([{
        buttonText: '',
        buttonUrl: '',
        backgroundMedia: null as S3File | null,
        text: '',
        logoMedia: null as S3File | null
    }]);
    const [isLogoSelectorOpen, setIsLogoSelectorOpen] = useState<number | null>(null);
    const [isBackgroundSelectorOpen, setIsBackgroundSelectorOpen] = useState<number | null>(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Pop-up kontrolü için state

    const MAX_TEXT_LENGTH = 50; // Yazı sınırını 50 karaktere çıkardım

    const handleAddCard = () => {
        setCards([...cards, { buttonText: '', buttonUrl: '', backgroundMedia: null, text: '', logoMedia: null }]);
    };

    const handleCardChange = (index: number, field: string, value: string) => {
        const newCards = cards.map((card, i) =>
            i === index ? { ...card, [field]: value } : card
        );
        setCards(newCards);
    };

    const handleLogoSelect = (index: number, file: S3File) => {
        const newCards = cards.map((card, i) =>
            i === index ? { ...card, logoMedia: file } : card
        );
        setCards(newCards);
        setIsLogoSelectorOpen(null);
    };

    const handleBackgroundSelect = (index: number, file: S3File) => {
        const newCards = cards.map((card, i) =>
            i === index ? { ...card, backgroundMedia: file } : card
        );
        setCards(newCards);
        setIsBackgroundSelectorOpen(null);
    };

    const handleRemoveCard = (index: number) => {
        setCards(cards.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Product Card Slider',
            componentData: {
                cards: cards.map(card => ({
                    ...card,
                    logoMedia: card.logoMedia?.url || '',
                    backgroundMedia: card.backgroundMedia?.url || ''
                })),
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
         
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const renderMediaPreview = (media: S3File | null) => {
        if (!media) return null;

        const fileExtension = media.key.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return <img src={media.url} alt={media.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />;
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video controls className="max-w-full max-h-64 object-contain mx-auto rounded-lg">
                    <source src={media.url} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    const handlePreview = () => {
        setIsPreviewOpen(true); // Önizleme modalını açmak için state'i günceller
    };

    return (
        <div>
            {cards.map((card, index) => (
                <div key={index} className="mb-4 p-4 border rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                        <h3 className="text-gray-700">Product Card {index + 1}</h3>
                        <button
                            onClick={() => handleRemoveCard(index)}
                            className="px-3 py-1 bg-red-500 text-white rounded"
                        >
                            Sil
                        </button>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Buton Adı</label>
                        <input
                            type="text"
                            value={card.buttonText}
                            onChange={(e) => handleCardChange(index, 'buttonText', e.target.value)}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                            placeholder="Buton Adı"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Buton URL</label>
                        <input
                            type="text"
                            value={card.buttonUrl}
                            onChange={(e) => handleCardChange(index, 'buttonUrl', e.target.value)}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                            placeholder="Buton URL"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Arka Plan Medya</label>
                        <button
                            onClick={() => setIsBackgroundSelectorOpen(index)}
                            className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                        >
                            <span className="text-[#091E42]">{card.backgroundMedia ? card.backgroundMedia.key : 'Arka Plan Medya Seç'}</span>
                        </button>
                        {card.backgroundMedia && (
                            <div className="mt-2">
                                {renderMediaPreview(card.backgroundMedia)}
                            </div>
                        )}
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Yazı Alanı (50 karaktere kadar)</label>
                        <textarea
                            value={card.text}
                            onChange={(e) => {
                                if (e.target.value.length <= MAX_TEXT_LENGTH) handleCardChange(index, 'text', e.target.value);
                            }}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md resize-none h-24"
                            placeholder="Yazı Alanı"
                        />
                        <p className="text-sm text-gray-500 mt-1">{card.text.length}/{MAX_TEXT_LENGTH} karakter</p>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Logo Alanı (Önerilen boyut: 64x64 px)</label>
                        <button
                            onClick={() => setIsLogoSelectorOpen(index)}
                            className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                        >
                            <span className="text-[#091E42]">{card.logoMedia ? card.logoMedia.key : 'Logo Seç'}</span>
                        </button>
                        {card.logoMedia && (
                            <div className="mt-2">
                                {renderMediaPreview(card.logoMedia)}
                            </div>
                        )}
                    </div>
                    <MediaSelector
                        isOpen={isLogoSelectorOpen === index}
                        onSelect={(file: S3File) => handleLogoSelect(index, file)}
                        onClose={() => setIsLogoSelectorOpen(null)}
                    />
                    <MediaSelector
                        isOpen={isBackgroundSelectorOpen === index}
                        onSelect={(file: S3File) => handleBackgroundSelect(index, file)}
                        onClose={() => setIsBackgroundSelectorOpen(null)}
                    />
                </div>
            ))}

            <button onClick={handleAddCard} className="px-4 py-2.5 bg-white-500 text-gray-500 rounded-lg shadow mt-4">
                Ürün Kartı Ekle
            </button>

            <div className="flex justify-end space-x-4 mt-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={() => setIsPreviewOpen(false)}
                >
                    <div
                        className="relative w-[85%] max-w-[1200px] h-[600px] bg-white rounded-lg p-6 overflow-auto flex justify-center items-center"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-red-500 hover:text-red-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        <div className="w-full h-full">
                            <MiniCardSliderSection
                                componentData={{
                                    cards: cards.map(card => ({
                                        ...card,
                                        logoMedia: card.logoMedia?.url || '',
                                        backgroundMedia: card.backgroundMedia?.url || '',
                                    }))
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductCardForm;
