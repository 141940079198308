import create from 'zustand';

export interface SeoSettings {
    title: string;
    url: string;
    keywords: string;
    description: string;
    socialImage: string;
    indexStatus: boolean;
    followStatus: boolean;
}

interface SeoStore {
    seoData: SeoSettings;
    setSeoData: (data: Partial<SeoSettings>) => void;
    resetSeoData: () => void;
}

const useSeoStore = create<SeoStore>((set) => ({
    seoData: {
        title: '',
        url: '',
        keywords: '',
        description: '',
        socialImage: '',
        indexStatus: true,
        followStatus: true,
    },
    setSeoData: (data) => set((state) => ({ seoData: { ...state.seoData, ...data } })),
    resetSeoData: () => set({
        seoData: {
            title: '',
            url: '',
            keywords: '',
            description: '',
            socialImage: '',
            indexStatus: true,
            followStatus: true,
        },
    }),
}));

export default useSeoStore;
