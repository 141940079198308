import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // Medya seçici modal
import RightTextCardSection from '../../UI/sections/RightTextCardSection'; // Section bileşeni
import { motion } from 'framer-motion'; // Animasyon için framer-motion
import { S3File } from '../../services/types'; // S3File tipini import edin

interface RightTextCardFormProps {
    onSave: (data: any) => void;
}

const RightTextCardForm: React.FC<RightTextCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>();
    const [text, setText] = useState('');
    const [selectedMedia, setSelectedMedia] = useState<S3File | null>(null);
    const [selectedPoster, setSelectedPoster] = useState<S3File | null>(null); // Kapak fotoğrafı
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false);
    const [isPosterSelectorOpen, setIsPosterSelectorOpen] = useState(false); // Poster seçici için state
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const MAX_TEXT_LENGTH = 400;

    const handleSave = async () => {
        if (!selectedMedia) return;

        const componentData = {
            designId: id,
            componentName: 'Right Text Card',
            componentData: {
                text,
                media: selectedMedia.url,
                mediaPoster: selectedPoster?.url, // Kapak fotoğrafı burada gönderiliyor
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data);
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleMediaSelect = (file: S3File) => {
        setSelectedMedia(file);
        setIsMediaSelectorOpen(false);
    };

    const handlePosterSelect = (file: S3File) => {
        setSelectedPoster(file);
        setIsPosterSelectorOpen(false);
    };

    const renderMediaPreview = (media: S3File) => {
        const fileExtension = media.url.split('.').pop()?.toLowerCase();
        if (['jpg', 'png', 'jpeg', 'webp'].includes(fileExtension || '')) {
            return <img src={media.url} alt={media.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />;
        } else if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video controls className="max-w-full max-h-64 mx-auto rounded-lg">
                    <source src={media.url} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else {
            return <p>Bu dosya tipi görüntülenemez: {media.key}</p>;
        }
    };

    const handlePreviewOpen = () => setIsPreviewOpen(true);
    const handlePreviewClose = () => setIsPreviewOpen(false);

    return (
        <div>
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal">Yazı (En fazla {MAX_TEXT_LENGTH} karakter)</label>
                <textarea
                    value={text}
                    onChange={(e) => {
                        if (e.target.value.length <= MAX_TEXT_LENGTH) setText(e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-[#dfe2e6] rounded-lg"
                    placeholder="Yazı Alanı"
                />
                <p className="text-sm text-gray-500 mt-1">{text.length}/{MAX_TEXT_LENGTH} karakter</p>
            </div>
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal">Medya (Önerilen boyutlar: 820x756 piksel)</label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsMediaSelectorOpen(true)}
                >
                    <span className="text-[#091E42] text-sm">{selectedMedia ? selectedMedia.key : 'Medya Seç'}</span>
                </div>
                {selectedMedia && (
                    <div className="mt-4">
                        {renderMediaPreview(selectedMedia)}
                    </div>
                )}
            </div>

            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal">Kapak Fotoğrafı (Önerilen boyutlar: 820x756 piksel)</label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsPosterSelectorOpen(true)}
                >
                    <span className="text-[#091E42] text-sm">{selectedPoster ? selectedPoster.key : 'Kapak Fotoğrafı Seç'}</span>
                </div>
                {selectedPoster && (
                    <div className="mt-4">
                        <img src={selectedPoster.url} alt={selectedPoster.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />
                    </div>
                )}
            </div>

            <MediaSelector
                isOpen={isMediaSelectorOpen}
                onSelect={handleMediaSelect}
                onClose={() => setIsMediaSelectorOpen(false)}
            />

            <MediaSelector
                isOpen={isPosterSelectorOpen}
                onSelect={handlePosterSelect}
                onClose={() => setIsPosterSelectorOpen(false)}
            />

            <div className="flex justify-end space-x-4">
                <button onClick={handlePreviewOpen} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Ekle
                </button>
            </div>

            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={handlePreviewClose}
                >
                    <motion.div
                        className="relative w-[85vw] h-[75vh] bg-white rounded-lg p-12 flex justify-center items-center"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <RightTextCardSection
                            componentData={{
                                text,
                                media: selectedMedia?.url || '',
                                mediaPoster: selectedPoster?.url || '',
                            }}
                        />

                        <button
                            className="absolute top-4 right-4 text-black-500 hover:text-black-700"
                            onClick={handlePreviewClose}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default RightTextCardForm;
