import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FaSearch, FaUserAlt, FaEnvelope, FaCommentDots, FaListUl } from 'react-icons/fa';

interface SearchComponentFormProps {
    onSave: (data: any) => void;
}

const SearchComponentForm: React.FC<SearchComponentFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [searchTerm, setSearchTerm] = useState(''); // Arama alanı state
    const [loading, setLoading] = useState(false); // Loading state

    const handleSave = async () => {
        // Seçili section olmadan da kaydet
        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Search Component',
            componentData: {
                searchTerm, // Arama alanındaki değeri kaydediyoruz
            },
        };

        try {
            setLoading(true); // Loading durumunu true yapıyoruz
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
            setLoading(false); // İşlem bitince loading'i false yapıyoruz
       
        } catch (error) {
            setLoading(false);
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    // Temizle butonuna basıldığında arama alanını sıfırlar
    const handleClear = () => {
        setSearchTerm('');
    };

    return (
        <div className="p-6 bg-white rounded-lg shadow-md max-w-lg mx-auto">
            <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">Component Ekle</h2>

            {/* Lansman Butonları */}
            <div className="grid grid-cols-4 gap-4 mb-6">
                <button
                    disabled // Loading sırasında butonlar devre dışı
                    className="flex flex-col items-center justify-center border rounded-lg p-4 bg-gray-50 transition-all"
                >
                    <FaUserAlt className="text-2xl mb-1 text-gray-500" />
                    <span className="text-sm font-medium text-gray-700">Bugünün Lansmanları</span>
                </button>
                <button
                    disabled // Loading sırasında butonlar devre dışı
                    className="flex flex-col items-center justify-center border rounded-lg p-4 bg-gray-50 transition-all"
                >
                    <FaEnvelope className="text-2xl mb-1 text-gray-500" />
                    <span className="text-sm font-medium text-gray-700">Devam Eden Lansmanlar</span>
                </button>
                <button
                    disabled // Loading sırasında butonlar devre dışı
                    className="flex flex-col items-center justify-center border rounded-lg p-4 bg-gray-50 transition-all"
                >
                    <FaCommentDots className="text-2xl mb-1 text-gray-500" />
                    <span className="text-sm font-medium text-gray-700">Gelecek Lansmanlar</span>
                </button>
                <button
                    disabled
                    className="flex flex-col items-center justify-center border rounded-lg p-4 bg-gray-50 transition-all"
                >
                    <FaListUl className="text-2xl mb-1 text-gray-500" />
                    <span className="text-sm font-medium text-gray-700">Geçmiş Lansmanlar</span>
                </button>
            </div>

            {/* Arama Alanı */}
            <div className="relative mb-6">
                <FaSearch className="absolute left-3 top-3 text-gray-400" />
                <input
                    disabled // Loading sırasında arama kutusu devre dışı
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-20 py-3 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Arama"
                />
                
            </div>

            {/* Butonlar: Kaydet ve Temizle */}
            <div className="flex justify-end">
                {/* Kaydet Butonu */}
                <button
                    disabled={loading} // Loading sırasında buton devre dışı
                    className="px-4 py-2 bg-[#fcfcfc] rounded-lg  shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight"
                    onClick={handleSave}
                    style={{ width: '100px', height: '40px' }} // Butonun boyutunu düzenleme
                >
                    {loading ? 'Kaydediliyor...' : 'Ekle'}
                </button>
            </div>
        </div>
    );
};

export default SearchComponentForm;
