import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import TitleCardSection from '../../UI/sections/TitleCardSection'; // Ön izleme için Section bileşenini import edin

interface TitleCardFormProps {
    onSave: (data: any) => void;
}

const TitleCardForm: React.FC<TitleCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi alıyoruz
    const [title, setTitle] = useState('');
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Pop-up kontrolü için state

    // Anonim başlık
    const anonymousTitle = 'Anonim Başlık';

    const handleSave = async () => {
        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Title',
            componentData: { title },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
  
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-gray-700">Başlık</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Başlık Alanı"
                />
            </div>

            {/* Butonları yan yana konumlandırıyoruz */}
            <div className="flex justify-end space-x-4">
                {/* Ön İzleme Butonu */}
                <button onClick={() => setIsPreviewOpen(true)} className="px-4 py-2 bg-red-700 text-white rounded">
                    Ön İzleme
                </button>

                {/* Ekle Butonu */}
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal font-['Arial', sans-serif] leading-tight">
                    Ekle
                </button>
            </div>

            {/* Pop-up Modal (Preview) */}
            {isPreviewOpen && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                    <div className="relative w-auto max-w-[95vw] max-h-[95vh] bg-white rounded-lg overflow-hidden">
                        <button
                            className="absolute top-2 right-2 bg-gray-200 p-2 rounded-full hover:bg-opacity-50 transition z-50"
                            onClick={() => setIsPreviewOpen(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        {/* TitleCardSection Bileşeni ile Ön İzleme */}
                        <TitleCardSection
                            componentData={{
                                title: title || anonymousTitle, // Eğer başlık yoksa anonim başlık göster
                            }}
                        />
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default TitleCardForm;
