import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import IconSelector from '../MediaSelector'; // MediaSelector bileşenini import edin
import InfoCardSliderSection from '../../UI/sections/InfoCardSliderSection'; // InfoCardSliderSection bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin

interface InfoCardSliderFormProps {
    onSave: (data: any) => void;
}

const InfoCardSliderForm: React.FC<InfoCardSliderFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi burada kullanıyoruz
    const [infoCards, setInfoCards] = useState([{
        media: null as S3File | null,
        title: '',
        subtitle: '',
        url: '' // Yeni URL alanı
    }]);
    const [isIconSelectorOpen, setIsIconSelectorOpen] = useState<number | null>(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Önizleme modal state

    const MAX_TITLE_LENGTH = 20;
    const MAX_SUBTITLE_LENGTH = 20;

    const handleAddInfoCard = () => {
        setInfoCards([...infoCards, { media: null, title: '', subtitle: '', url: '' }]);
    };

    const handleInfoChange = (index: number, field: string, value: string) => {
        const newInfoCards = infoCards.map((card, i) =>
            i === index ? { ...card, [field]: value } : card
        );
        setInfoCards(newInfoCards);
    };

    const handleMediaSelect = (index: number, file: S3File) => {
        const newInfoCards = infoCards.map((card, i) =>
            i === index ? { ...card, media: file } : card
        );
        setInfoCards(newInfoCards);
        setIsIconSelectorOpen(null);
    };

    const handleRemoveInfoCard = (index: number) => {
        setInfoCards(infoCards.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        const componentData = {
            designId: id,
            componentName: 'Info Card Slider',
            componentData: {
                infoCards: infoCards.map(card => ({
                    ...card,
                    media: card.media?.url || '' // URL alanını ekleyin
                })),
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet

        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const renderMediaPreview = (media: S3File | null) => {
        if (!media) return null;

        const fileExtension = media.key.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return <img src={media.url} alt={media.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />;
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video controls className="max-w-full max-h-64 object-contain mx-auto rounded-lg">
                    <source src={media.url} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    const handlePreview = () => {
        setIsPreviewOpen(true); // Önizleme modalını açmak için state güncellenir
    };

    return (
        <div>
            {infoCards.map((card, index) => (
                <div key={index} className="mb-4 p-4 border rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                        <h3 className="text-gray-700">Info Card {index + 1}</h3>
                        <button
                            onClick={() => handleRemoveInfoCard(index)}
                            className="px-3 py-1 bg-red-500 text-white rounded"
                        >
                            Sil
                        </button>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Başlık</label>
                        <input
                            type="text"
                            value={card.title}
                            onChange={(e) => {
                                if (e.target.value.length <= MAX_TITLE_LENGTH) handleInfoChange(index, 'title', e.target.value);
                            }}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                            placeholder="Başlık"
                        />
                        <p className="text-sm text-gray-500 mt-1">{card.title.length}/{MAX_TITLE_LENGTH} karakter</p>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Alt Başlık</label>
                        <input
                            type="text"
                            value={card.subtitle}
                            onChange={(e) => {
                                if (e.target.value.length <= MAX_SUBTITLE_LENGTH) handleInfoChange(index, 'subtitle', e.target.value);
                            }}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                            placeholder="Alt Başlık"
                        />
                        <p className="text-sm text-gray-500 mt-1">{card.subtitle.length}/{MAX_SUBTITLE_LENGTH} karakter</p>
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">URL</label>
                        <input
                            type="text"
                            value={card.url}
                            onChange={(e) => handleInfoChange(index, 'url', e.target.value)}
                            className="w-full px-4 py-2 border border-[#dfe2e6] rounded-md"
                            placeholder="Kartın Yönlendirme URL'si"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700">Medya</label>
                        <button
                            onClick={() => setIsIconSelectorOpen(index)}
                            className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer bg-white text-sm mt-2"
                        >
                            <span className="text-[#091E42]">{card.media ? card.media.key : 'Medya Seç'}</span>
                        </button>
                        {card.media && (
                            <div className="mt-2">
                                {renderMediaPreview(card.media)}
                            </div>
                        )}
                    </div>
                    <IconSelector
                        isOpen={isIconSelectorOpen === index}
                        onSelect={(file: S3File) => handleMediaSelect(index, file)}
                        onClose={() => setIsIconSelectorOpen(null)}
                    />
                </div>
            ))}

            <button onClick={handleAddInfoCard} className="px-4 py-2.5 bg-white-500 text-gray-500 rounded-lg shadow mt-4">
                Info Card Ekle
            </button>

            <div className="flex justify-end space-x-4 mt-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Kaydet
                </button>
            </div>

            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={() => setIsPreviewOpen(false)}
                >
                    <div
                        className="relative w-[85%] max-w-[1200px] h-[600px] bg-white rounded-lg p-6 overflow-auto flex justify-center items-center"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-red-500 hover:text-red-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        <div className="w-full h-full">
                            <InfoCardSliderSection
                                componentData={{
                                    infoCards: infoCards.map(card => ({
                                        ...card,
                                        media: card.media?.url || '', // null ise boş string
                                    }))
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InfoCardSliderForm;
