import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import useMailStore from '../../store/mailStore';

interface PopupFormProps {
    onClose: () => void;
    onVerifySuccess?: () => void; // Doğrulama başarılı olduğunda çağrılacak fonksiyon
}
interface VerifyCodeResponse {
    token: string;
}

const VisitForm: React.FC<PopupFormProps> = ({ onClose, onVerifySuccess }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [email, setEmail] = useState<string>('');
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false); // Email gönderme durumunu takip eder
    const formRef = useRef<HTMLDivElement>(null); // Form dışına tıklama için ref

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { sendVerificationEmail, verifyCode, isLoading, errorMessage: storeErrorMessage } = useMailStore();

    // Form dışına tıklama işlevi
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (formRef.current && !formRef.current.contains(event.target as Node)) {
                onClose(); // Form dışına tıklandığında formu kapat
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]);

    const handleNext = async () => {
        if (emailRegex.test(email)) {
            setIsSendingEmail(true); // Gönderiliyor durumunu başlat
            try {
                await sendVerificationEmail(email);
                setErrorMessage('');
                setIsSendingEmail(false); // Gönderme tamamlandı
                setCurrentPage(2);
            } catch (error) {
                setIsSendingEmail(false); // Gönderme başarısız
                setErrorMessage('E-posta gönderilirken bir hata oluştu.');
            }
        } else {
            setErrorMessage('Geçerli bir e-posta adresi giriniz.');
        }
    };

    const handleSubmit = async () => {
        if (verificationCode.length === 6) {
            try {
                const response = await verifyCode(email, verificationCode);
                const data = response.data as VerifyCodeResponse;
                if (data.token) {
                    Cookies.set('visitFormToken', data.token, { expires: 1 / 24 }); // Token ismini değiştirdik
                    if (onVerifySuccess) {
                        onVerifySuccess(); // Doğrulama başarılı, yönlendirme işlemi yapılabilir
                    }
                    onClose();
                } else {
                    setErrorMessage('Doğrulama başarısız oldu.');
                }
            } catch (error) {
                setErrorMessage('Doğrulama kodu hatalı.');
            }
        } else {
            setErrorMessage('6 haneli bir doğrulama kodu giriniz.');
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-none" onClick={onClose}></div> {/* Form dışına tıklayınca kapanacak */}
            <div ref={formRef} className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-md z-10">
                <div className="flex justify-between mb-6">
                    <div className={`w-full h-2 ${currentPage === 1 ? 'bg-indigo-600' : 'bg-gray-300'} rounded-l-lg`}></div>
                    <div className={`w-full h-2 ${currentPage === 2 ? 'bg-indigo-600' : 'bg-gray-300'} rounded-r-lg`}></div>
                </div>

                <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                    ✖ {/* Kapatma butonu */}
                </button>

                {currentPage === 1 ? (
                    <div className="animate-slideIn space-y-6">
                        <h2 className="text-2xl font-semibold text-gray-800">E-posta Giriniz</h2>
                        <input
                            type="email"
                            placeholder="E-posta adresinizi giriniz"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-lg "
                        />
                        {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}

                        {/* Eğer e-posta gönderiliyorsa yükleme animasyonu gösterilecek */}
                        {isSendingEmail ? (
                            <div className="flex justify-center items-center space-x-2">
                                <div className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin border-t-transparent"></div>
                                <span>Gönderiliyor...</span>
                            </div>
                        ) : (
                            <button
                                onClick={handleNext}
                                className="w-full bg-indigo-600 text-white p-3 rounded-lg hover:bg-indigo-500 transition duration-300"
                            >
                                Devam Et
                            </button>
                        )}
                    </div>
                ) : (
                    <div className="animate-slideIn space-y-6">
                        <h2 className="text-2xl font-semibold text-gray-800">Doğrulama Kodu Giriniz</h2>
                        <input
                            type="text"
                            placeholder="6 haneli doğrulama kodu"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            maxLength={6}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-indigo-200"
                        />
                        {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
                        <button
                            onClick={handleSubmit}
                            className="w-full bg-indigo-600 text-white p-3 rounded-lg hover:bg-indigo-500 transition duration-300"
                        >
                            Gönder
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VisitForm;