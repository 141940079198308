import create from 'zustand';

interface ComponentData {
    [key: string]: any;
}

interface ComponentStore {
    componentData: ComponentData;
    setComponentData: (componentName: string, data: any) => void;
    resetComponentData: () => void;
}

const useComponentStore = create<ComponentStore>((set) => ({
    componentData: {},
    setComponentData: (componentName: string, data: any) =>
        set((state: ComponentStore) => ({
            componentData: { ...state.componentData, [componentName]: data },
        })),
    resetComponentData: () => set({ componentData: {} }),
}));





export default useComponentStore;
